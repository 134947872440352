import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { CheckStatus } from "../../models/checkStatus";

@Injectable({
    providedIn: "root",
})
export class CheckStatusDbDao extends DbDaoBase<CheckStatus> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    private static sortTypes(returnCheckStatuss: CheckStatus[]): CheckStatus[] {
        returnCheckStatuss = returnCheckStatuss.sort((a, b) => {
            return a.order.localeCompare(b.order);
        });

        return returnCheckStatuss;
    }

    public list(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + ";";

        return this.listByQuery(selectQuery);
    }

    public listForMatIDAndReturn(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE actionType='RETOUR' AND equipmentNature='MATID';";

        return this.listByQuery(selectQuery);
    }

    public listForMatQTAndReturn(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE actionType='RETOUR' AND equipmentNature='MATQT';";

        return this.listByQuery(selectQuery);
    }

    public listForMatIDAndDeparture(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE actionType='DEPART' AND equipmentNature='MATID';";

        return this.listByQuery(selectQuery);
    }

    public listForMatQTAndDeparture(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE actionType='DEPART' AND (equipmentNature='MATQT' OR equipmentNature='CONSO');";

        return this.listByQuery(selectQuery);
    }

    public listForMatIDAndCheck(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE actionType='CONTROLE' AND equipmentNature='MATID';";

        return this.listByQuery(selectQuery);
    }

    public listForMatQTAndCheck(): Promise<CheckStatus[]> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE actionType='CONTROLE' AND equipmentNature='MATQT';";

        return this.listByQuery(selectQuery);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + CheckStatus.TABLENAME + "_id"
                    + " ON " + CheckStatus.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + CheckStatus.TABLENAME + "_actionType"
                + " ON " + CheckStatus.TABLENAME + "(actionType);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + CheckStatus.TABLENAME + "_equipmentNature"
                + " ON " + CheckStatus.TABLENAME + "(equipmentNature);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + CheckStatus.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY, "
                    + "wording TEXT, "
                    + "actionType TEXT, "
                    + "equipmentNature TEXT, "
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + CheckStatus.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + CheckStatus.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<CheckStatus> {
        let selectQuery = "SELECT * FROM " + CheckStatus.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let returnCheckStatus = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return returnCheckStatus;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return CheckStatus.TABLENAME;
    }

    protected rowToModel(row: any): CheckStatus {
        let returnCheckStatus = new CheckStatus();

        returnCheckStatus.id = row.id;
        returnCheckStatus.wording = row.wording;
        returnCheckStatus.actionType = row.actionType;
        returnCheckStatus.equipmentNature = row.equipmentNature;
        returnCheckStatus.order = row._order;

        return returnCheckStatus;
    }

    public save(returnCheckStatus: CheckStatus): Promise<CheckStatus> {
        let promises = [];

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + CheckStatus.TABLENAME + " ("
                        + "id, "
                        + "wording, "
                        + "actionType, "
                        + "equipmentNature, "
                        + "_order"
                        + ") VALUES ("
                        + this.getValue(returnCheckStatus.id)
                        + this.getValue(returnCheckStatus.wording)
                        + this.getValue(returnCheckStatus.actionType)
                        + this.getValue(returnCheckStatus.equipmentNature)
                        + this.getValue(returnCheckStatus.order, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return returnCheckStatus;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }

    private listByQuery(query: string): Promise<CheckStatus[]> {
        return this.sqlProvider.query(query)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let returnCheckStatuss: CheckStatus[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    returnCheckStatuss.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return CheckStatusDbDao.sortTypes(returnCheckStatuss);
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
