import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { ToastOptions } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";
import { TOAST_DELAY } from "../interfaces/constants";

@Injectable({
    providedIn: "root",
})
export class ToastHelper {
    constructor(
        private translateService: TranslateService,
        private toastCtrl: ToastController) {
    }

    public show(message: string, showCloseButton: boolean = false, onDidDismissCallback?: () => void): Promise<HTMLIonToastElement> {
        if (showCloseButton) {
            let opts: ToastOptions = {
                message: message,
                buttons: [],
                cssClass: "alert",
                position: "bottom",
            };

            if (showCloseButton) {
                opts.buttons.push({
                    text: this.translateService.instant("IMCP_Close"),
                    role: "cancel",
                });
            }

            return this.toastCtrl.create(opts)
                .then((toast) => {
                    if (onDidDismissCallback) {
                        toast.onDidDismiss().then(value => {
                            onDidDismissCallback();
                        });
                    }

                    toast.present();

                    return toast;
                });
        } else {
            return this.toastCtrl.create({
                message: message,
                duration: TOAST_DELAY,
                position: "bottom",
            })
                .then(toast => {
                    if (onDidDismissCallback) {
                        toast.onDidDismiss().then(value => {
                            onDidDismissCallback();
                        });
                    }

                    toast.present();

                    return toast;
                });
        }
    }
}
