import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { DATE_FORMAT, DATE_NODEJS_FORMAT } from "../../interfaces/constants";
import { DateHelper } from "../date.helper";

@Pipe({ name: "debugDateTime" })
export class DebugDateTimePipe implements PipeTransform {

    transform(date: string | Date | DateTime) {
        if (date == null) {
            return "null";
        }

        if (date == "") {
            return "empty";
        }

        if (date instanceof DateTime) {
            return date.toFormat(DATE_FORMAT) + "(DateTime)";
        } else if (date instanceof Date) {
            return DateTime.fromJSDate(date).toFormat(DATE_FORMAT) + "(Date)";
        } else if (typeof date === "string") {
            return DateHelper.tryFromFormat(date, DATE_NODEJS_FORMAT).toFormat(DATE_FORMAT) + "(string)";
        } else {
            return "Unknown date format";
        }
    }
}
