import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PARSE_DATE_HOUR_FORMAT, PICTURE_QUALITY } from "../interfaces/constants";
import { DateProvider } from "../interfaces/dateProvider";
import { DrawModal } from "../modals/draw/draw.modal";
import { ViewModal } from "../modals/view/view.modal";
import { GeolocationService } from "../services/geolocation.service";
import { FileHelper } from "./file.helper";
import { NavControllerExtended } from "./navControllerExtended";

@Injectable({
    providedIn: "root",
})
export class PictureHelper {
    constructor(private dateProvider: DateProvider,
                private modalController: ModalController,
                private geolocationService: GeolocationService,
                private navCtrl: NavControllerExtended) {
    }

    public static addLegend(uriImageData: string,
                            legend: string,
                            textSize: number,
                            pictureQuality: number): Promise<string> {
        return new Promise((resolve, reject) => {
            if (uriImageData == null) {
                return reject();
            }

            const canvas = document.createElement("canvas"),
                context = canvas.getContext("2d"),
                image = new Image();

            image.addEventListener(
                "load",
                ev => {
                    canvas.width = image.width;
                    canvas.height = image.height;

                    context.fillStyle = "#FFF";
                    context.fillRect(0, 0, image.width, image.height);

                    context.drawImage(image, 0, 0, image.width, image.height);

                    context.font = textSize + "px Georgia";
                    let textMargin = 4;
                    let yCoord = image.height - (textSize + textMargin);

                    let lines = legend.split("\n");

                    for (let i = lines.length - 1; i >= 0; i--) {
                        let textMetrics: TextMetrics = context.measureText(lines[i]);
                        context.fillStyle = "#FFF";
                        context.globalAlpha = 0.5;
                        context.fillRect(image.width - textMetrics.width - 10,
                            yCoord,
                            textMetrics.width + 20,
                            textSize + textMargin);

                        context.fillStyle = "#333";
                        context.globalAlpha = 1;
                        context.fillText(lines[i],
                            image.width - textMetrics.width - 5,
                            yCoord + (textSize));

                        yCoord -= textSize + textMargin;
                    }

                    resolve(canvas.toDataURL("image/jpeg", pictureQuality));
                },
                false);
            image.src = uriImageData;
        });
    }

    public static encodeToBase64(arrayBuffer: ArrayBuffer): string {
        let base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
        return FileHelper.guessDataUrlHeader(base64String.charAt(0)) + base64String;
    }

    public static resize(uriImageData: string,
                         pictureWidth: number,
                         pictureHeight: number,
                         pictureQuality: number): Promise<string> {
        return new Promise(
            (resolve, reject) => {
                if (uriImageData == null) {
                    return reject();
                }

                const canvas = document.createElement("canvas"),
                    context = canvas.getContext("2d"),
                    image = new Image();

                image.addEventListener(
                    "load",
                    ev => {
                        let maxWidth = pictureWidth,
                            maxHeight = pictureHeight,
                            imageWidth = image.width,
                            imageHeight = image.height;

                        if (imageWidth > imageHeight) {
                            if (imageWidth > maxWidth) {
                                imageHeight *= maxWidth / imageWidth;
                                imageWidth = maxWidth;
                            }
                        } else {
                            if (imageHeight > maxHeight) {
                                imageWidth *= maxHeight / imageHeight;
                                imageHeight = maxHeight;
                            }
                        }

                        canvas.width = imageWidth;
                        canvas.height = imageHeight;
                        context.drawImage(image, 0, 0, canvas.width, canvas.height);
                        resolve(canvas.toDataURL(FileHelper.extractMimeType(uriImageData), pictureQuality));
                    },
                    false);
                image.src = uriImageData;
            });
    }

    public async watermarkPicture(imageData: string,
                                  pictureQuality: number): Promise<string> {
        let legend = this.dateProvider.now().toFormat(PARSE_DATE_HOUR_FORMAT);
        let lastKnownLocation = await this.geolocationService.getCurrentPosition(false);
        if (lastKnownLocation) {
            legend += "\n";
            legend += " à " + lastKnownLocation.latitude.toFixed(4) + ", " + lastKnownLocation.longitude.toFixed(4);
        }

        let result = await PictureHelper.addLegend(imageData,
            legend,
            20,
            pictureQuality);

        return result;
    }

    public viewImage(imageData: string, canModifyPhoto: boolean): Promise<string> {
        this.navCtrl.params.clearParams();
        this.navCtrl.params.addParam("imageData", imageData);
        this.navCtrl.params.addParam("canModifyPhoto", canModifyPhoto);
        this.navCtrl.params.addParam("exportQuality", PICTURE_QUALITY);

        return new Promise<string>((resolve) => {
            if (canModifyPhoto) {
                this.modalController.create({
                    component: DrawModal,
                    backdropDismiss: false,
                })
                    .then(modal => {
                        modal.present();
                        modal.onDidDismiss()
                            .then(result => {
                                if (result.data) {
                                    resolve(result.data.base64);
                                } else {
                                    resolve(null);
                                }
                            });
                    });
            } else {
                this.modalController.create({
                    component: ViewModal,
                    backdropDismiss: false,
                })
                    .then(modal => {
                        modal.present();
                        modal.onDidDismiss()
                            .then(result => {
                                if (result.data) {
                                    resolve(result.data.base64);
                                } else {
                                    resolve(null);
                                }
                            });
                    });
            }
        });
    }
}
