import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Model } from "../../models/model.model";
import { PersonalModelDbDao } from "./personalModel.db.dao";

@Injectable({
    providedIn: "root",
})
export class ModelDbDao extends DbDaoBase<Model> {
    constructor(logger: LoggerService,
                private personalModelDbDao: PersonalModelDbDao,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Model.TABLENAME + "_id"
                    + " ON " + Model.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Model.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "personalModel TEXT,"
                    + "server NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Model.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Model.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Model> {
        let selectQuery = "SELECT * FROM " + Model.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let model: Model = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    if (hydrate) {
                        hydratationPromises.push(this.personalModelDbDao.get(model.personalModel.id, hydrate)
                            .then(value => {
                                model.personalModel = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return model;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Model.TABLENAME;
    }

    protected rowToModel(row: any): Model {
        let model = new Model();

        model.id = row.id;
        model.wording = row.wording;

        model.personalModel.id = row.personalModel;

        return model;
    }

    public save(model: Model): Promise<Model> {
        let promises = [];
        promises.push(this.personalModelDbDao.save(model.personalModel));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Model.TABLENAME + " ("
                            + "id, wording, personalModel"
                            + ") VALUES ("
                            + this.getValue(model.id)
                            + this.getValue(model.wording)
                            + this.getFkValue(model.personalModel, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return model;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
