import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Invoice } from "../../models/invoice.model";
import { AddressDbDao } from "./address.db.dao";
import { ConstructionSiteDbDao } from "./constructionSite.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class InvoiceDbDao extends DbDaoBase<Invoice> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private thirdPartyDbDao: ThirdPartyDbDao,
        private addressDbDao: AddressDbDao,
        private constructionSiteDbDao: ConstructionSiteDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Invoice.TABLENAME + "_id"
                    + " ON " + Invoice.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Invoice.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "documentNumber TEXT, "
                    + "groupingEvent TEXT, "
                    + "groupingMovement TEXT, "
                    + "isTruckDelivery NUMERIC, "
                    // Fks
                    + "prescriber TEXT, "
                    + "thirdParty TEXT, "
                    + "constructionSite TEXT, "
                    + "orderPicker TEXT, "
                    + "mainAddress TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Invoice.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Invoice.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Invoice> {
        let selectQuery = "SELECT * FROM " + Invoice.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let invoice: Invoice = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    hydratationPromises.push(this.constructionSiteDbDao.get(invoice.constructionSite.id, hydrate)
                        .then(value => {
                            invoice.constructionSite = value;
                        }));

                    hydratationPromises.push(this.thirdPartyDbDao.get(invoice.orderPicker.id, hydrate)
                        .then(value => {
                            invoice.orderPicker = value;
                        }));

                    if (hydrate) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(invoice.prescriber.id, hydrate)
                            .then(value => {
                                invoice.prescriber = value;
                            }));

                        hydratationPromises.push(this.thirdPartyDbDao.get(invoice.thirdParty.id, hydrate)
                            .then(value => {
                                invoice.thirdParty = value;
                            }));

                        hydratationPromises.push(this.addressDbDao.get(invoice.mainAddress.id, hydrate)
                            .then(value => {
                                invoice.mainAddress = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return invoice;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Invoice.TABLENAME;
    }

    protected rowToModel(row: any): Invoice {
        let invoice = new Invoice();
        invoice.id = row.id;
        invoice.wording = row.wording;
        invoice.documentNumber = row.documentNumber;
        invoice.groupingEvent = row.groupingEvent;
        invoice.groupingMovement = row.groupingMovement;
        invoice.isTruckDelivery = row.isTruckDelivery;

        // Fks
        invoice.prescriber.id = row.prescriber;
        invoice.thirdParty.id = row.thirdParty;
        invoice.constructionSite.id = row.constructionSite;
        invoice.orderPicker.id = row.orderPicker;
        invoice.mainAddress.id = row.mainAddress;

        return invoice;
    }

    public save(invoice: Invoice): Promise<Invoice> {
        let promises = [];
        promises.push(this.thirdPartyDbDao.save(invoice.prescriber));
        promises.push(this.thirdPartyDbDao.save(invoice.thirdParty));
        promises.push(this.constructionSiteDbDao.save(invoice.constructionSite));
        promises.push(this.thirdPartyDbDao.save(invoice.orderPicker));

        promises.push(this.addressDbDao.save(invoice.mainAddress));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Invoice.TABLENAME + " ("
                            + "id, wording, documentNumber, groupingEvent, groupingMovement, isTruckDelivery,"
                            // FKs
                            + "prescriber, thirdParty, constructionSite, orderPicker, mainAddress"
                            + ") VALUES ("
                            + this.getValue(invoice.id)
                            + this.getValue(invoice.wording)
                            + this.getValue(invoice.documentNumber)
                            + this.getValue(invoice.groupingEvent)
                            + this.getValue(invoice.groupingMovement)
                            + this.getValue(invoice.isTruckDelivery)
                            // FKs
                            + this.getFkValue(invoice.prescriber)
                            + this.getFkValue(invoice.thirdParty)
                            + this.getFkValue(invoice.constructionSite)
                            + this.getFkValue(invoice.orderPicker)
                            + this.getFkValue(invoice.mainAddress, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return invoice;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
