import { Component, Input } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DepartureMaterialDto } from "src/core/dto/material.dto";
import { EquipmentDto } from "../../../core/dto/equipment.dto";
import { ModelDto } from "../../../core/dto/model.dto";
import { EquipmentService } from "../../../core/services/equipment.service";
import { LoadingHelper } from "../../../gyzmo-commons/helpers/loading.helper";
import { NavControllerExtended } from "../../../gyzmo-commons/helpers/navControllerExtended";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";

@Component({
    templateUrl: "equipment-search.modal.html",
    styleUrls: ["equipment-search.modal.scss"],
})
export class EquipmentSearchModal {
    @Input() model: ModelDto;
    @Input() lines: DepartureMaterialDto[];

    public equipments: EquipmentDto[];
    public filterTerm: string = "";
    public filteredEquipments: EquipmentDto[];
    public searching: boolean = false;

    Array = Array;

    constructor(navControllerExtended: NavControllerExtended,
                private modalController: ModalController,
                private loadingHelper: LoadingHelper,
                private alertController: AlertController,
                private translateService: TranslateService,
                private equipmentService: EquipmentService) {
    }

    public async ionViewWillEnter() {
        this.searching = true;
        this.equipments = await this.equipmentService.getListByModel(this.model);
        this.filteredEquipments = this.equipments;
        this.searching = false;
        this.filter();
    }

    public dismiss(data?: any) {
        void this.modalController.dismiss(data);
    }

    public filter() {
        // On enlève des résultats les équipements déjà utilisés
        this.filteredEquipments.forEach((equipment, index) => {
            this.lines.forEach(line => {
                if (line.equipment.id == equipment.id) {
                    console.log("ee");
                    this.filteredEquipments.splice(index, 1);
                }
            });
        });

        if (isNullOrEmpty(this.filterTerm)) {
            this.filteredEquipments = this.equipments;
            return;
        }

        this.filteredEquipments = this.equipments.filter(element => {
            if (element.title
                && element.title.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0) {
                return true;
            }

            if (element.registrationNumber
                && element.registrationNumber.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0) {
                return true;
            }

            if (element.id
                && element.id.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0) {
                return true;
            }

            return false;
        });
    }

    async selectEquipment(equipmentDto: EquipmentDto) {
        await this.loadingHelper.showLoading();

        void this.equipmentService.getById(equipmentDto)
            .then(async updatedEquipmentDto => {
                this.dismiss(updatedEquipmentDto);
                await this.loadingHelper.hideAll();
            });
    }

    public async help() {
        const alert = await this.alertController.create({
            header: this.translateService.instant("IMCP_Help"),
            message: this.translateService.instant("IMCP_Vous_pouvez_filtrer_sur_le_code_parc_l_immatriculation_ou_le_libellé_du_matériel."),
        });

        await alert.present();
    }
}
