// Différentes catégories possibles d'une note de frais
export enum ExpensesCategories {
    AUTOR = "AUTOR",
    CARB = "CARB",
    DIVERS = "DIVERS",
    HOTEL = "HOTEL",
    KM = "KM",
    PARKING = "PARKING",
    REPAS = "REPAS",
    TRANSPORT = "TRANSPORT",
}
