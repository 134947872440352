import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Service extends ModelBase {
    static TABLENAME = "Services";

    public id: string = "";

    constructor() {
        super(Service.TABLENAME);
    }
}
