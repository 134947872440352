import { Injectable } from "@angular/core";
import { CacheDbDao } from "../../gyzmo-commons/dao/db/cache.db.dao";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "../dao/db/address.db.dao";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { CheckStatusDbDao } from "../dao/db/checkStatus.db.dao";
import { CompanyDbDao } from "../dao/db/company.db.dao";
import { ConstructionSiteDbDao } from "../dao/db/constructionSite.db.dao";
import { ContactDetailsDbDao } from "../dao/db/contactDetails.db.dao";
import { DeliveryDbDao } from "../dao/db/delivery.db.dao";
import { DeliveryLinkedMovementDbDao } from "../dao/db/deliveryLinkedMovement.db.dao";
import { DriverInfoDbDao } from "../dao/db/driverInfo.db.dao";
import { EquipmentDbDao } from "../dao/db/equipment.db.dao";
import { HourTypeDbDao } from "../dao/db/hourType.db.dao";
import { InvoiceDbDao } from "../dao/db/invoice.db.dao";
import { InvoiceLineDbDao } from "../dao/db/invoiceLine.db.dao";
import { LocationDbDao } from "../dao/db/location.db.dao";
import { ModelDbDao } from "../dao/db/model.db.dao";
import { MovementDbDao } from "../dao/db/movement.db.dao";
import { OfflineModeDbDao } from "../dao/db/offlineMode.db.dao";
import { OrderDbDao } from "../dao/db/order.db.dao";
import { OrderLinkedMovementDbDao } from "../dao/db/orderLinkedMovement.db.dao";
import { PersonalModelDbDao } from "../dao/db/personalModel.db.dao";
import { SectorDbDao } from "../dao/db/sector.db.dao";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { SurveyKindDbDao } from "../dao/db/surveyKind.db.dao";
import { ThirdPartyDbDao } from "../dao/db/thirdParty.db.dao";
import { TimesDbDao } from "../dao/db/times.db.dao";
import { UserDbDao } from "../dao/db/user.db.dao";

@Injectable({
    providedIn: "root",
})
export class DaoProvider {
    constructor(private addressDbDao: AddressDbDao,
                private cacheDbDao: CacheDbDao,
                private contactDetailsDbDao: ContactDetailsDbDao,
                private driverInfoDbDao: DriverInfoDbDao,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private userDbDao: UserDbDao,
                private attachmentDbDao: AttachmentDbDao,
                private equipmentDbDao: EquipmentDbDao,
                private movementDbDao: MovementDbDao,
                private locationDbDao: LocationDbDao,
                private sectorDbDao: SectorDbDao,
                private orderDbDao: OrderDbDao,
                private deliveryDbDao: DeliveryDbDao,
                private keyValueDbDao: KeyValueDbDao,
                private versionDbDao: VersionDbDao,
                private companyDbDao: CompanyDbDao,
                private offlineModeDbDao: OfflineModeDbDao,
                private invoiceDbDao: InvoiceDbDao,
                private invoiceLineDbDao: InvoiceLineDbDao,
                private deliveryLinkedMovementDbDao: DeliveryLinkedMovementDbDao,
                private orderLinkedMovementDbDao: OrderLinkedMovementDbDao,
                private constructionSiteDbDao: ConstructionSiteDbDao,
                private returnCheckStatusDbDao: CheckStatusDbDao,
                private modelDbDao: ModelDbDao,
                private personalModelDbDao: PersonalModelDbDao,
                private serverDbDao: ServerDbDao,
                private hourTypeDbDao: HourTypeDbDao,
                private surveyKindDbDao: SurveyKindDbDao,
                private timesDbDao: TimesDbDao) {
    }

    public getAllDaoList() {
        return [this.keyValueDbDao,
                this.versionDbDao,
                this.cacheDbDao,
                this.addressDbDao,
                this.contactDetailsDbDao,
                this.driverInfoDbDao,
                this.thirdPartyDbDao,
                this.userDbDao,
                this.attachmentDbDao,
                this.equipmentDbDao,
                this.movementDbDao,
                this.locationDbDao,
                this.sectorDbDao,
                this.orderDbDao,
                this.deliveryDbDao,
                this.companyDbDao,
                this.offlineModeDbDao,
                this.invoiceDbDao,
                this.invoiceLineDbDao,
                this.deliveryLinkedMovementDbDao,
                this.orderLinkedMovementDbDao,
                this.constructionSiteDbDao,
                this.returnCheckStatusDbDao,
                this.modelDbDao,
                this.personalModelDbDao,
                this.serverDbDao,
                this.hourTypeDbDao,
                this.surveyKindDbDao,
                this.timesDbDao];
    }

    public getOnDisconnectDaoList() {
        return [this.versionDbDao,
            //this.cacheDbDao,
                this.addressDbDao,
                this.contactDetailsDbDao,
                this.driverInfoDbDao,
                this.thirdPartyDbDao,
                this.userDbDao,
                this.attachmentDbDao,
                this.equipmentDbDao,
                this.movementDbDao,
                this.locationDbDao,
                this.sectorDbDao,
                this.orderDbDao,
                this.deliveryDbDao,
                this.companyDbDao,
                this.offlineModeDbDao,
                this.invoiceDbDao,
                this.invoiceLineDbDao,
                this.deliveryLinkedMovementDbDao,
                this.orderLinkedMovementDbDao,
                this.constructionSiteDbDao,
                this.returnCheckStatusDbDao,
                this.modelDbDao,
                this.personalModelDbDao,
                this.serverDbDao,
                this.hourTypeDbDao,
                this.surveyKindDbDao,
                this.timesDbDao];
    }
}
