import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class ThirdPartyWsDao extends WsDao<ThirdPartyDto> {
    static WS = "third-parties";

    public getById(serverConnection: ServerConnection, id: string): Promise<ThirdPartyDto> {
        return new Promise<ThirdPartyDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ThirdPartyWsDao.WS + "/:id", tokens)
                .then(response => {
                    let thirdParty = ThirdPartyDto.fromBody(response.body);

                    let promises = [];
                    Promise.all(promises)
                        .then(() => {
                            resolve(thirdParty);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, thirdPartyDto: ThirdPartyDto): Promise<ThirdPartyDto> {
        return new Promise<ThirdPartyDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            if (!isNullOrEmpty(thirdPartyDto.id)) {
                tokens.set("id", thirdPartyDto.id);

                serverConnection.put(this.constructor.name, ThirdPartyWsDao.WS + "/:id", tokens, thirdPartyDto.toBody())
                    .then(response => {
                        // On redemande le third-party
                        this.getById(serverConnection, thirdPartyDto.id)
                            .then(updatedThirdParty => {
                                resolve(updatedThirdParty);
                            });
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                serverConnection.post(this.constructor.name, ThirdPartyWsDao.WS + "", tokens, thirdPartyDto.toBody())
                    .then(response => {
                        thirdPartyDto.id = response.headers.get(XVEGAID.toLowerCase());
                        // On redemande le third-party
                        this.getById(serverConnection, thirdPartyDto.id)
                            .then(updatedThirdParty => {
                                resolve(updatedThirdParty);
                            });
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }
}
