import { Injectable } from "@angular/core";
import { FrenchGovernmentApiGeocoding } from "./geocodings/frenchGovernmentApi.geocoding";
import { GeocoderForwardResult } from "./geocodings/geocoderForwardResult";
import { GeocoderReverseResult } from "./geocodings/geocoderReverseResult";
import { OpenStreetMapGeocoding } from "./geocodings/openStreetMap.geocoding";

@Injectable({
    providedIn: "root",
})
export class GeocodingService {
    constructor(private openStreetMapGeocoding: OpenStreetMapGeocoding,
                private frenchGovernmentApiGeocoding: FrenchGovernmentApiGeocoding) {
    }

    public reverseGeocode(latitude: number, longitude: number): Promise<GeocoderReverseResult[]> {
        return new Promise<GeocoderReverseResult[]>((resolve, reject) => {
            this.frenchGovernmentApiGeocoding.reverseGeocode(latitude, longitude)
                .then(result => {
                    resolve(result);
                })
                .catch(reason => {
                    this.openStreetMapGeocoding.reverseGeocode(latitude, longitude)
                        .then(result => {
                            resolve(result);
                        })
                        .catch(reason => {
                            reject(reason);
                        });
                });
        });
    }

    public forwardGeocode(address: string): Promise<GeocoderForwardResult[]> {
        return new Promise<GeocoderForwardResult[]>((resolve, reject) => {
            this.frenchGovernmentApiGeocoding.forwardGeocode(address)
                .then(result => {
                    resolve(result);
                })
                .catch(reason => {
                    this.openStreetMapGeocoding.forwardGeocode(address)
                        .then(result => {
                            resolve(result);
                        })
                        .catch(reason => {
                            reject(reason);
                        });
                });
        });
    }

    public listCitiesFromPostalCode(postalCode: string): Promise<string[]> {
        return this.frenchGovernmentApiGeocoding.listCitiesFromPostalCode(postalCode);
    }
}
