import { Component, Input } from "@angular/core";
import { EquipmentDto } from "../../../core/dto/equipment.dto";

@Component({
    selector: "equipment-info",
    templateUrl: "equipment-info.component.html",
    styleUrls: ["equipment-info.component.scss"],
})
export class EquipmentInfoComponent {
    @Input() equipment: EquipmentDto;

}
