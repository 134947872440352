import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { BaseDto } from "./base/base.dto";
import { ConstructionSiteDto } from "./constructionSite.dto";
import { MovementDto } from "./movement.dto";

export class ReturnSearchResultDto extends BaseDto<void> {
    public contractId: string = "";
    public contractNumber: string = "";
    public invoiceId: string = "";
    public groupingCode: string = "";
    public constructionSite: ConstructionSiteDto = new ConstructionSiteDto();
    public movement: MovementDto = new MovementDto();

    constructor() {
        super();

        this.constructionSite = new ConstructionSiteDto();
        this.movement = new MovementDto();
    }

    static fromBody(body: any): ReturnSearchResultDto {
        let returnCheckStatusDto = new ReturnSearchResultDto();

        returnCheckStatusDto.contractId = body.field0;
        returnCheckStatusDto.contractNumber = body.field1;
        returnCheckStatusDto.movement.startDate = DateHelper.tryFromFormat(body.field2, DATE_NODEJS_FORMAT);

        // invoice
        returnCheckStatusDto.invoiceId = body.field3;
        // grouping code
        returnCheckStatusDto.groupingCode = body.field4;

        if (body.field5 || body.field6) {
            returnCheckStatusDto.movement.customer.firstName = body.field5;
            returnCheckStatusDto.movement.customer.lastName = body.field6;
            returnCheckStatusDto.movement.customer.mainAddress.address1 = body.field7;
            returnCheckStatusDto.movement.customer.mainAddress.address2 = body.field8;
            returnCheckStatusDto.movement.customer.mainAddress.zipCode = body.field9;
            returnCheckStatusDto.movement.customer.mainAddress.city = body.field10;
        }

        if (body.field11 || body.field12) {
            returnCheckStatusDto.constructionSite.siteName = body.field11 + " " + body.field12;
            returnCheckStatusDto.constructionSite.mainAddress.address1 = body.field13;
            returnCheckStatusDto.constructionSite.mainAddress.address2 = body.field14;
            returnCheckStatusDto.constructionSite.mainAddress.zipCode = body.field15;
            returnCheckStatusDto.constructionSite.mainAddress.city = body.field16;
        }

        return returnCheckStatusDto;
    }

    public toBody() {
        throw new Error("Method not implemented.");
    }

    public toModel(): void {
        throw new Error("Method not implemented.");
    }
}
