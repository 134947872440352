import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { NotImplementedError } from "../../gyzmo-commons/helpers/notImplementedError";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { ExpensesCategories } from "../interfaces/expense.categories.constants";
import { AttachmentDto } from "./attachment.dto";
import { BaseDto } from "./base/base.dto";
import { ExpenseCategoryDto } from "./expenseCategory.dto";
import { ExpenseStateDto } from "./expenseState.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class ExpenseDto extends BaseDto<void> {
    public id: string = "";
    public wording: string = "";

    public date: DateTime = null;
    public startDate: DateTime = null;
    public endDate: DateTime = null;
    public state: ExpenseStateDto = new ExpenseStateDto();

    public category: ExpenseCategoryDto = new ExpenseCategoryDto();
    public amount: number = 0;
    public kilometers: number = 0;

    public driver: ThirdPartyDto = new ThirdPartyDto();
    public customer: ThirdPartyDto = new ThirdPartyDto();
    public supplier: ThirdPartyDto = new ThirdPartyDto();

    //Gestion de la TVA, la laisser à null
    public vatAmount: number = null;

    linkedMovement: { id: string } = null;
    type: string = "";
    constructionSite: { id: string } = null;

    public attachments: AttachmentDto[] = [];

    public toBody(): any {
        const clone: any = { ...this };

        clone.date = DateHelper.tryToFormat(this.date, DATE_NODEJS_FORMAT);
        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.endDate = DateHelper.tryToFormat(this.endDate, DATE_NODEJS_FORMAT);

        if (this.state) {
            clone.state = this.state.toBody();
        }
        if (this.category) {
            clone.category = this.category.toBody();
        }
        if (this.driver) {
            clone.driver = this.driver.toBody();
        }
        if (this.customer) {
            clone.customer = this.customer.toBody();
        }
        if (this.supplier) {
            clone.supplier = this.supplier.toBody();
        }

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            clone.attachments.push(attachment.toBody());
        });

        if (this.category.id == ExpensesCategories.KM) {
            clone.amount = 0;
        }

        return clone;
    }

    public toModel(): void {
        throw new NotImplementedError();
    }
}
