import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class ZoomWsDao extends WsDao<any> {
    static WS = "technical/zoom-links";

    public getById(serverConnection: ServerConnection, id: string): Promise<any> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, ZoomDto: any): Promise<any> {
        throw new NotImplementedError();
    }

    public getParameters(serverConnection: ServerConnection, id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ZoomWsDao.WS + "/:id/parameters", tokens)
                .then(response => {
                    resolve(response.body);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public execute(serverConnection: ServerConnection, id: string, params: string): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ZoomWsDao.WS + "/:id/execute?" + params, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        resolve((response.body as Array<any>));
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
