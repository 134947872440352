import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy/ngx";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DateTime } from "luxon";
import { DaoModule } from "../core/dao/db/dao.module";
import { UserWsDao } from "../core/dao/ws/user.ws.dao";
import { WorkflowWsDao } from "../core/dao/ws/workflow.ws.dao";
import { WsModule } from "../core/dao/ws/ws.module";
import { HttpErrorHandler } from "../core/http/httpErrorHandler";
import { DaoProvider } from "../core/persistence/dao.provider";
import { ServersConnectionsProvider } from "../core/providers/serversConnections.provider";
import { OfflineModeService } from "../core/services/offlineMode.service";
import { UserService } from "../core/services/user.service";
import { environment } from "../environments/environment";
import { KeyValueDbDao } from "../gyzmo-commons/dao/db/keyValue.db.dao";
import { DirectivesModule } from "../gyzmo-commons/directives/directives.module";
import { AssetsHelper } from "../gyzmo-commons/helpers/assets.helper";
import { DeviceHelper } from "../gyzmo-commons/helpers/device.helper";
import { LoadingHelper } from "../gyzmo-commons/helpers/loading.helper";
import { NavControllerExtended } from "../gyzmo-commons/helpers/navControllerExtended";
import { NetworkHelper } from "../gyzmo-commons/helpers/network.helper";
import { PictureHelper } from "../gyzmo-commons/helpers/picture.helper";
import { PipesModule } from "../gyzmo-commons/helpers/pipes/pipes.module";
import { ToastHelper } from "../gyzmo-commons/helpers/toast.helper";
import { AngularHttpClient } from "../gyzmo-commons/http/angularHttpClient";
import { AngularHttpClientWithMock } from "../gyzmo-commons/http/angularHttpClientWithMock";
import { NativeHttpClient } from "../gyzmo-commons/http/nativeHttpClient";
import { DateProvider } from "../gyzmo-commons/interfaces/dateProvider";
import { BrowserCameraModalModule } from "../gyzmo-commons/modals/browser-camera/browserCamera.module";
import { DrawModalModule } from "../gyzmo-commons/modals/draw/draw.module";
import { ViewModalModule } from "../gyzmo-commons/modals/view/view.module";
import { AppSqlProvider } from "../gyzmo-commons/persistence/app.sql.provider";
import { Database } from "../gyzmo-commons/persistence/database";
import { HttpClientProvider } from "../gyzmo-commons/providers/httpClientProvider";
import { MockDateProvider } from "../gyzmo-commons/providers/mockDateProvider";
import { NavParamsProvider } from "../gyzmo-commons/providers/navParams.provider";
import { RealDateProvider } from "../gyzmo-commons/providers/realDateProvider";
import { AppVersionService } from "../gyzmo-commons/services/appVersion.service";
import { CacheService } from "../gyzmo-commons/services/cache.service";
import { CameraService } from "../gyzmo-commons/services/camera.service";
import { DatabaseVersionService } from "../gyzmo-commons/services/databaseVersion.service";
import { GeocodingService } from "../gyzmo-commons/services/geocoding.service";
import { GeolocationService } from "../gyzmo-commons/services/geolocation.service";
import { LanguageService } from "../gyzmo-commons/services/language.service";
import { LoggerService } from "../gyzmo-commons/services/logs/logger.service";
import { ThirdPartyTypesWs } from "../gyzmo-commons/ws/thirdPartyTypes.ws";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { APP_OPTIONS } from "./app.constants";
import { EquipmentSearchModalModule } from "./modal/equipment-search/equipment-search.module";
import { PopoversModule } from "./popovers/popovers.module";

registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

export function createHttpClient(logger: LoggerService,
                                 httpClient: HttpClient,
                                 assetsHelper: AssetsHelper,
                                 keyValueDbDao: KeyValueDbDao) {
    return environment.mocked ? new AngularHttpClientWithMock(logger, httpClient, assetsHelper, keyValueDbDao) : new AngularHttpClient(logger, httpClient);
}

function createDateProvider() {
    return environment.mocked ? new MockDateProvider(DateTime.local(2023, 12, 21, 15, 50)) : new RealDateProvider();
}

function getAppOptions() {
    let appOptions = APP_OPTIONS;

    const currentUrl = window.location.href;
    if (!appOptions["mode"] && currentUrl.endsWith("/#/ios")) {
        console.warn("App forced in ios mode !");
        appOptions.mode = "ios";
    }

    // @ts-ignore
    if (window.playwright) {
        appOptions.animated = false;
    }

    return appOptions;
}

export class DefaultMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return "~" + params.key;
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        PipesModule,
        DirectivesModule,
        PopoversModule,
        DaoModule,
        WsModule,
        AppRoutingModule,
        BrowserCameraModalModule,
        DrawModalModule,
        ViewModalModule,
        EquipmentSearchModalModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: DefaultMissingTranslationHandler },
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot(getAppOptions()),
    ],
    providers: [
        AssetsHelper,
        Database,
        DeviceHelper,
        File,
        HttpErrorHandler,
        LoadingHelper,
        LocationAccuracy,
        NavControllerExtended,
        NavParamsProvider,
        NetworkHelper,
        ServersConnectionsProvider,
        ThirdPartyTypesWs,
        ToastHelper,
        AppSqlProvider,
        UserWsDao,
        WorkflowWsDao,
        UserService,
        OfflineModeService,
        CacheService,
        GeolocationService,
        PictureHelper,
        LanguageService,
        CameraService,
        HttpClientProvider,
        HTTP,
        NativeHttpClient,
        SQLite,
        GeocodingService,
        GeolocationService,
        DaoProvider,
        LoggerService,
        // Pour le test du processus de mise à jour de la structure base
        //{ provide: DatabaseVersionService, useValue: new DatabaseVersionServiceMock("0.0.1") },
        //{ provide: AppVersionService, useValue: new AppVersionServiceMock("99") },
        // Pour la prod
        { provide: DatabaseVersionService, useClass: DatabaseVersionService },
        { provide: AppVersionService, useClass: AppVersionService },
        { provide: AngularHttpClient, useFactory: createHttpClient, deps: [LoggerService, HttpClient, AssetsHelper, KeyValueDbDao] },
        { provide: DateProvider, useFactory: createDateProvider },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: "fr-FR" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
