// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu ion-footer {
  background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,2EAAA;AACJ;;AAEA;EACI,yEAAA;AACJ;;AAEA;EACI,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,sDAAA;AACJ;;AAEA;EACI,+BAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,+BAAA;AACJ;;AAEA;EACI,eAAA;EACA,cAAA;AACJ;;AAEA;EACI,iCAAA;AACJ","sourcesContent":["ion-menu ion-content {\r\n    --background: var(--ion-item-background, var(--ion-background-color, #fff));\r\n}\r\n\r\nion-menu ion-footer {\r\n    background: var(--ion-item-background, var(--ion-background-color, #fff));\r\n}\r\n\r\nion-menu.md ion-content {\r\n    --padding-start: 8px;\r\n    --padding-end: 8px;\r\n    --padding-top: 20px;\r\n    --padding-bottom: 20px;\r\n}\r\n\r\nion-menu.md ion-item {\r\n    --padding-start: 10px;\r\n    --padding-end: 10px;\r\n    border-radius: 4px;\r\n}\r\n\r\nion-menu.md ion-item.selected {\r\n    --background: rgba(var(--ion-color-primary-rgb), 0.14);\r\n}\r\n\r\nion-menu.md ion-item.selected ion-icon {\r\n    color: var(--ion-color-primary);\r\n}\r\n\r\nion-menu.md ion-item ion-icon {\r\n    color: #616e7e;\r\n}\r\n\r\nion-menu.md ion-item ion-label {\r\n    font-weight: 500;\r\n}\r\n\r\nion-menu.ios ion-content {\r\n    --padding-bottom: 20px;\r\n}\r\n\r\nion-menu.ios ion-list {\r\n    padding: 20px 0 0 0;\r\n}\r\n\r\nion-menu.ios ion-item {\r\n    --padding-start: 16px;\r\n    --padding-end: 16px;\r\n    --min-height: 50px;\r\n}\r\n\r\nion-menu.ios ion-item.selected ion-icon {\r\n    color: var(--ion-color-primary);\r\n}\r\n\r\nion-menu.ios ion-item ion-icon {\r\n    font-size: 24px;\r\n    color: #73849a;\r\n}\r\n\r\nion-item.selected {\r\n    --color: var(--ion-color-primary);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
