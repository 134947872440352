import { Injectable } from "@angular/core";
import { MovementDto } from "../../dto/movement.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { LocationWsDao } from "./location.ws.dao";

@Injectable({
    providedIn: "root",
})
export class MovementWsDao extends WsDao<MovementDto> {
    static WS = "movements";

    constructor(private locationWsDao: LocationWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, MovementWsDao.WS + "/:id", tokens)
                .then(response => {
                    let movement = MovementDto.fromBody(response.body);

                    let promises = [];
                    if (movement.startLocation.id) {
                        promises.push(this.locationWsDao.getById(serverConnection, movement.startLocation.id)
                            .then(value => {
                                movement.startLocation = value;
                            }));
                    }
                    if (movement.returnLocation.id) {
                        promises.push(this.locationWsDao.getById(serverConnection, movement.returnLocation.id)
                            .then(value => {
                                movement.returnLocation = value;
                            }));
                    }

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(movement);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, movement: MovementDto): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", movement.id);

            serverConnection.put(this.constructor.name, MovementWsDao.WS + "/:id", tokens, movement.toBody())
                .then(response => {
                    resolve(movement);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
