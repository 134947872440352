// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --overflow: hidden;
  --background: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/popovers/progress-dialog/progress-dialog.popover.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,oCAAA;AACJ","sourcesContent":["ion-content {\r\n    --overflow: hidden;\r\n    --background: var(--ion-color-light);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
