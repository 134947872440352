import { Model } from "../models/model.model";
import { BaseDto } from "./base/base.dto";
import { PersonalModelDto } from "./personalModel.dto";

export class ModelDto extends BaseDto<Model> {
    public id: string = "";
    public wording: string = "";

    public personalModel: PersonalModelDto = null;

    constructor() {
        super();

        this.personalModel = new PersonalModelDto();
    }

    static fromBody(body: any): ModelDto {
        let modelDto = new ModelDto();

        modelDto.id = body.id;
        modelDto.wording = body.wording;

        if (body.personalModel) {
            modelDto.personalModel = PersonalModelDto.fromBody(body.personalModel);
        }

        return modelDto;
    }

    static fromModel(model: Model): ModelDto {
        let modelDto = new ModelDto();

        modelDto.id = model.id;
        modelDto.wording = model.wording;

        if (model.personalModel) {
            modelDto.personalModel = PersonalModelDto.fromModel(model.personalModel);
        }

        return modelDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.personalModel = this.personalModel.toBody();

        return clone;
    }

    public toModel(): Model {
        let model = new Model();

        model.id = this.id;
        model.wording = this.wording;

        if (this.personalModel) {
            model.personalModel = this.personalModel.toModel();
        }

        return model;
    }
}
