import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Address } from "./address.model";
import { ConstructionSite } from "./constructionSite.model";
import { Equipment } from "./equipment.model";
import { Model } from "./model.model";
import { Service } from "./service.model";
import { ThirdParty } from "./thirdParty.model";

export class DeliveryLinkedMovement extends ModelBase {
    static TABLENAME = "DeliveryLinkedMovements";

    id: string = "";
    wording: string = "";
    movementId: string = "";
    fatherMovementId: string = "";
    quantity: number = 0;
    deliveredQuantity: number = 0;
    order: string = "";
    isClosed: number = 0;

    startDate: string = "";
    plannedReturnDate: string = "";

    // Fks
    prescriber: ThirdParty;
    customer: ThirdParty;
    constructionSite: ConstructionSite = null;

    model: Model = null;

    service: Service = null;

    equipment: Equipment = null;

    deliveryAddress: Address = null;

    constructor() {
        super(DeliveryLinkedMovement.TABLENAME);

        this.prescriber = new ThirdParty();
        this.customer = new ThirdParty();
        this.constructionSite = new ConstructionSite();

        this.model = new Model();

        this.service = new Service();

        this.equipment = new Equipment();

        this.deliveryAddress = new Address();
    }
}
