import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { DeliveryStates } from "../interfaces/deliveryStates";

export class Time extends ModelBase {
    static TABLENAME = "Times";

    public id: number = null;
    public fatherMovementId: string = "";
    public customerId: string = "";
    public constructionSiteId: string = "";
    public index: number = 0;
    public type: DeliveryStates = DeliveryStates.NOT_STARTED;
    public startTime: string = "";
    public endTime: string = "";

    constructor() {
        super(Time.TABLENAME);
    }
}
