import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Equipment } from "../models/equipment.model";
import { BaseDto } from "./base/base.dto";
import { ModelDto } from "./model.dto";
import { PersonalModelDto } from "./personalModel.dto";

export class EquipmentDto extends BaseDto<Equipment> {
    public id: string = "";
    public title: string = "";
    public options: string = "";
    public description: string = "";
    public color: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public picture: string = "";
    public trackerVersion: number = 0;
    public registrationNumber: string = "";

    public model: ModelDto = null;

    // volatile
    public mileage: number = 0;
    public mileageDate: DateTime = null;

    public personalModel: PersonalModelDto = null;

    constructor() {
        super();

        this.model = new ModelDto();
        this.personalModel = new PersonalModelDto();
    }

    public static fromBody(body: any): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.trackerVersion = 1;
        equipmentDto.id = body.id;
        equipmentDto.latitude = Number(body.latitude);
        equipmentDto.longitude = Number(body.longitude);
        equipmentDto.title = body.wording.trim();
        equipmentDto.color = body.color;

        equipmentDto.registrationNumber = body.registrationNumber;
        if (body.registration && isNullOrEmpty(equipmentDto.registrationNumber)) {
            equipmentDto.registrationNumber = body.registration.registration;
        }

        if (body.model) {
            equipmentDto.model = ModelDto.fromBody(body.model);
        }

        if (body.personalModel) {
            equipmentDto.personalModel = PersonalModelDto.fromBody(body.personalModel);
        }

        equipmentDto.mileage = body.mileageValue;
        equipmentDto.mileageDate = DateHelper.tryFromFormat(body.mileageDate, DATE_NODEJS_FORMAT);

        return equipmentDto;
    }

    static fromModel(equipment: Equipment): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.id = equipment.id;
        equipmentDto.latitude = equipment.latitude;
        equipmentDto.longitude = equipment.longitude;
        equipmentDto.title = equipment.title;
        equipmentDto.color = equipment.color;
        equipmentDto.trackerVersion = equipment.trackerVersion;
        equipmentDto.registrationNumber = equipment.registrationNumber;

        equipmentDto.options = equipment.options;
        equipmentDto.description = equipment.description;
        equipmentDto.picture = equipment.picture;

        equipmentDto.mileage = equipment.mileage;
        equipmentDto.mileageDate = DateHelper.tryFromISO(equipment.mileageDate);

        if (equipment.model) {
            equipmentDto.model = ModelDto.fromModel(equipment.model);
        }

        if (equipment.personalModel) {
            equipmentDto.personalModel = PersonalModelDto.fromModel(equipment.personalModel);
        }

        return equipmentDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.model = this.model.toBody();
        clone.personalModel = this.personalModel.toBody();

        delete clone.mileageDate;

        return clone;
    }

    toModel() {
        let equipment = new Equipment();

        equipment.id = this.id;
        equipment.latitude = this.latitude;
        equipment.longitude = this.longitude;
        equipment.title = this.title;
        equipment.color = this.color;
        equipment.trackerVersion = this.trackerVersion;
        equipment.registrationNumber = this.registrationNumber;

        equipment.options = this.options;
        equipment.description = this.description;
        equipment.picture = this.picture;

        equipment.mileage = this.mileage;
        equipment.mileageDate = DateHelper.tryToISO(this.mileageDate);

        if (this.model) {
            equipment.model = this.model.toModel();
        }

        if (this.personalModel) {
            equipment.personalModel = this.personalModel.toModel();
        }

        return equipment;
    }
}

