import { DEFAULT_MAX_TANK_CAPACITY } from "../../app/app.constants";
import { CounterKind, PersonalModel } from "../models/personalModel.model";
import { BaseDto } from "./base/base.dto";

export enum CounterKinds {
    None = "0",
    Kilometers = "1",
    Hour = "3",
}

export class CounterKindDto {
    public id: string = "0";
    public wording: string = "";
    public maxFuel: number = 0;

    static fromBody(body: any): CounterKindDto {
        let modelDto = new CounterKindDto();

        modelDto.id = body.id;
        modelDto.wording = body.wording;

        if (body.fraction) {
            modelDto.maxFuel = body.fraction;
        } else {
            modelDto.maxFuel = DEFAULT_MAX_TANK_CAPACITY;
        }

        return modelDto;
    }

    static fromModel(model: CounterKind): CounterKindDto {
        let modelDto = new CounterKindDto();

        modelDto.id = model.id;
        modelDto.wording = model.wording;
        modelDto.maxFuel = model.maxFuel;

        return modelDto;
    }

    public toModel(): CounterKind {
        let model = new CounterKind();

        model.id = this.id;
        model.wording = this.wording;
        model.maxFuel = this.maxFuel;

        return model;
    }
}

export class PersonalModelDto extends BaseDto<PersonalModel> {
    public id: string = "";
    public wording: string = "";

    public category: any = {};

    public doors: number = 0;
    public seatings: number = 0;
    public motorization: string = "";

    public counterKind: CounterKindDto = null;
    public counterKind2: CounterKindDto = null;

    constructor() {
        super();

        this.counterKind = new CounterKindDto();
        this.counterKind2 = new CounterKindDto();
    }

    static fromBody(body: any): PersonalModelDto {
        let modelDto = new PersonalModelDto();

        modelDto.id = body.id;
        modelDto.wording = body.wording;

        modelDto.doors = body.doors;
        modelDto.seatings = body.registrationS1;

        if (body.energy) {
            modelDto.motorization = body.energy.wording;
        }

        if (body.category) {
            modelDto.category = body.category;
        }

        if (body.counterKind) {
            modelDto.counterKind = CounterKindDto.fromBody(body.counterKind);
        }

        if (body.counterKind2) {
            modelDto.counterKind2 = CounterKindDto.fromBody(body.counterKind2);
        }

        return modelDto;
    }

    static fromModel(model: PersonalModel): PersonalModelDto {
        let modelDto = new PersonalModelDto();

        modelDto.id = model.id;
        modelDto.wording = model.wording;

        modelDto.category = model.category;

        modelDto.doors = model.doors;
        modelDto.seatings = model.seatings;
        modelDto.motorization = model.motorization;

        modelDto.counterKind = CounterKindDto.fromModel(model.counterKind);
        modelDto.counterKind2 = CounterKindDto.fromModel(model.counterKind2);

        return modelDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): PersonalModel {
        let model = new PersonalModel();

        model.id = this.id;
        model.wording = this.wording;

        model.doors = this.doors;
        model.seatings = this.seatings;
        model.motorization = this.motorization;

        model.category = this.category;

        if (this.counterKind) {
            model.counterKind = this.counterKind.toModel();
        }

        if (this.counterKind2) {
            model.counterKind2 = this.counterKind2.toModel();
        }

        return model;
    }
}
