import { Component, Input } from "@angular/core";
import { ModalController, ViewDidEnter, ViewWillEnter } from "@ionic/angular";
import { DeviceHelper } from "../../helpers/device.helper";
import { NavControllerExtended } from "../../helpers/navControllerExtended";
import { HammerJsPage } from "../../pages/hammerJs.page.base";

@Component({
    templateUrl: "view.modal.html",
    styleUrls: ["view.modal.scss"],
})
export class ViewModal extends HammerJsPage implements ViewWillEnter, ViewDidEnter {
    @Input() imageData: string = "";

    constructor(deviceHelper: DeviceHelper,
                private navCtrl: NavControllerExtended,
                private modalController: ModalController) {
        super(deviceHelper);
    }

    ionViewWillEnter() {
        if (this.navCtrl.params.hasParam("imageData")) {
            this.imageData = this.navCtrl.params.getParam("imageData") as string;
        }
    }

    ionViewDidEnter() {
        if (this.imageData.length > 0) {
            let image = new Image();
            image.src = this.imageData;
            image.onload = () => {
            };
        }

        this.initializeHammerJs();
    }

    public dismiss() {
        void this.modalController.dismiss();
    }
}
