import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Attachment } from "./attachment.model";
import { Equipment } from "./equipment.model";
import { Model } from "./model.model";
import { Service } from "./service.model";

export class OrderLinkedMovement extends ModelBase {
    static TABLENAME = "OrderLinkedMovements";

    id: string = "";
    ltcId: string = "";
    movementId: string = "";
    fatherMovementId: string = "";
    wording: string = "";
    quantity: number = 0;
    preparedQuantity: number = 0;
    equipmentNature: string = "";

    model: Model = null;
    service: Service = null;
    equipment: Equipment = null;

    attachments: Attachment[] = [];

    constructor() {
        super(OrderLinkedMovement.TABLENAME);

        this.model = new Model();
        this.service = new Service();
        this.equipment = new Equipment();
    }
}
