import { Injectable } from "@angular/core";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { NetworkHelper } from "../../gyzmo-commons/helpers/network.helper";
import { HttpClientProvider } from "../../gyzmo-commons/providers/httpClientProvider";
import { AppVersionService } from "../../gyzmo-commons/services/appVersion.service";
import { LanguageService } from "../../gyzmo-commons/services/language.service";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { ServerDto } from "../dto/server.dto";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";
import { OfflineModeService } from "../services/offlineMode.service";

export const CURRENT_SERVER_CONFIGURATION: string = "currentServerConfiguration";

@Injectable({
    providedIn: "root",
})
export class ServersConnectionsProvider {
    protected serversConnection: ServerConnection = null;

    constructor(private deviceHelper: DeviceHelper,
                private appVersionService: AppVersionService,
                private languageService: LanguageService,
                private networkHelper: NetworkHelper,
                private httpClientProvider: HttpClientProvider,
                private httpErrorHandler: HttpErrorHandler,
                private serverDbDao: ServerDbDao,
                private offlineModeService: OfflineModeService) {
    }

    public async setServerConnection(serverDto: ServerDto) {
        await this.serverDbDao.deleteAll();
        await this.serverDbDao.save(serverDto.toModel());
        await this.initialize();
    }

    public getServerConnection(): ServerConnection {
        return this.serversConnection;
    }

    public getPrimaryServerConnection(): ServerConnection {
        return this.getServerConnection();
    }

    public async initialize(): Promise<void> {
        let servers = await this.serverDbDao.list();

        if (servers.length > 0) {
            this.serversConnection = new ServerConnection(
                this.deviceHelper,
                this.languageService,
                this.appVersionService,
                this.networkHelper,
                ServerDto.fromModel(servers[0]),
                this.serverDbDao,
                this.httpClientProvider,
                this.httpErrorHandler,
                this.offlineModeService);
        }
    }
}
