import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { PersonalModel } from "./personalModel.model";

export class Model extends ModelBase {
    static TABLENAME = "Models";

    public id: string = "";
    public wording: string = "";

    public personalModel: PersonalModel = null;

    constructor() {
        super(Model.TABLENAME);

        this.personalModel = new PersonalModel();
    }
}
