import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { DeliveryLinkedMovement } from "../../models/deliveryLinkedMovement.model";
import { AddressDbDao } from "./address.db.dao";
import { ConstructionSiteDbDao } from "./constructionSite.db.dao";
import { EquipmentDbDao } from "./equipment.db.dao";
import { ModelDbDao } from "./model.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class DeliveryLinkedMovementDbDao extends DbDaoBase<DeliveryLinkedMovement> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private thirdPartyDbDao: ThirdPartyDbDao,
        private constructionSiteDbDao: ConstructionSiteDbDao,
        private modelDbDao: ModelDbDao,
        private equipmentDbDao: EquipmentDbDao,
        private addressDbDao: AddressDbDao) {
        super(logger);
    }

    public getByMovementId(fatherMovementId: string, hydrate: boolean): Promise<DeliveryLinkedMovement[]> {
        let selectQuery = "SELECT * FROM " + DeliveryLinkedMovement.TABLENAME + " WHERE fatherMovementId = '" + fatherMovementId + "' ORDER BY _order;";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let hydratationPromises = [];
                let linkedMovements: DeliveryLinkedMovement[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    let deliveryLinkedMovement: DeliveryLinkedMovement = this.rowToModel(data.rows[i]);

                    if (hydrate) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(deliveryLinkedMovement.prescriber.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.prescriber = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(deliveryLinkedMovement.customer.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.customer = value;
                            }));
                        hydratationPromises.push(this.constructionSiteDbDao.get(deliveryLinkedMovement.constructionSite.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.constructionSite = value;
                            }));
                        hydratationPromises.push(this.modelDbDao.get(deliveryLinkedMovement.model.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.model = value;
                            }));
                        hydratationPromises.push(this.equipmentDbDao.get(deliveryLinkedMovement.equipment.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.equipment = value;
                            }));
                        hydratationPromises.push(this.addressDbDao.get(deliveryLinkedMovement.deliveryAddress.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.deliveryAddress = value;
                            }));
                    }

                    linkedMovements.push(deliveryLinkedMovement);
                }

                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return linkedMovements;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + DeliveryLinkedMovement.TABLENAME + "_id"
                    + " ON " + DeliveryLinkedMovement.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + DeliveryLinkedMovement.TABLENAME + "_fatherMovementId"
                + " ON " + DeliveryLinkedMovement.TABLENAME + "(fatherMovementId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + DeliveryLinkedMovement.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "movementId TEXT, "
                    + "fatherMovementId TEXT, "
                    + "quantity NUMERIC, "
                    + "deliveredQuantity NUMERIC, "
                    + "isClosed NUMERIC, "
                    + "_order TEXT, "
                    + "startDate TEXT, "
                    + "plannedReturnDate TEXT, "
                    + "service TEXT, "
                    // FKs
                    + "prescriber TEXT, "
                    + "customer TEXT, "
                    + "constructionSite TEXT, "
                    + "model TEXT, "
                    + "equipment TEXT, "
                    + "deliveryAddress TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + DeliveryLinkedMovement.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + DeliveryLinkedMovement.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<DeliveryLinkedMovement> {
        let selectQuery = "SELECT * FROM " + DeliveryLinkedMovement.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let deliveryLinkedMovement: DeliveryLinkedMovement = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(deliveryLinkedMovement.prescriber.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.prescriber = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(deliveryLinkedMovement.customer.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.customer = value;
                            }));
                        hydratationPromises.push(this.constructionSiteDbDao.get(deliveryLinkedMovement.constructionSite.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.constructionSite = value;
                            }));
                        hydratationPromises.push(this.modelDbDao.get(deliveryLinkedMovement.model.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.model = value;
                            }));
                        hydratationPromises.push(this.equipmentDbDao.get(deliveryLinkedMovement.equipment.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.equipment = value;
                            }));
                        hydratationPromises.push(this.addressDbDao.get(deliveryLinkedMovement.deliveryAddress.id, hydrate)
                            .then(value => {
                                deliveryLinkedMovement.deliveryAddress = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return deliveryLinkedMovement;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return DeliveryLinkedMovement.TABLENAME;
    }

    protected rowToModel(row: any): DeliveryLinkedMovement {
        let deliveryLinkedMovement = new DeliveryLinkedMovement();

        deliveryLinkedMovement.id = row.id;
        deliveryLinkedMovement.wording = row.wording;
        deliveryLinkedMovement.movementId = row.movementId;
        deliveryLinkedMovement.fatherMovementId = row.fatherMovementId;
        deliveryLinkedMovement.quantity = row.quantity;
        deliveryLinkedMovement.deliveredQuantity = row.deliveredQuantity;
        deliveryLinkedMovement.isClosed = row.isClosed;
        deliveryLinkedMovement.order = row._order;
        deliveryLinkedMovement.startDate = row.startDate;
        deliveryLinkedMovement.plannedReturnDate = row.plannedReturnDate;

        deliveryLinkedMovement.service = JSON.parse(row.service);

        // FKs
        deliveryLinkedMovement.prescriber.id = row.prescriber;
        deliveryLinkedMovement.customer.id = row.customer;
        deliveryLinkedMovement.constructionSite.id = row.constructionSite;
        deliveryLinkedMovement.model.id = row.model;
        deliveryLinkedMovement.equipment.id = row.equipment;
        deliveryLinkedMovement.deliveryAddress.id = row.deliveryAddress;

        return deliveryLinkedMovement;
    }

    public save(deliveryLinkedMovement: DeliveryLinkedMovement): Promise<DeliveryLinkedMovement> {
        let promises = [];

        promises.push(this.thirdPartyDbDao.save(deliveryLinkedMovement.prescriber));
        promises.push(this.thirdPartyDbDao.save(deliveryLinkedMovement.customer));
        promises.push(this.constructionSiteDbDao.save(deliveryLinkedMovement.constructionSite));
        promises.push(this.modelDbDao.save(deliveryLinkedMovement.model));
        promises.push(this.equipmentDbDao.save(deliveryLinkedMovement.equipment));
        promises.push(this.addressDbDao.save(deliveryLinkedMovement.deliveryAddress));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + DeliveryLinkedMovement.TABLENAME + " ("
                            + "id, wording, movementId, fatherMovementId, quantity, deliveredQuantity, isClosed, _order, startDate, plannedReturnDate, service,"
                            + "prescriber, customer, constructionSite,  model, equipment, deliveryAddress"
                            + ") VALUES ("
                            + this.getValue(deliveryLinkedMovement.id)
                            + this.getValue(deliveryLinkedMovement.wording)
                            + this.getValue(deliveryLinkedMovement.movementId)
                            + this.getValue(deliveryLinkedMovement.fatherMovementId)
                            + this.getValue(deliveryLinkedMovement.quantity)
                            + this.getValue(deliveryLinkedMovement.deliveredQuantity)
                            + this.getValue(deliveryLinkedMovement.isClosed)
                            + this.getValue(deliveryLinkedMovement.order)
                            + this.getValue(deliveryLinkedMovement.startDate)
                            + this.getValue(deliveryLinkedMovement.plannedReturnDate)
                            + this.getValueAsJsonString(deliveryLinkedMovement.service)
                            // FKs
                            + this.getFkValue(deliveryLinkedMovement.prescriber)
                            + this.getFkValue(deliveryLinkedMovement.customer)
                            + this.getFkValue(deliveryLinkedMovement.constructionSite)
                            + this.getFkValue(deliveryLinkedMovement.model)
                            + this.getFkValue(deliveryLinkedMovement.equipment)
                            + this.getFkValue(deliveryLinkedMovement.deliveryAddress, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return deliveryLinkedMovement;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
