import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../helpers/null.helper";
import { HttpResponse } from "../../http/httpResponse";
import { HttpClientProvider } from "../../providers/httpClientProvider";
import { LoggerService } from "../logs/logger.service";
import { GeocoderForwardResult } from "./geocoderForwardResult";
import { GeocoderReverseResult } from "./geocoderReverseResult";

@Injectable({
    providedIn: "root",
})
export class OpenStreetMapGeocoding {
    constructor(private logger: LoggerService,
                private httpClientProvider: HttpClientProvider) {
    }

    public reverseGeocode(latitude: number, longitude: number): Promise<GeocoderReverseResult[]> {
        return new Promise<GeocoderReverseResult[]>((resolve, reject) => {
            let result: GeocoderReverseResult[] = [];

            this.httpClientProvider.getHttpClient().get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + latitude + "&lon=" + longitude + "&addressdetails=1", {})
                .then((response: HttpResponse) => {
                    if (!response.body) {
                        reject();
                        return;
                    }

                    let geocoderReverseResult: GeocoderReverseResult = new GeocoderReverseResult();

                    geocoderReverseResult.thoroughfare = "";
                    if (!isNullOrEmpty(response.body.address.house_number)) {
                        geocoderReverseResult.thoroughfare = response.body.address.house_number.trim();
                    }
                    geocoderReverseResult.thoroughfare += " ";
                    if (!isNullOrEmpty(response.body.address.road)) {
                        geocoderReverseResult.thoroughfare += response.body.address.road.trim();
                    }
                    geocoderReverseResult.thoroughfare = geocoderReverseResult.thoroughfare.trim();

                    geocoderReverseResult.subThoroughfare = "";

                    if (!isNullOrEmpty(response.body.address.postcode)) {
                        geocoderReverseResult.postalCode = response.body.address.postcode.trim();
                    }

                    if (!isNullOrEmpty(response.body.address.town)) {
                        geocoderReverseResult.locality = response.body.address.town.trim();
                    } else if (!isNullOrEmpty(response.body.address.village)) {
                        geocoderReverseResult.locality = response.body.address.village.trim();
                    } else if (!isNullOrEmpty(response.body.address.county)) {
                        geocoderReverseResult.locality = response.body.address.county.trim();
                    }

                    result.push(geocoderReverseResult);

                    resolve(result);
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    reject(reason);
                });
        });
    }

    public forwardGeocode(address: string): Promise<GeocoderForwardResult[]> {
        return new Promise<GeocoderForwardResult[]>((resolve, reject) => {
            let result: GeocoderForwardResult[] = [];

            this.httpClientProvider.getHttpClient().get("https://nominatim.openstreetmap.org/search?format=json&q=" + address, {})
                .then((response: HttpResponse) => {
                    if (!response.body) {
                        reject();
                        return;
                    }

                    response.body.forEach(entry => {
                        let geocoderForwardResult: GeocoderForwardResult = new GeocoderForwardResult();
                        geocoderForwardResult.description = entry.display_name.trim();
                        geocoderForwardResult.latitude = parseFloat("" + entry.lat);
                        geocoderForwardResult.longitude = parseFloat("" + entry.lon);

                        result.push(geocoderForwardResult);
                    });

                    resolve(result);
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    reject(reason);
                });
        });
    }
}
