import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class CounterKind {
    public id: string = "0";
    public wording: string = "";
    public maxFuel: number = 0;
}

export class PersonalModel extends ModelBase {
    static TABLENAME = "PersonalModels";

    public id: string = "";
    public wording: string = "";

    public category: any = {};

    public doors: number = 0;
    public seatings: number = 0;
    public motorization: string = "";

    public counterKind: CounterKind = null;
    public counterKind2: CounterKind = null;

    constructor() {
        super(PersonalModel.TABLENAME);

        this.counterKind = new CounterKind();
        this.counterKind2 = new CounterKind();
    }
}
