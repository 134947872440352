import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularHttpClient } from "../http/angularHttpClient";
import { NativeHttpClient } from "../http/nativeHttpClient";
import { IHttpClient } from "../interfaces/IHttpClient";

@Injectable({
    providedIn: "root",
})
export class HttpClientProvider {
    constructor(private httpClient: HttpClient,
                private angularHttpClient: AngularHttpClient,
                private nativeHttpClient: NativeHttpClient) {
    }

    public getAngularHttpClient(): HttpClient {
        return this.httpClient;
    }

    public getNativeHttpClient(): IHttpClient {
        return this.nativeHttpClient;
    }

    public getHttpClient(): IHttpClient {
        return this.angularHttpClient;
    }
}
