import { Injectable } from "@angular/core";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy/ngx";
import { Geolocation } from "@capacitor/geolocation";
import { DateTime } from "luxon";
import { DeviceHelper, PlatformName } from "../helpers/device.helper";
import { DateProvider } from "../interfaces/dateProvider";
import { LoggerService } from "./logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class GeolocationService {
    private lastCoordinates: { latitude: number, longitude: number } = null;
    private lastCoordinatesDate: DateTime = null;

    constructor(private deviceHelper: DeviceHelper,
                private logger: LoggerService,
                private dateProvider: DateProvider,
                private locationAccuracy: LocationAccuracy) {
    }

    public ensureGpsOn(): Promise<void> {
        return new Promise<void>(resolve => {
            if (this.deviceHelper.isRunningOnDevice()) {
                this.locationAccuracy.canRequest()
                    .then((canRequest: boolean) => {
                        if (canRequest) {
                            // the accuracy option will be ignored by iOS
                            this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
                                .then(() => {
                                    resolve();
                                })
                                .catch(reason => {
                                    this.logger.error(this.constructor.name, reason);
                                    resolve();
                                });
                        } else {
                            resolve();
                        }
                    })
                    .catch(reason => {
                        this.logger.error(this.constructor.name, reason);
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    }

    public getCurrentPosition(enableHighAccuracy = false): Promise<{ latitude: number, longitude: number } | null> {
        this.logger.info(this.constructor.name, "Acquiring gps coordinates");
        const TIMEOUT = 750;
        const MAX_AGE = 10000;

        return new Promise<{ latitude: number, longitude: number }>(async (resolve) => {
            if (this.lastCoordinatesDate != null && this.dateProvider.now().diff(this.lastCoordinatesDate, "milliseconds").milliseconds < MAX_AGE) {
                this.logger.info(this.constructor.name, "Returning cached coordinates");
                resolve(this.lastCoordinates);
            } else {
                await this.ensureGpsOn();

                if (this.deviceHelper.getPlatform() == PlatformName.ELECTRON) {
                    if (navigator.geolocation) {
                        return navigator.geolocation.getCurrentPosition(
                            (position: GeolocationPosition) => {
                                this.lastCoordinatesDate = this.dateProvider.now();
                                this.lastCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                                resolve(this.lastCoordinates);
                            },
                            (positionError: GeolocationPositionError) => {
                                this.logger.error(this.constructor.name, positionError.message);
                                resolve(null);
                            },
                            { maximumAge: MAX_AGE - 1, enableHighAccuracy: enableHighAccuracy, timeout: TIMEOUT });
                    } else {
                        this.logger.error(this.constructor.name, "Your browser does not support Geolocation!");
                        resolve(null);
                    }
                } else {
                    let fired = false;
                    setTimeout(() => {
                        if (!fired) {
                            this.logger.info(this.constructor.name, "Watchdog timeout");
                            fired = true;
                            resolve(null);
                        }
                    }, TIMEOUT + 1);

                    Geolocation.getCurrentPosition({ maximumAge: MAX_AGE - 1, enableHighAccuracy: enableHighAccuracy, timeout: TIMEOUT })
                        .then(position => {
                            if (!fired) {
                                this.logger.info(this.constructor.name, "Coordinates acquired");
                                fired = true;

                                this.lastCoordinatesDate = this.dateProvider.now();
                                this.lastCoordinates = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                                resolve(this.lastCoordinates);
                            }
                        })
                        .catch(reason => {
                            this.logger.error(this.constructor.name, reason);
                            resolve(null);
                        });
                }
            }
        });
    }
}
