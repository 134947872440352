import { Injectable } from "@angular/core";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";
import { HourTypeDbDao } from "../dao/db/hourType.db.dao";
import { HourTypeWsDao } from "../dao/ws/hourType.ws.dao";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class HourTypeService {
    constructor(private logger: LoggerService,
                private hourTypeWsDao: HourTypeWsDao,
                private hourTypeDbDao: HourTypeDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getDbList(kindFilter: string) {
        return this.hourTypeDbDao.list(kindFilter);
    }

    public synchronize(): Promise<void> {
        return this.hourTypeWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(hourTypeDtos => {
                let promises = [];

                hourTypeDtos.forEach(hourTypeDto => {
                    promises.push(this.hourTypeDbDao.save(hourTypeDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }

    public async checkData(): Promise<boolean> {
        let correctness = true;
        let list = await this.hourTypeDbDao.getList(true);

        if (list == null || list.length <= 0) {
            correctness = false;
            this.logger.error(this.constructor.name, "No hour types !");
        }

        return correctness;
    }
}
