import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { DeliveryLinkedMovement } from "../models/deliveryLinkedMovement.model";
import { AddressDto } from "./address.dto";
import { BaseDto } from "./base/base.dto";
import { ConstructionSiteDto } from "./constructionSite.dto";
import { EquipmentDto } from "./equipment.dto";
import { ModelDto } from "./model.dto";
import { ServiceDto } from "./service.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class DeliveryLinkedMovementDto extends BaseDto<DeliveryLinkedMovement> {
    id: string = "";
    wording: string = "";
    movementId: string = "";
    fatherMovementId: string = "";
    quantity: number = 0;
    deliveredQuantity: number = null;
    order: string = "";
    isClosed: boolean = false;
    startDate: DateTime = null;
    plannedReturnDate: DateTime = null;

    // Fks
    prescriber: ThirdPartyDto;
    customer: ThirdPartyDto;
    constructionSite: ConstructionSiteDto = null;

    model: ModelDto = null;

    service: ServiceDto = null;

    equipment: EquipmentDto = null;

    deliveryAddress: AddressDto = null;

    constructor() {
        super();

        this.prescriber = new ThirdPartyDto();
        this.customer = new ThirdPartyDto();
        this.constructionSite = new ConstructionSiteDto();
        this.model = new ModelDto();
        this.service = new ServiceDto();
        this.equipment = new EquipmentDto();
        this.deliveryAddress = new AddressDto();
    }

    static fromDeliveryZoom(value: any) {
        let linkedMovementDto = new DeliveryLinkedMovementDto();

        linkedMovementDto.id = value.field0;
        linkedMovementDto.quantity = value.field1;
        linkedMovementDto.deliveredQuantity = value.field2;
        linkedMovementDto.isClosed = (value.field3 == "1");
        if (!linkedMovementDto.isClosed) {
            if (linkedMovementDto.quantity == linkedMovementDto.deliveredQuantity) {
                linkedMovementDto.deliveredQuantity = 0;
            }
        }

        linkedMovementDto.order = value.field4;

        linkedMovementDto.service.id = value.field5;

        linkedMovementDto.fatherMovementId = value.field6;
        linkedMovementDto.movementId = value.field7;

        linkedMovementDto.model.id = value.field8;

        linkedMovementDto.equipment.id = value.field9;

        linkedMovementDto.startDate = DateHelper.tryFromFormat(value.field10, DATE_NODEJS_FORMAT);
        linkedMovementDto.plannedReturnDate = DateHelper.tryFromFormat(value.field11, DATE_NODEJS_FORMAT);

        linkedMovementDto.customer.id = value.field12;
        linkedMovementDto.prescriber.id = value.field13;
        linkedMovementDto.constructionSite.id = value.field14;

        linkedMovementDto.deliveryAddress.id = value.field16;

        if (value.field17) {
            // Model "Hors catalogue"
            linkedMovementDto.wording = value.field17;
        }

        return linkedMovementDto;
    }

    static fromModel(linkedMovement: DeliveryLinkedMovement): DeliveryLinkedMovementDto {
        let linkedMovementDto = new DeliveryLinkedMovementDto();

        linkedMovementDto.id = linkedMovement.id;
        linkedMovementDto.wording = linkedMovement.wording;
        linkedMovementDto.quantity = linkedMovement.quantity;
        linkedMovementDto.deliveredQuantity = linkedMovement.deliveredQuantity;
        linkedMovementDto.order = linkedMovement.order;
        linkedMovementDto.isClosed = (linkedMovement.isClosed == 1);

        linkedMovementDto.fatherMovementId = linkedMovement.fatherMovementId;
        linkedMovementDto.movementId = linkedMovement.movementId;

        linkedMovementDto.startDate = DateHelper.tryFromISO(linkedMovement.startDate);
        linkedMovementDto.plannedReturnDate = DateHelper.tryFromISO(linkedMovement.plannedReturnDate);

        if (linkedMovement.service) {
            linkedMovementDto.service = ServiceDto.fromModel(linkedMovement.service);
        }
        if (linkedMovement.model) {
            linkedMovementDto.model = ModelDto.fromModel(linkedMovement.model);
        }
        if (linkedMovement.equipment) {
            linkedMovementDto.equipment = EquipmentDto.fromModel(linkedMovement.equipment);
        }

        if (linkedMovement.customer) {
            linkedMovementDto.customer = ThirdPartyDto.fromModel(linkedMovement.customer);
        }
        if (linkedMovement.prescriber) {
            linkedMovementDto.prescriber = ThirdPartyDto.fromModel(linkedMovement.prescriber);
        }
        if (linkedMovement.constructionSite) {
            linkedMovementDto.constructionSite = ConstructionSiteDto.fromModel(linkedMovement.constructionSite);
        }

        if (linkedMovement.deliveryAddress) {
            linkedMovementDto.deliveryAddress = AddressDto.fromModel(linkedMovement.deliveryAddress);
        }

        return linkedMovementDto;
    }

    public static sorter(a: DeliveryLinkedMovementDto, b: DeliveryLinkedMovementDto) {
        return ("" + a.order).localeCompare("" + b.order);
    }

    public equals(linkedMovementDto: DeliveryLinkedMovementDto) {
        return this.id == linkedMovementDto.id
               && this.movementId == linkedMovementDto.movementId
               && this.fatherMovementId == linkedMovementDto.fatherMovementId
               && this.quantity == linkedMovementDto.quantity
               && this.model.id == linkedMovementDto.model.id;
    }

    isDelivered() {
        return this.isClosed;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.plannedReturnDate = DateHelper.tryToFormat(this.plannedReturnDate, DATE_NODEJS_FORMAT);

        clone.prescriber = this.prescriber.toBody();
        clone.customer = this.customer.toBody();
        clone.constructionSite = this.constructionSite.toBody();
        clone.model = this.model.toBody();
        clone.service = this.service.toBody();
        clone.equipment = this.equipment.toBody();
        clone.deliveryAddress = this.deliveryAddress.toBody();

        clone["father"] = { id: this.fatherMovementId };
        clone["quantityCompleted"] = this.deliveredQuantity;
        clone["isCompleted"] = this.isClosed;
        clone["isPrepared"] = this.isClosed;
        clone["isActive"] = true;
        //   clone['movementLink'] =  {id: this.id};
        clone["movementLink"] = { id: this.service.id };

        delete clone.deliveredQuantity;
        delete clone.fatherMovementId;
        delete clone.service;
        delete clone.model;
        delete clone.equipment;

        return clone;
    }

    toModel() {
        let linkedMovement = new DeliveryLinkedMovement();

        linkedMovement.id = this.id;
        linkedMovement.wording = this.wording;
        linkedMovement.quantity = this.quantity;
        linkedMovement.deliveredQuantity = this.deliveredQuantity;
        linkedMovement.order = this.order;
        linkedMovement.isClosed = this.isClosed ? 1 : 0;

        linkedMovement.fatherMovementId = this.fatherMovementId;
        linkedMovement.movementId = this.movementId;

        linkedMovement.startDate = DateHelper.tryToISO(this.startDate);
        linkedMovement.plannedReturnDate = DateHelper.tryToISO(this.plannedReturnDate);

        if (this.service) {
            linkedMovement.service = this.service.toModel();
        }
        if (this.model) {
            linkedMovement.model = this.model.toModel();
        }
        if (this.equipment) {
            linkedMovement.equipment = this.equipment.toModel();
        }

        if (this.customer) {
            linkedMovement.customer = this.customer.toModel();
        }
        if (this.prescriber) {
            linkedMovement.prescriber = this.prescriber.toModel();
        }
        if (this.constructionSite) {
            linkedMovement.constructionSite = this.constructionSite.toModel();
        }

        if (this.deliveryAddress) {
            linkedMovement.deliveryAddress = this.deliveryAddress.toModel();
        }

        return linkedMovement;
    }
}
