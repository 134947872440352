export const MOCK_VERSION_KEY: string = "mockVersion";

export class MockVersionDto {
    id: string = "";
    name: string = "";

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

