import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class CheckStatus extends ModelBase {
    static TABLENAME = "CheckStatuses";

    public id: string = "";
    public wording: string = "";
    public actionType: string = "";
    public equipmentNature: string = "";
    public order: string = "";

    constructor() {
        super(CheckStatus.TABLENAME);
    }
}
