import { NgModule } from "@angular/core";
import { AddressWsDao } from "./address.ws";
import { AttachmentWsDao } from "./attachment.ws.dao";
import { CheckStatusWsDao } from "./checkStatus.ws.dao";
import { CompanyWsDao } from "./company.ws.dao";
import { ConstraintWsDao } from "./constraint.ws.dao";
import { ConstructionSiteWsDao } from "./constructionSite.ws.dao";
import { ContactDetailsWsDao } from "./contactDetails.ws.dao";
import { DeliveryWsDao } from "./delivery.ws.dao";
import { ExpenseWsDao } from "./expense.ws.dao";
import { HourTypeWsDao } from "./hourType.ws.dao";
import { LinkedMovementWsDao } from "./linkedMovement.ws.dao";
import { ModelWsDao } from "./model.ws.dao";
import { OfflineModeWsDao } from "./offlineMode.ws.dao";
import { OrderWsDao } from "./order.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { TimeWsDao } from "./timeWsDao";
import { UserWsDao } from "./user.ws.dao";
import { ZoomWsDao } from "./zoom.ws.dao";

@NgModule({
    providers: [
        CompanyWsDao,
        AttachmentWsDao,
        ContactDetailsWsDao,
        ThirdPartyWsDao,
        UserWsDao,
        OfflineModeWsDao,
        ConstructionSiteWsDao,
        ZoomWsDao,
        ConstraintWsDao,
        CheckStatusWsDao,
        ModelWsDao,
        OrderWsDao,
        DeliveryWsDao,
        AddressWsDao,
        LinkedMovementWsDao,
        HourTypeWsDao,
        ExpenseWsDao,
        TimeWsDao,
    ],
})
export class WsModule {
}
