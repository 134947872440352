import { Invoice } from "../models/invoice.model";
import { AddressDto } from "./address.dto";
import { BaseDto } from "./base/base.dto";
import { ConstructionSiteDto } from "./constructionSite.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class InvoiceDto extends BaseDto<Invoice> {
    id: string = "";
    wording: string = "";
    documentNumber: string = "";
    groupingEvent: string = "";
    groupingMovement: string = "";
    isTruckDelivery: boolean = false;
    // Fks
    prescriber: ThirdPartyDto;
    thirdParty: ThirdPartyDto;
    constructionSite: ConstructionSiteDto;
    orderPicker: ThirdPartyDto;
    mainAddress: AddressDto;

    constructor() {
        super();

        this.prescriber = new ThirdPartyDto();
        this.thirdParty = new ThirdPartyDto();
        this.constructionSite = new ConstructionSiteDto();
        this.orderPicker = new ThirdPartyDto();

        this.mainAddress = new AddressDto();
    }

    public static fromBody(body: any): InvoiceDto {
        let invoiceDto = new InvoiceDto();

        invoiceDto.id = body.id;
        invoiceDto.wording = body.wording;
        invoiceDto.documentNumber = body.documentNumber;
        invoiceDto.groupingEvent = body.groupingEvent;
        invoiceDto.groupingMovement = body.groupingMovement;
        invoiceDto.isTruckDelivery = body.isTruckDelivery == "1";

        if (body.thirdParty) {
            invoiceDto.thirdParty = ThirdPartyDto.fromBody(body.thirdParty);
        }

        if (body.prescriber) {
            invoiceDto.prescriber = ThirdPartyDto.fromBody(body.prescriber);
        }

        if (body.constructionSite) {
            invoiceDto.constructionSite = ConstructionSiteDto.fromBody(body.constructionSite);
        }

        if (body.orderPicker) {
            invoiceDto.orderPicker.id = body.orderPicker;
        }

        if (body.mainAddress) {
            invoiceDto.mainAddress = AddressDto.fromBody(body.mainAddress);
        }

        return invoiceDto;
    }

    static fromModel(invoice: Invoice): InvoiceDto {
        let invoiceDto = new InvoiceDto();

        invoiceDto.id = invoice.id;
        invoiceDto.wording = invoice.wording;
        invoiceDto.documentNumber = invoice.documentNumber;
        invoiceDto.groupingEvent = invoice.groupingEvent;
        invoiceDto.groupingMovement = invoice.groupingMovement;
        invoiceDto.isTruckDelivery = invoice.isTruckDelivery == 1;

        if (invoice.thirdParty) {
            invoiceDto.thirdParty = ThirdPartyDto.fromModel(invoice.thirdParty);
        }

        if (invoice.prescriber) {
            invoiceDto.prescriber = ThirdPartyDto.fromModel(invoice.prescriber);
        }

        if (invoice.constructionSite) {
            invoiceDto.constructionSite = ConstructionSiteDto.fromModel(invoice.constructionSite);
        }

        if (invoice.orderPicker) {
            invoiceDto.orderPicker = ThirdPartyDto.fromModel(invoice.orderPicker);
        }

        if (invoice.mainAddress) {
            invoiceDto.mainAddress = AddressDto.fromModel(invoice.mainAddress);
        }

        return invoiceDto;
    }

    public toBody() {
        const clone: any = { ...this };

        clone.prescriber = this.prescriber.toBody();
        clone.thirdParty = this.thirdParty.toBody();
        clone.constructionSite = this.constructionSite.toBody();
        clone.orderPicker = this.orderPicker.toBody();
        clone.mainAddress = this.mainAddress.toBody();

        return clone;
    }

    toModel() {
        let invoice = new Invoice();

        invoice.id = this.id;
        invoice.wording = this.wording;
        invoice.documentNumber = this.documentNumber;
        invoice.groupingEvent = this.groupingEvent;
        invoice.groupingMovement = this.groupingMovement;
        invoice.isTruckDelivery = this.isTruckDelivery ? 1 : 0;

        if (this.thirdParty) {
            invoice.thirdParty = this.thirdParty.toModel();
        }

        if (this.prescriber) {
            invoice.prescriber = this.prescriber.toModel();
        }

        if (this.constructionSite) {
            invoice.constructionSite = this.constructionSite.toModel();
        }

        if (this.orderPicker) {
            invoice.orderPicker = this.orderPicker.toModel();
        }

        if (this.mainAddress) {
            invoice.mainAddress = this.mainAddress.toModel();
        }

        return invoice;
    }
}
