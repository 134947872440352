import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Order } from "../../models/order.model";
import { InvoiceDbDao } from "./invoice.db.dao";
import { LocationDbDao } from "./location.db.dao";
import { MovementDbDao } from "./movement.db.dao";
import { OrderLinkedMovementDbDao } from "./orderLinkedMovement.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class OrderDbDao extends DbDaoBase<Order> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private movementDbDao: MovementDbDao,
        private invoiceDbDao: InvoiceDbDao,
        private thirdPartyDbDao: ThirdPartyDbDao,
        private locationDbDao: LocationDbDao,
        private linkedMovementDbDao: OrderLinkedMovementDbDao) {
        super(logger);
    }

    public getList(startDate: DateTime, endDate: DateTime,
                   hydrateMovement: boolean = false,
                   hydrateObjects: boolean = false): Promise<Order[]> {
        let selectQuery = "SELECT * FROM " + Order.TABLENAME;

        if (startDate && endDate) {
            selectQuery += " WHERE startDate >= '" + startDate.toFormat(DATE_NODEJS_FORMAT) + "' AND startDate <= '" + endDate.toFormat(DATE_NODEJS_FORMAT) + "'";
        }
        selectQuery += ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let orders: Order[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    orders.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                orders.forEach(order => {
                    hydratationPromises.push(this.invoiceDbDao.get(order.invoice.id, hydrateObjects)
                        .then(value => {
                            order.invoice = value;
                        }));

                    if (hydrateMovement) {
                        hydratationPromises.push(this.movementDbDao.get(order.movement.id, hydrateObjects)
                            .then(value => {
                                order.movement = value;
                            }));

                        hydratationPromises.push(this.linkedMovementDbDao.getByLtcId(order.id, hydrateObjects)
                            .then(value => {
                                order.linkedMovements = value;
                            }));

                        hydratationPromises.push(this.thirdPartyDbDao.get(order.customer.id, hydrateObjects)
                            .then(value => {
                                order.customer = value;
                            }));
                    }

                    if (hydrateObjects) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(order.driver.id, hydrateObjects)
                            .then(value => {
                                order.driver = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(order.merchant.id, hydrateObjects)
                            .then(value => {
                                order.merchant = value;
                            }));

                        hydratationPromises.push(this.locationDbDao.get(order.startLocation.id, hydrateObjects)
                            .then(value => {
                                order.startLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(order.plannedReturnLocation.id, hydrateObjects)
                            .then(value => {
                                order.plannedReturnLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(order.returnLocation.id, hydrateObjects)
                            .then(value => {
                                order.returnLocation = value;
                            }));
                    }
                });

                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return orders;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Order.TABLENAME + "_id"
                    + " ON " + Order.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Order.TABLENAME + "_startDate"
                + " ON " + Order.TABLENAME + "(startDate);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Order.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT,"
                    + "startDate TEXT,"
                    + "orderDate TEXT,"
                    + "plannedReturnDate TEXT,"
                    + "returnDate TEXT,"
                    + "wantedDays NUMERIC,"
                    + "duration NUMERIC,"
                    + "cumulativeMileage NUMERIC,"
                    + "includedMileage NUMERIC,"
                    + "contractNumber TEXT,"
                    + "bookingCode TEXT,"
                    + "status TEXT,"
                    + "isClosed NUMERIC,"
                    // Fks
                    + "customer TEXT,"
                    + "driver TEXT,"
                    + "merchant TEXT,"
                    + "movement TEXT,"
                    + "invoice TEXT,"
                    + "startLocation TEXT,"
                    + "plannedReturnLocation TEXT,"
                    + "returnLocation TEXT,"
                    + "server NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Order.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Order.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Order> {
        let selectQuery = "SELECT * FROM " + Order.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let order: Order = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    if (hydrate) {
                        hydratationPromises.push(this.movementDbDao.get(order.movement.id, hydrate)
                            .then(value => {
                                order.movement = value;
                            }));

                        hydratationPromises.push(this.invoiceDbDao.get(order.invoice.id, hydrate)
                            .then(value => {
                                order.invoice = value;
                            }));

                        hydratationPromises.push(this.thirdPartyDbDao.get(order.customer.id, hydrate)
                            .then(value => {
                                order.customer = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(order.driver.id, hydrate)
                            .then(value => {
                                order.driver = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(order.merchant.id, hydrate)
                            .then(value => {
                                order.merchant = value;
                            }));

                        hydratationPromises.push(this.locationDbDao.get(order.startLocation.id, hydrate)
                            .then(value => {
                                order.startLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(order.plannedReturnLocation.id, hydrate)
                            .then(value => {
                                order.plannedReturnLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(order.returnLocation.id, hydrate)
                            .then(value => {
                                order.returnLocation = value;
                            }));

                        hydratationPromises.push(this.linkedMovementDbDao.getByLtcId(order.id, hydrate)
                            .then(value => {
                                order.linkedMovements = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return order;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Order.TABLENAME;
    }

    protected rowToModel(row: any): Order {
        let order = new Order();

        order.id = row.id;
        order.wording = row.wording;
        order.startDate = row.startDate;
        order.orderDate = row.orderDate;
        order.plannedReturnDate = row.plannedReturnDate;
        order.returnDate = row.returnDate;
        order.wantedDays = Number(row.wantedDays);
        order.duration = row.duration;
        order.cumulativeMileage = Number(row.cumulativeMileage);
        order.includedMileage = Number(row.includedMileage);
        order.contractNumber = row.contractNumber;
        order.bookingCode = row.bookingCode;

        order.status = row.status;
        order.isClosed = row.isClosed;

        // Fks
        order.customer.id = row.customer;
        order.driver.id = row.driver;
        order.merchant.id = row.merchant;
        order.movement.id = row.movement;
        order.invoice.id = row.invoice;

        order.startLocation.id = row.startLocation;
        order.plannedReturnLocation.id = row.plannedReturnLocation;
        order.returnLocation.id = row.returnLocation;

        return order;
    }

    public save(order: Order): Promise<Order> {
        let promises = [];
        promises.push(this.movementDbDao.save(order.movement));

        promises.push(this.thirdPartyDbDao.save(order.customer));
        promises.push(this.thirdPartyDbDao.save(order.driver));
        promises.push(this.thirdPartyDbDao.save(order.merchant));

        promises.push(this.locationDbDao.save(order.startLocation));
        promises.push(this.locationDbDao.save(order.plannedReturnLocation));
        promises.push(this.locationDbDao.save(order.returnLocation));

        promises.push(this.invoiceDbDao.save(order.invoice));

        order.linkedMovements.forEach(linkedMovement => {
            promises.push(this.linkedMovementDbDao.save(linkedMovement));
        });

        return Promise.all(promises)
            .then(values => {
                let query = "INSERT OR REPLACE INTO " + Order.TABLENAME + " ("
                            + "id, wording, startDate, orderDate, plannedReturnDate, returnDate,"
                            + "wantedDays, duration, cumulativeMileage, includedMileage, "
                            + "contractNumber, bookingCode, status, isClosed, "
                            // Fks
                            + "customer, driver, merchant, movement, invoice, "
                            + "startLocation, plannedReturnLocation, returnLocation"
                            + ") VALUES ("
                            + this.getValue(order.id)
                            + this.getValue(order.wording)
                            + this.getValue(order.startDate)
                            + this.getValue(order.orderDate)
                            + this.getValue(order.plannedReturnDate)
                            + this.getValue(order.returnDate)
                            + this.getValue(order.wantedDays)
                            + this.getValue(order.duration)
                            + this.getValue(order.cumulativeMileage)
                            + this.getValue(order.includedMileage)
                            + this.getValue(order.contractNumber)
                            + this.getValue(order.bookingCode)
                            + this.getValue(order.status)
                            + this.getValue(order.isClosed)
                            // Fks
                            + this.getFkValue(order.customer)
                            + this.getFkValue(order.driver)
                            + this.getFkValue(order.merchant)
                            + this.getFkValue(order.movement)
                            + this.getFkValue(order.invoice)
                            + this.getFkValue(order.startLocation)
                            + this.getFkValue(order.plannedReturnLocation)
                            + this.getFkValue(order.returnLocation, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return order;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
