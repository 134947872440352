import { LogLevel } from "../gyzmo-commons/interfaces/logLevel";
import { EnvironmentSpec } from "./environment.model.specif";

export const environment: EnvironmentSpec = {
    production: false,
    customer: "Gyzmo",
    defaultLogin: "00201",
    defaultPassword: "ADM00201",
    mockCamera: false,
    mocked: true,
    servers: [
        {
            name: "Qualif",
            url: "https://api.gyzmo-technology.net/api-locpro-easy",
            apiKey: "78093053-f54c-44bb-b00e-2e3f942c6b46",
            defaultToken: "",
            token: "",
            context: "Master_Qualif",
        },
    ],
    logging: {
        level: LogLevel.DEBUG,
        consoleLogger: { active: true },
        fileLogger: { active: false },
    },
};
