import { EventEmitter, Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { environment } from "../../environments/environment";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";
import { OrderDbDao } from "../dao/db/order.db.dao";
import { OrderWsDao } from "../dao/ws/order.ws.dao";
import { OrderDto } from "../dto/order.dto";
import { OrderLinkedMovementDto } from "../dto/orderLinkedMovement.dto";
import { ServerConnection } from "../http/serverConnection";
import { LtcStatuses, LtcStatusesHelper } from "../interfaces/ltcStatuses";
import { Order } from "../models/order.model";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class OrderService {
    private inspectionsMockCache = new Map<string, OrderDto>();
    private synchronisationFinishedSubject = new EventEmitter<{ day: DateTime, orders: OrderDto[] }>();

    constructor(private logger: LoggerService,
                private orderWsDao: OrderWsDao,
                private orderDbDao: OrderDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getSynchronisationFinishedObservable(): EventEmitter<{ day: DateTime, orders: OrderDto[] }> {
        return this.synchronisationFinishedSubject;
    }

    public publishSynchronisationFinished(data: { day: DateTime, orders: OrderDto[] }) {
        this.synchronisationFinishedSubject.emit(data);
    }

    public getLinkedMovements(orderDto: OrderDto): Promise<OrderLinkedMovementDto[]> {
        return this.orderWsDao.getLinkedMovements(this.serversConnectionsProvider.getPrimaryServerConnection(), orderDto);
    }

    public save(orderDto: OrderDto) {
        if (environment.mocked) {
            switch (orderDto.status) {
                case LtcStatuses.RESERVATION:
                    orderDto.status = LtcStatuses.BEING_PREPARED;
                    this.resetMockedStates(orderDto);
                    break;
                case LtcStatuses.BEING_PREPARED:
                    orderDto.status = LtcStatuses.CONTRACT;
                    this.resetMockedStates(orderDto);
                    break;
                case LtcStatuses.CONTRACT:
                    orderDto.status = LtcStatuses.READY;
                    this.resetMockedStates(orderDto);
                    break;
            }

            this.inspectionsMockCache.set(orderDto.id, orderDto);
        }

        return new Promise<void>((resolve, reject) => {
            this.orderWsDao.save(this.serversConnectionsProvider.getPrimaryServerConnection(), orderDto)
                .then(value => {
                    this.orderDbDao.save(orderDto.toModel())
                        .then(ignore => {
                            resolve();
                        });
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    reject(reason);
                });
        });
    }

    public getCountBetweenDates(startDate: DateTime, endDate: DateTime, orderPickerIdFilter?: string): Promise<number[][]> {
        return new Promise<number[][]>(async (resolve) => {
            let allLtcs = await this.getListWithOfflineFallback(startDate, endDate);

            let result: number[][] = [];
            let activeDay = startDate;
            while (activeDay <= endDate) {
                let counts: number[] = [
                    allLtcs.filter((ltcDto: OrderDto) => {
                        let passFilter = ltcDto.isOnDay(activeDay) && LtcStatusesHelper.isReserved(ltcDto.status);
                        if (!isNullOrEmpty(orderPickerIdFilter)) {
                            if (ltcDto.invoice.orderPicker.id == orderPickerIdFilter) {
                                return passFilter;
                            } else {
                                return false;
                            }
                        }
                        return passFilter;
                    }).length,
                    allLtcs.filter((ltcDto: OrderDto) => {
                        let passFilter = ltcDto.isOnDay(activeDay) && LtcStatusesHelper.isBeingPrepared(ltcDto.status);
                        if (!isNullOrEmpty(orderPickerIdFilter)) {
                            if (ltcDto.invoice.orderPicker.id == orderPickerIdFilter) {
                                return passFilter;
                            } else {
                                return false;
                            }
                        }
                        return passFilter;
                    }).length,
                    allLtcs.filter((ltcDto: OrderDto) => {
                        let passFilter = ltcDto.isOnDay(activeDay) && LtcStatusesHelper.isReady(ltcDto.status);
                        if (!isNullOrEmpty(orderPickerIdFilter)) {
                            if (ltcDto.invoice.orderPicker.id == orderPickerIdFilter) {
                                return passFilter;
                            } else {
                                return false;
                            }
                        }
                        return passFilter;
                    }).length,
                ];

                result.push(counts);

                activeDay = activeDay.plus({ days: 1 });
            }

            resolve(result);
        });
    }

    public async getListByDate(date: DateTime, orderPickerIdFilter?: string): Promise<OrderDto[]> {
        try {
            return await this.synchronize(date, orderPickerIdFilter);
        } catch {
            return await this.getOfflineInspectionList(date, date, true, false);
        }
    }

    public getByIdOffline(id: string): Promise<OrderDto> {
        return this.orderDbDao.get(id, true)
            .then(async order => {
                return OrderDto.fromModel(order);
            });
    }

    public getByIdOnline(serverConnection: ServerConnection, id: string): Promise<OrderDto> {
        return this.orderWsDao.getById(serverConnection, id);
    }

    public clearMockCache() {
        this.inspectionsMockCache = new Map<string, OrderDto>();
    }

    private async getListWithOfflineFallback(startDate: DateTime, endDate: DateTime): Promise<OrderDto[]> {
        try {
            return await this.getOnlineInspectionList(startDate, endDate);
        } catch {
            return await this.getOfflineInspectionList(startDate, endDate, true, false);
        }
    }

    private getOfflineInspectionList(startDate: DateTime,
                                     endDate: DateTime,
                                     hydrateMovement: boolean,
                                     hydrateObjects: boolean): Promise<OrderDto[]> {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");

        return this.orderDbDao.getList(startDate, endDate, hydrateMovement, hydrateObjects)
            .then(inspections => {
                let result = [];

                inspections.forEach(inspection => {
                    let inspectionDto = OrderDto.fromModel(inspection);
                    inspectionDto = this.updateMockCache(inspectionDto);
                    result.push(inspectionDto);
                });

                return result;
            });
    }

    private getOfflineListByDate(date: DateTime,
                                 hydrateMovement: boolean,
                                 hydrateObjects: boolean,
                                 orderPickerIdFilter?: string): Promise<OrderDto[]> {
        const startDate = date.startOf("day");
        const endDate = date.endOf("day");

        return this.orderDbDao.getList(startDate, endDate, hydrateMovement, hydrateObjects)
            .then(orders => {
                let result = [];

                orders.forEach(order => {
                    if (!isNullOrEmpty(orderPickerIdFilter)) {
                        if (order.invoice.orderPicker.id == orderPickerIdFilter) {
                            result.push(OrderDto.fromModel(order));
                        }
                    } else {
                        result.push(OrderDto.fromModel(order));
                    }
                });

                return result;
            });
    }

    private async getOnlineInspectionList(startDate: DateTime,
                                          endDate: DateTime): Promise<OrderDto[]> {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");

        let serverConnection = this.serversConnectionsProvider.getPrimaryServerConnection();

        let onlineResults = await this.orderWsDao.getList(serverConnection, startDate, endDate);
        for (let i = 0; i < onlineResults.length; i++) {
            let inspectionDto = onlineResults[i];
            onlineResults[i] = this.updateMockCache(inspectionDto);
        }

        return onlineResults;
    }

    private isLtcSame(localLtc: Order, remoteLtc: OrderDto) {
        return localLtc.id == remoteLtc.id && localLtc.status == remoteLtc.status;
    }

    private resetMockedStates(inspection: OrderDto) {
    }

    private synchronize(date: DateTime, orderPickerIdFilter?: string): Promise<OrderDto[]> {
        return new Promise<OrderDto[]>((resolve, reject) => {
            let promises = [];
            let remoteLtcs: OrderDto[] = [];
            let localLtcs: Order[] = [];

            const startDate = date.startOf("day");
            const endDate = date.endOf("day");

            promises.push(this.orderWsDao.getList(this.serversConnectionsProvider.getServerConnection(), startDate, endDate, true)
                .then(value => {
                    remoteLtcs = remoteLtcs.concat(value);
                }));
            promises.push(this.orderDbDao.getList(startDate, endDate, false, false)
                .then(value => {
                    localLtcs = value;
                }));

            Promise.all(promises)
                .then(ignored => {
                    let synchronizedBaseLtcs = [];
                    let synchronisationPromises = [];
                    let getDetailsPromises = [];

                    remoteLtcs.forEach(remoteLtc => {
                        let localLtc = localLtcs.find(localLtc => {
                            return this.isLtcSame(localLtc, remoteLtc);
                        });

                        if (!localLtc) {
                            synchronisationPromises.push(this.orderDbDao.save(remoteLtc.toModel()));

                            getDetailsPromises.push(this.getByIdOnline(this.serversConnectionsProvider.getPrimaryServerConnection(), remoteLtc.id)
                                .then(value => {
                                    let order = value.toModel();
                                    return this.orderDbDao.save(order);
                                }));
                        }

                        // Rapid response list
                        if (!isNullOrEmpty(orderPickerIdFilter)) {
                            if (remoteLtc.invoice.orderPicker.id == orderPickerIdFilter) {
                                synchronizedBaseLtcs.push(remoteLtc);
                            }
                        } else {
                            synchronizedBaseLtcs.push(remoteLtc);
                        }
                    });

                    localLtcs.forEach(localLtc => {
                        let remoteLtc = remoteLtcs.find(remoteLtc => {
                            return this.isLtcSame(localLtc, remoteLtc);
                        });

                        if (!remoteLtc) {
                            synchronisationPromises.push(this.orderDbDao.delete(localLtc.id));
                        }
                    });

                    Promise.all(synchronisationPromises)
                        .then((ignored) => {
                            Promise.all(getDetailsPromises)
                                .then(value => {
                                    this.getOfflineListByDate(date, true, false, orderPickerIdFilter)
                                        .then(orders => {
                                            this.publishSynchronisationFinished({
                                                day: date,
                                                orders: orders,
                                            });
                                        });
                                });

                            resolve(synchronizedBaseLtcs);
                        });

                })
                .catch(reason => {
                        reject(reason);
                    },
                );
        });
    }

    private updateMockCache(inspection: OrderDto): OrderDto {
        if (!environment.mocked) {
            return inspection;
        }

        if (this.inspectionsMockCache.has(inspection.id)) {
            return this.inspectionsMockCache.get(inspection.id);
        }

        return inspection;
    }
}
