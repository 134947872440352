import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../helpers/null.helper";
import { Version } from "../../models/version.model";
import { AppSqlProvider } from "../../persistence/app.sql.provider";
import { LoggerService } from "../../services/logs/logger.service";
import { DbDaoBase } from "./base/db.dao.base";

@Injectable({
    providedIn: "root",
})
export class VersionDbDao extends DbDaoBase<Version> {

    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Version.TABLENAME + "_id"
                    + " ON " + Version.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        const query = "CREATE TABLE IF NOT EXISTS " + Version.TABLENAME
                      + " ("
                      + "id NUMERIC PRIMARY KEY,"
                      + "version TEXT"
                      + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Version.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Version.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Version> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Version.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        throw new Error();
                    }

                    return this.rowToModel(data.rows[0]);
                })
            .catch(reason => {
                let version = new Version();
                version.id = 0;
                version.version = "0";

                return version;
            });
    }

    public getTableName(): string {
        return Version.TABLENAME;
    }

    protected rowToModel(row: any): Version {
        let version = new Version();
        version.id = row.id;
        version.version = row.version;

        return version;
    }

    public save(version: Version): Promise<Version> {
        version.id = 0;

        let query = "INSERT OR REPLACE INTO " + Version.TABLENAME + " (id, version) VALUES ("
                    + this.getValue(version.id)
                    + this.getValue(version.version, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return version;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    getCurrentVersion(): Promise<Version> {
        return this.get("0", false);
    }

    public saveCurrentVersion(version: Version): Promise<void> {
        version.id = 0;

        return this.save(version)
            .then(() => {
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
