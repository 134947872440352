import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { PersonalModel } from "../../models/personalModel.model";

@Injectable({
    providedIn: "root",
})
export class PersonalModelDbDao extends DbDaoBase<PersonalModel> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + PersonalModel.TABLENAME + "_id"
                    + " ON " + PersonalModel.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + PersonalModel.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "category TEXT, "
                    + "doors NUMERIC, "
                    + "seatings NUMERIC, "
                    + "motorization TEXT, "
                    + "counterKind TEXT,"
                    + "counterKind2 TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + PersonalModel.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + PersonalModel.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<PersonalModel> {
        let selectQuery = "SELECT * FROM " + PersonalModel.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let personalModel: PersonalModel = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return personalModel;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return PersonalModel.TABLENAME;
    }

    protected rowToModel(row: any): PersonalModel {
        let personalModel = new PersonalModel();

        personalModel.id = row.id;
        personalModel.wording = row.wording;

        personalModel.doors = row.doors;
        personalModel.seatings = row.seatings;
        personalModel.motorization = row.motorization;

        personalModel.category = JSON.parse(row.category);
        personalModel.counterKind = JSON.parse(row.counterKind);
        personalModel.counterKind2 = JSON.parse(row.counterKind2);

        return personalModel;
    }

    public save(personalModel: PersonalModel): Promise<PersonalModel> {
        let promises = [];

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + PersonalModel.TABLENAME + " ("
                            + "id, wording, category, doors, seatings, motorization, counterKind, counterKind2"
                            + ") VALUES ("
                            + this.getValue(personalModel.id)
                            + this.getValue(personalModel.wording)
                            + this.getValueAsJsonString(personalModel.category)
                            + this.getValue(personalModel.doors)
                            + this.getValue(personalModel.seatings)
                            + this.getValue(personalModel.motorization)
                            + this.getValueAsJsonString(personalModel.counterKind)
                            + this.getValueAsJsonString(personalModel.counterKind2, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return personalModel;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
