import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { DateTime } from "luxon";
import { DeliveryDto } from "../../../core/dto/delivery.dto";
import { DeliveryStates } from "../../../core/interfaces/deliveryStates";
import { DeliveryTypes } from "../../../core/interfaces/deliveryTypes";
import { LinkedMovementDtoGroup } from "../../../core/interfaces/linked-movement-dto.group";
import { NavControllerExtended } from "../../../gyzmo-commons/helpers/navControllerExtended";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";

@Component({
    selector: "popover-delivery-create",
    templateUrl: "delivery-create.popover.html",
    styleUrls: ["delivery-create.popover.scss"],
})
export class DeliveryCreatePopover {
    delivery: DeliveryDto;
    DeliveryStates = DeliveryStates;
    DeliveryTypes = DeliveryTypes;
    startTime: DateTime;

    comment: string;
    deliveryType: string;
    group: LinkedMovementDtoGroup;

    constructor(navCtrl: NavControllerExtended,
                private dateProvider: DateProvider,
                private modalController: ModalController) {
        this.delivery = navCtrl.params.getParam("delivery");
        this.startTime = this.dateProvider.now();
        this.deliveryType = navCtrl.params.getParam("deliveryType");
        this.group = navCtrl.params.getParam("group");

    }

    public async dismiss(data?: any) {
        let returnData = (data) ? { comment: this.comment } : null;
        await this.modalController.dismiss(returnData);
    }
}

