import { Injectable } from "@angular/core";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";
import { SurveyKindDbDao } from "../dao/db/surveyKind.db.dao";
import { SurveyKindWsDao } from "../dao/ws/surveyKind.ws.dao";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class SurveyKindService {
    constructor(private logger: LoggerService,
                private surveyKindWsDao: SurveyKindWsDao,
                private surveyKindDbDao: SurveyKindDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getDbDeliveryList() {
        return this.surveyKindDbDao.listByFilter1("DELIVERY");
    }

    public synchronize(): Promise<void> {
        return this.surveyKindWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(returnCheckStatusDtos => {
                let promises = [];

                returnCheckStatusDtos.forEach(returnCheckStatusDto => {
                    promises.push(this.surveyKindDbDao.save(returnCheckStatusDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }

    public async checkData(): Promise<boolean> {
        let correctness = true;
        let list = await this.surveyKindDbDao.getList(true);

        if (list == null || list.length <= 0) {
            correctness = false;
            this.logger.error(this.constructor.name, "No survey !");
        }

        return correctness;
    }
}
