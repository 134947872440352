import { NgModule } from "@angular/core";
import { BackEndDatePipe } from "./backEndDate.pipe";
import { BackEndDateTimePipe } from "./backEndDateTime.pipe";
import { BackEndHourPipe } from "./backEndHour.pipe";
import { CapitalizePipe } from "./capitalize.pipe";
import { DebugDateTimePipe } from "./debugDateTime.pipe";
import { HighlightPipe } from "./highlight.pipe";
import { OrderByPipe } from "./orderBy.pipe";
import { ReplacePipe } from "./replace.pipe";
import { TruncatePipe } from "./truncate.pipe";
import { UppercasePipe } from "./uppercase.pipe";

@NgModule({
    declarations: [
        CapitalizePipe,
        TruncatePipe,
        ReplacePipe,
        OrderByPipe,
        UppercasePipe,
        HighlightPipe,
        BackEndDatePipe,
        BackEndDateTimePipe,
        BackEndHourPipe,
        DebugDateTimePipe,
    ],
    imports: [],
    exports: [
        CapitalizePipe,
        TruncatePipe,
        ReplacePipe,
        OrderByPipe,
        UppercasePipe,
        HighlightPipe,
        BackEndDatePipe,
        BackEndDateTimePipe,
        BackEndHourPipe,
        DebugDateTimePipe,
    ],
})
export class PipesModule {
}
