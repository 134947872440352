import { Injectable } from "@angular/core";
import { ModelDto } from "../../dto/model.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class ModelWsDao extends WsDao<ModelDto> {
    static WS = "models";

    public getById(serverConnection: ServerConnection, id: string): Promise<ModelDto> {
        return new Promise<ModelDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, ModelWsDao.WS + "/:id", tokens)
                .then(response => {
                    let modelDto = ModelDto.fromBody(response.body);
                    let promises = [];

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(modelDto);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, modelDto: ModelDto): Promise<ModelDto> {
        return new Promise<ModelDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, ModelWsDao.WS, tokens, modelDto.toBody())
                .then(response => {
                    resolve(modelDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
