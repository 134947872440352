import { NgModule } from "@angular/core";
import { KeyValueDbDao } from "../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "./address.db.dao";
import { AttachmentDbDao } from "./attachment.db.dao";
import { CheckStatusDbDao } from "./checkStatus.db.dao";
import { CompanyDbDao } from "./company.db.dao";
import { ConstructionSiteDbDao } from "./constructionSite.db.dao";
import { ContactDetailsDbDao } from "./contactDetails.db.dao";
import { DeliveryDbDao } from "./delivery.db.dao";
import { DeliveryLinkedMovementDbDao } from "./deliveryLinkedMovement.db.dao";
import { DriverInfoDbDao } from "./driverInfo.db.dao";
import { EquipmentDbDao } from "./equipment.db.dao";
import { HourTypeDbDao } from "./hourType.db.dao";
import { InvoiceDbDao } from "./invoice.db.dao";
import { InvoiceLineDbDao } from "./invoiceLine.db.dao";
import { LocationDbDao } from "./location.db.dao";
import { ModelDbDao } from "./model.db.dao";
import { MovementDbDao } from "./movement.db.dao";
import { OfflineModeDbDao } from "./offlineMode.db.dao";
import { OrderDbDao } from "./order.db.dao";
import { OrderLinkedMovementDbDao } from "./orderLinkedMovement.db.dao";
import { PersonalModelDbDao } from "./personalModel.db.dao";
import { SectorDbDao } from "./sector.db.dao";
import { ServerDbDao } from "./server.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";
import { TimesDbDao } from "./times.db.dao";
import { UserDbDao } from "./user.db.dao";

@NgModule({
    imports: [],
    providers: [AddressDbDao,
                AttachmentDbDao,
                ContactDetailsDbDao,
                DriverInfoDbDao,
                EquipmentDbDao,
                KeyValueDbDao,
                MovementDbDao,
                ServerDbDao,
                ThirdPartyDbDao,
                UserDbDao,
                VersionDbDao,
                OfflineModeDbDao,
                CompanyDbDao,
                LocationDbDao,
                OrderDbDao,
                InvoiceDbDao,
                InvoiceLineDbDao,
                DeliveryLinkedMovementDbDao,
                OrderLinkedMovementDbDao,
                ConstructionSiteDbDao,
                CheckStatusDbDao,
                ModelDbDao,
                PersonalModelDbDao,
                DeliveryDbDao,
                HourTypeDbDao,
                SectorDbDao,
                TimesDbDao,
    ],
})
export class DaoModule {
}
