import { Injectable } from "@angular/core";
import { LocationDto } from "../../dto/location.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class LocationWsDao extends WsDao<LocationDto> {
    static WS = "locations";

    public getById(serverConnection: ServerConnection, id: string): Promise<LocationDto> {
        return new Promise<LocationDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, LocationWsDao.WS + "/:id", tokens)
                .then(response => {
                    let location = LocationDto.fromBody(response.body);
                    resolve(location);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, locationDto: LocationDto): Promise<LocationDto> {
        throw new Error("Not implemented");
    }
}
