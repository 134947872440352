import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { OrderLinkedMovement } from "../../models/orderLinkedMovement.model";
import { AttachmentDbDao } from "./attachment.db.dao";
import { EquipmentDbDao } from "./equipment.db.dao";
import { ModelDbDao } from "./model.db.dao";

@Injectable({
    providedIn: "root",
})
export class OrderLinkedMovementDbDao extends DbDaoBase<OrderLinkedMovement> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider,
        private equipmentDbDao: EquipmentDbDao,
        private modelDbDao: ModelDbDao,
        private attachmentDbDao: AttachmentDbDao) {
        super(logger);
    }

    public getByLtcId(ltcId: string, hydrate: boolean): Promise<OrderLinkedMovement[]> {
        let selectQuery = "SELECT * FROM " + OrderLinkedMovement.TABLENAME + " WHERE ltcId = '" + ltcId + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let hydratationPromises = [];
                let linkedMovements: OrderLinkedMovement[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    let orderLinkedMovement: OrderLinkedMovement = this.rowToModel(data.rows[i]);

                    if (hydrate) {
                        hydratationPromises.push(this.modelDbDao.get(orderLinkedMovement.model.id, hydrate)
                            .then(value => {
                                orderLinkedMovement.model = value;
                            }));
                        hydratationPromises.push(this.equipmentDbDao.get(orderLinkedMovement.equipment.id, hydrate)
                            .then(value => {
                                orderLinkedMovement.equipment = value;
                            }));

                        hydratationPromises.push(this.attachmentDbDao.getByObjectAndKey("orderLinkedMovement", orderLinkedMovement.id)
                            .then(value => {
                                orderLinkedMovement.attachments.push(...value);
                            }));
                    }

                    linkedMovements.push(orderLinkedMovement);
                }

                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return linkedMovements;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + OrderLinkedMovement.TABLENAME + "_id"
                    + " ON " + OrderLinkedMovement.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + OrderLinkedMovement.TABLENAME + "_ltcId"
                + " ON " + OrderLinkedMovement.TABLENAME + "(ltcId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + OrderLinkedMovement.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "ltcId TEXT, "
                    + "movementId TEXT, "
                    + "fatherMovementId TEXT, "
                    + "wording TEXT, "
                    + "quantity NUMERIC, "
                    + "preparedQuantity NUMERIC, "
                    + "model TEXT, "
                    + "equipment TEXT, "
                    + "equipmentNature TEXT, "
                    + "service TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + OrderLinkedMovement.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + OrderLinkedMovement.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<OrderLinkedMovement> {
        let selectQuery = "SELECT * FROM " + OrderLinkedMovement.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let orderLinkedMovement: OrderLinkedMovement = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.modelDbDao.get(orderLinkedMovement.model.id, hydrate)
                            .then(value => {
                                orderLinkedMovement.model = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return orderLinkedMovement;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return OrderLinkedMovement.TABLENAME;
    }

    protected rowToModel(row: any): OrderLinkedMovement {
        let orderLinkedMovement = new OrderLinkedMovement();

        orderLinkedMovement.id = row.id;
        orderLinkedMovement.ltcId = row.ltcId;
        orderLinkedMovement.movementId = row.movementId;
        orderLinkedMovement.fatherMovementId = row.fatherMovementId;
        orderLinkedMovement.wording = row.wording;
        orderLinkedMovement.quantity = row.quantity;
        orderLinkedMovement.preparedQuantity = row.preparedQuantity;
        orderLinkedMovement.equipmentNature = row.equipmentNature;

        orderLinkedMovement.model.id = row.model;
        orderLinkedMovement.equipment.id = row.equipment;

        orderLinkedMovement.service = JSON.parse(row.service);

        return orderLinkedMovement;
    }

    public save(orderLinkedMovement: OrderLinkedMovement): Promise<OrderLinkedMovement> {
        let promises = [];

        promises.push(this.modelDbDao.save(orderLinkedMovement.model));
        promises.push(this.equipmentDbDao.save(orderLinkedMovement.equipment));

        orderLinkedMovement.attachments.forEach(attachment => {
            promises.push(this.attachmentDbDao.save(attachment));
        });

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + OrderLinkedMovement.TABLENAME + " ("
                            + "id, ltcId, movementId, fatherMovementId,  wording, quantity, preparedQuantity, model, equipment, equipmentNature, service"
                            + ") VALUES ("
                            + this.getValue(orderLinkedMovement.id)
                            + this.getValue(orderLinkedMovement.ltcId)
                            + this.getValue(orderLinkedMovement.movementId)
                            + this.getValue(orderLinkedMovement.fatherMovementId)
                            + this.getValue(orderLinkedMovement.wording)
                            + this.getValue(orderLinkedMovement.quantity)
                            + this.getValue(orderLinkedMovement.preparedQuantity)
                            + this.getFkValue(orderLinkedMovement.model)
                            + this.getFkValue(orderLinkedMovement.equipment)
                            + this.getValue(orderLinkedMovement.equipmentNature)
                            + this.getValueAsJsonString(orderLinkedMovement.service, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return orderLinkedMovement;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
