import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DatabaseEventService {
    static instance: DatabaseEventService = null;

    private databaseLogSubject: EventEmitter<string> = new EventEmitter();

    constructor() {
        DatabaseEventService.instance = this;
    }

    publishDatabaseLog(filter: string) {
        this.databaseLogSubject.emit(filter);
    }

    getDatabaseLogSubject(): EventEmitter<string> {
        return this.databaseLogSubject;
    }

    public addDom() {
        document.addEventListener("traceDB", this.askForDatabaseLog, true);
    }

    private askForDatabaseLog(e: CustomEvent) {
        e.preventDefault();
        e.stopImmediatePropagation();
        DatabaseEventService.instance.publishDatabaseLog(e.detail.filter);
    }
}
