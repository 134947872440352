import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { InvoiceLine } from "../../models/invoiceLine.model";

@Injectable({
    providedIn: "root",
})
export class InvoiceLineDbDao extends DbDaoBase<InvoiceLine> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public getByInvoiceId(invoiceId: string, hydrate: boolean): Promise<InvoiceLine[]> {
        let selectQuery = "SELECT * FROM " + InvoiceLine.TABLENAME + " WHERE invoiceId = '" + invoiceId + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let invoiceLines: InvoiceLine[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    invoiceLines.push(this.rowToModel(data.rows[i]));
                }

                return invoiceLines;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + InvoiceLine.TABLENAME + "_id"
                    + " ON " + InvoiceLine.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + InvoiceLine.TABLENAME + "_invoiceId"
                + " ON " + InvoiceLine.TABLENAME + "(invoiceId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + InvoiceLine.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "invoiceId TEXT, "
                    + "wording TEXT, "
                    + "quantity NUMERIC, "
                    + "quantity2 NUMERIC, "
                    + "preparedQuantity NUMERIC, "
                    + "model TEXT, "
                    + "service TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + InvoiceLine.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + InvoiceLine.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<InvoiceLine> {
        let selectQuery = "SELECT * FROM " + InvoiceLine.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let invoiceLine: InvoiceLine = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return invoiceLine;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return InvoiceLine.TABLENAME;
    }

    protected rowToModel(row: any): InvoiceLine {
        let invoiceLine = new InvoiceLine();

        invoiceLine.id = row.id;
        invoiceLine.invoiceId = row.invoiceId;
        invoiceLine.wording = row.wording;
        invoiceLine.quantity = row.quantity;
        invoiceLine.quantity2 = row.quantity2;
        invoiceLine.preparedQuantity = row.preparedQuantity;

        invoiceLine.model = JSON.parse(row.model);
        invoiceLine.service = JSON.parse(row.service);

        return invoiceLine;
    }

    public save(invoiceLine: InvoiceLine): Promise<InvoiceLine> {
        let promises = [];
        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + InvoiceLine.TABLENAME + " ("
                            + "id, invoiceId, wording, quantity, quantity2, preparedQuantity, model, service"
                            + ") VALUES ("
                            + this.getValue(invoiceLine.id)
                            + this.getValue(invoiceLine.invoiceId)
                            + this.getValue(invoiceLine.wording)
                            + this.getValue(invoiceLine.quantity)
                            + this.getValue(invoiceLine.quantity2)
                            + this.getValue(invoiceLine.preparedQuantity)
                            + this.getValueAsJsonString(invoiceLine.model)
                            + this.getValueAsJsonString(invoiceLine.service, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return invoiceLine;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
