/* eslint-disable no-console */
import { Logger } from "../../interfaces/logger";

export class ConsoleLogger implements Logger {
    public debug(message: string, ...args: string[]): void {
        if (args.length > 0) {
            console.debug(message, args);
        } else {
            console.debug(message);
        }
    }

    public error(message: string, ...args: string[]): void {
        if (args.length > 0) {
            console.error(message, args);
        } else {
            console.error(message);
        }
    }

    public info(message: string, ...args: string[]): void {
        if (args.length > 0) {
            console.info(message, args);
        } else {
            console.info(message);
        }
    }

    public shareLogs(): Promise<void> {
        // Nothing to do....
        return Promise.resolve();
    }

    public table(table: any[]): void {
        console.table(table);
    }

    public warn(message: string, ...args: string[]): void {
        if (args.length > 0) {
            console.warn(message, args);
        } else {
            console.warn(message);
        }
    }
}
