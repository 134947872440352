import { NgModule } from "@angular/core";
import { GestureEventsDirective } from "./gestureEvents.directive";
import { HorizontalSwipeEventDirective } from "./horizontalSwipeEvents.directive";

@NgModule({
    declarations: [
        GestureEventsDirective,
        HorizontalSwipeEventDirective,
    ],
    imports: [],
    exports: [
        GestureEventsDirective,
        HorizontalSwipeEventDirective,
    ],
    providers: [],
})
export class DirectivesModule {
}
