import { Injectable } from "@angular/core";
import { LANGUAGES } from "../helpers/i18n/constants";

@Injectable({
    providedIn: "root",
})
export class LanguageService {
    public getUserLanguage(): LANGUAGES {
        let language = navigator.language.substring(0, 2);

        switch (language) {
            case "en":
                return LANGUAGES.ENGLISH;
            case "fr":
                return LANGUAGES.FRENCH;
            case "de":
                return LANGUAGES.GERMAN;
            case "es":
                return LANGUAGES.SPANISH;
            case "pt":
                return LANGUAGES.PORTUGUESE;
            case "nl":
                return LANGUAGES.DUTCH;
            default:
                return LANGUAGES.ENGLISH;
        }
    }

    public getUserLanguages(): string[] {
        return navigator.languages.slice();
    }
}

