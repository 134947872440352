import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { IriumTitleModule } from "../../../gyzmo-commons/components/irium-title/irium-title.module";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { FileService } from "../../../gyzmo-commons/services/file.service";
import { EquipmentInfoComponent } from "./equipment-info.component";

@NgModule(
    {
        declarations: [
            EquipmentInfoComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            HttpClientModule,
            TranslateModule,
            PipesModule,
            IriumTitleModule,
        ],
        exports: [
            EquipmentInfoComponent,
        ],
        providers: [
            FileService,
        ],
    })
export class EquipmentInfoComponentModule {
}
