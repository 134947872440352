import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Activity extends ModelBase {
    static TABLENAME = "Activity";

    public id: string = "";
    public activityPriorityId: string = "";
    public activityTypeId: string = "";
    public userId: string = "";
    public frequencyId: string = "";

    constructor() {
        super(Activity.TABLENAME);
    }
}
