import { Injectable } from "@angular/core";
import { EquipmentWsDao } from "../dao/ws/equipment.ws.dao";
import { EquipmentDto } from "../dto/equipment.dto";
import { ModelDto } from "../dto/model.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class EquipmentService {
    constructor(private equipmentWsDao: EquipmentWsDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getById(equipmentDto: EquipmentDto) {
        return this.equipmentWsDao.getById(this.serversConnectionsProvider.getServerConnection(), equipmentDto.id);
    }

    public getList(search?: string) {
        return this.equipmentWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection(), search);
    }

    public getUpdatedMileage(equipmentDto: EquipmentDto) {
        return this.equipmentWsDao.getUpdatedMileage(this.serversConnectionsProvider.getPrimaryServerConnection(), equipmentDto.id);
    }

    public getListByModel(modelDto: ModelDto): Promise<EquipmentDto[]> {
        return this.equipmentWsDao.getListByModel(this.serversConnectionsProvider.getPrimaryServerConnection(), modelDto.id);
    }
}
