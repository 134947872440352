import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { SurveyKind } from "../../models/surveyKind";

@Injectable({
    providedIn: "root",
})
export class SurveyKindDbDao extends DbDaoBase<SurveyKind> {
    constructor(
        logger: LoggerService,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    private static sortTypes(returnCheckStatuss: SurveyKind[]): SurveyKind[] {
        returnCheckStatuss = returnCheckStatuss.sort((a, b) => {
            return a.order.localeCompare(b.order);
        });

        return returnCheckStatuss;
    }

    public getList(hydrate: boolean = false): Promise<SurveyKind[]> {
        let selectQuery = "SELECT * FROM " + SurveyKind.TABLENAME + ";";

        return this.listByQuery(selectQuery);
    }

    public list(): Promise<SurveyKind[]> {
        let selectQuery = "SELECT * FROM " + SurveyKind.TABLENAME + " order by _order ASC;";

        return this.listByQuery(selectQuery);
    }

    public listByFilter1(filter1: string): Promise<SurveyKind[]> {
        let selectQuery = "SELECT * FROM " + SurveyKind.TABLENAME + " where filter1 ='" + filter1 + "' order by _order ASC;";

        return this.listByQuery(selectQuery);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + SurveyKind.TABLENAME + "_id"
                    + " ON " + SurveyKind.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + SurveyKind.TABLENAME + "_filter1"
                + " ON " + SurveyKind.TABLENAME + "(filter1);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + SurveyKind.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY, "
                    + "wording TEXT, "
                    + "filter1 TEXT, "
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + SurveyKind.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + SurveyKind.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<SurveyKind> {
        let selectQuery = "SELECT * FROM " + SurveyKind.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let returnSurveyKind = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return returnSurveyKind;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return SurveyKind.TABLENAME;
    }

    protected rowToModel(row: any): SurveyKind {
        let returnSurveyKind = new SurveyKind();

        returnSurveyKind.id = row.id;
        returnSurveyKind.wording = row.wording;
        returnSurveyKind.filter1 = row.filter1;
        returnSurveyKind.order = row._order;

        return returnSurveyKind;
    }

    public save(returnSurveyKind: SurveyKind): Promise<SurveyKind> {
        let promises = [];

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + SurveyKind.TABLENAME + " ("
                        + "id, "
                        + "wording, "
                        + "filter1, "
                        + "_order"
                        + ") VALUES ("
                        + this.getValue(returnSurveyKind.id)
                        + this.getValue(returnSurveyKind.wording)
                        + this.getValue(returnSurveyKind.filter1)
                        + this.getValue(returnSurveyKind.order, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return returnSurveyKind;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }

    private listByQuery(query: string): Promise<SurveyKind[]> {
        return this.sqlProvider.query(query)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let returnSurveyKinds: SurveyKind[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    returnSurveyKinds.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return SurveyKindDbDao.sortTypes(returnSurveyKinds);
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
