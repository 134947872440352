import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { DATE_NODEJS_FORMAT, HOUR_FORMAT } from "../../interfaces/constants";

@Pipe({ name: "backEndHour" })
export class BackEndHourPipe implements PipeTransform {

    transform(date: string | Date | DateTime) {
        if (!date) {
            return "";
        } else if (date instanceof DateTime) {
            return date.toFormat(HOUR_FORMAT);
        } else if (date instanceof Date) {
            return DateTime.fromJSDate(date).toFormat(HOUR_FORMAT);
        } else if (typeof date === "string") {
            return DateTime.fromFormat(date, DATE_NODEJS_FORMAT).toFormat(HOUR_FORMAT);
        } else {
            return "Unknown date format";
        }
    }
}
