import { AfterContentInit, Component, ElementRef, EventEmitter, OnDestroy, Output } from "@angular/core";

import * as SignaturePadNative from "./signature_pad.js";

export interface Point {
    x: number;
    y: number;
    time: number;
}

export enum Options {
    dotSize = "dotSize",
    minWidth = "minWidth",
    maxWidth = "maxWidth",
    canvasWidth = "canvasWidth",
    canvasHeight = "canvasHeight",
    backgroundColor = "backgroundColor",
    penColor = "penColor",
}

export type PointGroup = Array<Point>;

@Component({
    template: "<canvas #canvas style=' max-width: 100%; max-height: 100%;'></canvas>",
    selector: "signature-pad",
})
export class Angular2SignaturePadComponent implements AfterContentInit, OnDestroy {
    @Output() public onBeginEvent: EventEmitter<boolean>;
    @Output() public onEndEvent: EventEmitter<boolean>;

    private signaturePad: any;
    private elementRef: ElementRef;
    private canvas: any;

    constructor(elementRef: ElementRef) {
        // no op
        this.elementRef = elementRef;
        this.onBeginEvent = new EventEmitter();
        this.onEndEvent = new EventEmitter();
    }

    public get $canvas(): any {
        return this.canvas;
    }

    public ngAfterContentInit(): void {
        this.canvas = this.elementRef.nativeElement.querySelector("canvas");

        try {
            // @ts-ignore
            this.signaturePad = new SignaturePadNative.default(this.canvas);
        } catch {
            // @ts-ignore
            this.signaturePad = new SignaturePadNative(this.canvas);
        }

        this.signaturePad.onBegin = this.onBegin.bind(this);
        this.signaturePad.onEnd = this.onEnd.bind(this);
    }

    public ngOnDestroy(): void {
        this.canvas.width = 0;
        this.canvas.height = 0;
    }

    public resizeCanvas(): void {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
        const canvas: any = this.canvas;
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

        this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }

    // Returns signature image as an array of point groups
    public toData(): Array<PointGroup> {
        if (this.signaturePad) {
            return this.signaturePad.toData();
        } else {
            return [];
        }
    }

    // Draws signature image from an array of point groups
    public fromData(points: Array<PointGroup>): void {
        this.signaturePad.fromData(points as any);
    }

    // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
    public toDataURL(imageType?: string, quality?: number): string {
        return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
    }

    // Draws signature image from data URL
    public fromDataURL(dataURL: string, options: any = {}): void {
        this.signaturePad.fromDataURL(dataURL, options);
    }

    // Clears the canvas
    public clear(): void {
        this.signaturePad.clear();
    }

    // Returns true if canvas is empty, otherwise returns false
    public isEmpty(): boolean {
        return this.signaturePad.isEmpty();
    }

    // set an option on the signaturePad - e.g. set('minWidth', 50);
    public set(option: Options, value: any): void {
        switch (option) {
            case "canvasHeight":
                this.canvas.height = value;
                break;
            case "canvasWidth":
                this.canvas.width = value;
                break;
            default:
                this.signaturePad["" + option] = value;
        }
    }

    // notify subscribers on signature begin
    public onBegin(): void {
        this.onBeginEvent.emit(true);
    }

    // notify subscribers on signature end
    public onEnd(): void {
        this.onEndEvent.emit(true);
    }

    public queryPad(): any {
        return this.signaturePad;
    }
}
