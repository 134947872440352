import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "orderBy" })
/**
 * *ngFor="let tab of tabs | orderBy : ['position', 'DESC']"
 */
export class OrderByPipe implements PipeTransform {
    transform(array: Array<any>, args?) {
        if (array) {
            let orderByProperty: string;
            let orderByOrientation: string = "ASC";
            switch (args.length) {
                case 1:
                    orderByProperty = args[0];
                    break;
                case 2:
                    orderByProperty = args[0];
                    orderByOrientation = args[1];
                    break;
            }

            let byVal = (orderByOrientation == "ASC") ? 1 : -1;

            if (orderByProperty.length > 0) {
                array.sort((a: any, b: any) => {
                    if (a[orderByProperty] < b[orderByProperty]) {
                        return -1 * byVal;
                    } else if (a[orderByProperty] > b[orderByProperty]) {
                        return 1 * byVal;
                    } else {
                        return 0;
                    }
                });
            }

            return array;
        }

    }
}
