export class VersionHelper {
    public static compareVersions(version1: number[], version2: number[]): number {
        for (let i = 0; i < version1.length; i++) {
            if (version1[i] < version2[i]) {
                return -1;
            }

            if (version1[i] > version2[i]) {
                return 1;
            }
        }

        return 0;
    }

    public static parseVersion(versionString: string): number[] {
        if (!versionString) {
            return [0, 0, 0, 0];
        }

        let version: number[] = [0, 0, 0, 0];
        let stringTokens = versionString.split(/[-.]/);

        for (let i = 0; i < stringTokens.length; i++) {
            try {
                version[i] = Number(stringTokens[i]);

                if (Number.isNaN(version[i])) {
                    version[i] = 0;
                }
            } catch { /* empty */
            }
        }

        return version;
    }
}
