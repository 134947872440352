import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LoggerService } from "../services/logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class NavParamsProvider {
    params: Map<string, any>;

    constructor(private logger: LoggerService) {
        this.params = new Map<string, any>();
    }

    public clearParams(): void {
        this.logger.debug(this.constructor.name, "clearParams");
        this.params.clear();
    }

    public hasParam(name: string): boolean {
        this.checkCaller();
        return this.params.has(name);
    }

    public setParam(name: string, value: any): void {
        this.logger.debug(this.constructor.name, "setParam : " + name + "=" + JSON.stringify(value));
        this.clearParams();
        this.params.set(name, value);
    }

    public addParam(name: string, value: any): void {
        this.logger.debug(this.constructor.name, "addParam : " + name + "=" + JSON.stringify(value));
        this.params.set(name, value);
    }

    public getParam(name: string) {
        this.checkCaller();
        return this.params.get(name);
    }

    setParams(params: any) {
        Object.keys(params).forEach(key => {
            this.addParam(key, params[key]);
        });
    }

    private checkCaller() {
        if (environment.production) {
            return;
        }

        let stack = (new Error).stack;
        if (stack.indexOf("ionViewWillEnter") < 0 && stack.indexOf("ionViewDidEnter") < 0) {
            this.logger.warn(this.constructor.name,
                "Do not call hasParam in ngOnInit or page constructor ! "
                + "The next time the page is called, you will not get updated params but the ones of the first call ! "
                + "Call it instead in ionViewWillEnter / ionViewDidEnter.");
        }
    }
}
