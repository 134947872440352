import { NgModule } from "@angular/core";
import { Angular2SignaturePadComponent } from "./angular2-signaturepad.component";

@NgModule({
    declarations: [
        Angular2SignaturePadComponent,
    ],
    imports: [],
    exports: [
        Angular2SignaturePadComponent,
    ],
})
export class Angular2SignaturePadModule {
}
