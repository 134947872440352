import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Equipment } from "../../models/equipment.model";
import { ModelDbDao } from "./model.db.dao";
import { PersonalModelDbDao } from "./personalModel.db.dao";

@Injectable({
    providedIn: "root",
})
export class EquipmentDbDao extends DbDaoBase<Equipment> {
    constructor(logger: LoggerService, private sqlProvider: AppSqlProvider, private modelDbDao: ModelDbDao, private personalModelDbDao: PersonalModelDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Equipment.TABLENAME + "_id"
                    + " ON " + Equipment.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Equipment.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "title TEXT, "
                    + "picture TEXT, "
                    + "options TEXT, "
                    + "description TEXT, "
                    + "registrationNumber TEXT, "
                    + "color TEXT, "
                    + "latitude NUMERIC, "
                    + "longitude NUMERIC,"
                    + "model TEXT, "
                    + "trackerVersion TEXT, "
                    + "mileage NUMERIC,"
                    + "mileageDate TEXT,"
                    + "personalModel TEXT,"
                    + "server NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Equipment> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let equipment: Equipment = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    if (hydrate) {
                        hydratationPromises.push(this.modelDbDao.get(equipment.model.id, hydrate)
                            .then(value => {
                                equipment.model = value;
                            }));
                        hydratationPromises.push(this.personalModelDbDao.get(equipment.personalModel.id, hydrate)
                            .then(value => {
                                equipment.personalModel = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return equipment;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Equipment.TABLENAME;
    }

    protected rowToModel(row: any): Equipment {
        let equipment = new Equipment();

        equipment.id = row.id;
        equipment.title = row.title;
        equipment.picture = row.picture;
        equipment.options = row.options;
        equipment.description = row.description;
        equipment.registrationNumber = row.registrationNumber;
        equipment.color = row.color;
        equipment.latitude = row.latitude;
        equipment.longitude = row.longitude;
        equipment.trackerVersion = row.trackerVersion;

        equipment.mileage = row.mileage;
        equipment.mileageDate = row.mileageDate;

        equipment.model.id = row.model;
        equipment.personalModel.id = row.personalModel;

        return equipment;
    }

    public save(equipment: Equipment): Promise<Equipment> {
        let promises = [];
        promises.push(this.modelDbDao.save(equipment.model));
        promises.push(this.personalModelDbDao.save(equipment.personalModel));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Equipment.TABLENAME + " (id, title, picture, options, " +
                            "description, registrationNumber, color, latitude, longitude, model, trackerVersion, mileage, " +
                            "mileageDate, personalModel) VALUES ("
                            + this.getValue(equipment.id)
                            + this.getValue(equipment.title)
                            + this.getValue(equipment.picture)
                            + this.getValue(equipment.options)
                            + this.getValue(equipment.description)
                            + this.getValue(equipment.registrationNumber)
                            + this.getValue(equipment.color)
                            + this.getValue(equipment.latitude)
                            + this.getValue(equipment.longitude)
                            + this.getFkValue(equipment.model)
                            + this.getValue(equipment.trackerVersion)
                            + this.getValue(equipment.mileage)
                            + this.getValue(equipment.mileageDate)
                            + this.getFkValue(equipment.personalModel, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return equipment;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
