export enum LtcStatuses {
    RESERVATION = "2",
    CONTRACT = "3",
    CANCELLED = "Z",
    BEING_PREPARED = "PREP",
    READY = "CONTRAT_PREPARE",
    DELIVERY = "TOURNEE",
}

export class LtcStatusesHelper {
    public static isAContract(ltcStatus: string) {
        return ltcStatus == LtcStatuses.CONTRACT;
    }

    public static isBeingPrepared(ltcStatus: string) {
        return ltcStatus == LtcStatuses.BEING_PREPARED || ltcStatus == "PREP";
    }

    public static isCanceled(ltcStatus: string) {
        return ltcStatus == LtcStatuses.CANCELLED;
    }

    public static isReady(ltcStatus: string) {
        return ltcStatus == LtcStatuses.READY || ltcStatus == "PRETE";
    }

    public static isReserved(ltcStatus: string) {
        return ltcStatus == LtcStatuses.RESERVATION || ltcStatus == "2";
    }
}
