import { SurveyKind } from "../models/surveyKind";
import { BaseDto } from "./base/base.dto";

export class SurveyKindDto extends BaseDto<SurveyKind> {
    public id: string = "";
    public wording: string = "";
    public order: string = "";
    public filter1: string = "";
    public value: boolean = false;

    static fromBody(body: any): SurveyKindDto {
        let returnSurveyKindDto = new SurveyKindDto();

        returnSurveyKindDto.id = body.field0;
        returnSurveyKindDto.wording = body.field1;
        returnSurveyKindDto.order = body.field5;
        returnSurveyKindDto.filter1 = body.field7;

        return returnSurveyKindDto;
    }

    static fromModel(returnSurveyKind: SurveyKind) {
        let returnSurveyKindDto = new SurveyKindDto();

        returnSurveyKindDto.id = returnSurveyKind.id;
        returnSurveyKindDto.wording = returnSurveyKind.wording;
        returnSurveyKindDto.order = returnSurveyKind.order;
        returnSurveyKindDto.filter1 = returnSurveyKind.filter1;
        returnSurveyKindDto.value = returnSurveyKind.value;

        return returnSurveyKindDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): SurveyKind {
        let returnSurveyKind = new SurveyKind();

        returnSurveyKind.id = this.id;
        returnSurveyKind.wording = this.wording;
        returnSurveyKind.order = this.order;
        returnSurveyKind.filter1 = this.filter1;
        returnSurveyKind.filter1 = this.filter1;
        returnSurveyKind.value = this.value;

        return returnSurveyKind;
    }
}
