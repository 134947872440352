export class GeocoderReverseResult {
    subThoroughfare: string = "";
    thoroughfare: string = "";
    postalCode: string = "";
    locality: string = "";

    public toString() {
        return ("" + this.subThoroughfare + " " + this.thoroughfare + " " + this.postalCode + " " + this.locality).trim();
    }
}
