import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Address } from "./address.model";
import { ConstructionSite } from "./constructionSite.model";
import { ThirdParty } from "./thirdParty.model";

export class Invoice extends ModelBase {
    static TABLENAME = "Invoices";

    id: string = "";
    wording: string = "";
    documentNumber: string = "";
    groupingEvent: string = "";
    groupingMovement: string = "";
    isTruckDelivery: number = 0;

    // Fks
    prescriber: ThirdParty;
    thirdParty: ThirdParty;
    constructionSite: ConstructionSite;
    orderPicker: ThirdParty;

    mainAddress: Address;

    constructor() {
        super(Invoice.TABLENAME);

        this.prescriber = new ThirdParty();
        this.thirdParty = new ThirdParty();
        this.constructionSite = new ConstructionSite();
        this.orderPicker = new ThirdParty();

        this.mainAddress = new Address();
    }
}
