import { EventEmitter, Injectable } from "@angular/core";
import { Network } from "@capacitor/network";
import { Observable } from "rxjs";
import { LoggerService } from "../services/logs/logger.service";

@Injectable({
    providedIn: "root",
})
export class NetworkHelper {
    private connected = true;
    private connectivityChangedSubject = new EventEmitter<boolean>();
    private forceConnectivityStateSubject = new EventEmitter<boolean>();

    constructor(private logger: LoggerService) {
    }

    public initialize() {
        this.getForceConnectivityStateObservable()
            .subscribe(args => {
                if (this.connected != args) {
                    if (args) {
                        this.logger.debug(this.constructor.name, "Network connected!");
                    } else {
                        this.logger.debug(this.constructor.name, "Network disconnected :-(");
                    }
                }
                this.connected = args;
                this.publishConnectivityChanged(this.connected);
            });

        this.logger.debug(this.constructor.name, "Waiting for network events, mobile mode");

        void Network.addListener("networkStatusChange", status => {
            this.logger.debug(this.constructor.name, "Network connected!");
            this.connected = status.connected;
            this.publishConnectivityChanged(this.connected);
        });
    }

    public getConnectivityObservable(): Observable<boolean> {
        return this.connectivityChangedSubject;
    }

    public publishConnectivityChanged(state: boolean) {
        this.connectivityChangedSubject.emit(state);
    }

    public isConnected(): boolean {
        return this.connected;
    }

    public getForceConnectivityStateObservable(): Observable<boolean> {
        return this.forceConnectivityStateSubject;
    }

    public publishForceConnectivityStateChanged(state: boolean) {
        this.forceConnectivityStateSubject.emit(state);
    }
}
