import { Activity } from "../models/activity.model";
import { BaseDto } from "./base/base.dto";

export class ActivityDto extends BaseDto<Activity> {
    public id: string = "";
    public activityPriorityId: string = "";
    public activityTypeId: string = "";
    public userId: string = "";
    public frequencyId: string = "";

    static fromBody(body: any): ActivityDto {
        let activityDto = new ActivityDto();

        activityDto.id = body.id;
        if (body.activityPriority) {
            activityDto.activityPriorityId = body.activityPriority.id;
        }
        if (body.activityType) {
            activityDto.activityTypeId = body.activityType.id;
        }
        if (body.user) {
            activityDto.userId = body.user.id;
        }
        if (body.frequency) {
            activityDto.frequencyId = body.frequency.id;
        }

        return activityDto;
    }

    public static fromModel(model: Activity): ActivityDto {
        let activityDto = new ActivityDto();

        activityDto.id = model.id;
        activityDto.activityPriorityId = model.activityPriorityId;
        activityDto.activityTypeId = model.activityTypeId;
        activityDto.userId = model.userId;
        activityDto.frequencyId = model.frequencyId;

        return activityDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): Activity {
        let activity = new Activity();

        activity.id = this.id;
        activity.activityPriorityId = this.activityPriorityId;
        activity.activityTypeId = this.activityTypeId;
        activity.userId = this.userId;
        activity.frequencyId = this.frequencyId;

        return activity;
    }
}
