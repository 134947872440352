import { Service } from "../models/service.model";
import { BaseDto } from "./base/base.dto";

export class ServiceDto extends BaseDto<Service> {
    public id: string = "";

    static fromBody(body: any): ServiceDto {
        let serviceDto = new ServiceDto();

        serviceDto.id = body.id;

        return serviceDto;
    }

    static fromModel(service: Service): ServiceDto {
        let serviceDto = new ServiceDto();

        serviceDto.id = service.id;

        return serviceDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): Service {
        let service = new Service();

        service.id = this.id;

        return service;
    }
}
