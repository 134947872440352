import { Component, Input } from "@angular/core";

export class ProgressDialogDriver {
    private title: string = "";
    private value: number = 0;
    private max: number = 1;

    public getValue(): number {
        return this.value;
    }

    public setValue(value: number) {
        this.value = value;
    }

    public incrementValue() {
        this.value++;
    }

    public getMax(): number {
        return this.max;
    }

    public setMax(max: number) {
        this.max = max;
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(value: string) {
        this.title = value;
    }
}

@Component({
    selector: "progress-dialog",
    templateUrl: "progress-dialog.popover.html",
    styleUrls: ["progress-dialog.popover.scss"],
})
export class ProgressDialogPopover {
    @Input() progressDialogDriver: ProgressDialogDriver;
}

