// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  cursor: pointer;
}
ion-card ion-card-content {
  padding: 0 10px 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/equipment-info/equipment-info.component.scss"],"names":[],"mappings":"AACA;EACI,eAAA;AAAJ;AAEI;EACI,yBAAA;AAAR","sourcesContent":["\r\nion-card {\r\n    cursor: pointer;\r\n\r\n    ion-card-content {\r\n        padding: 0 10px 10px 10px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
