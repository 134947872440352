import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { GyzmoButtonModule } from "../../../gyzmo-commons/components/gyzmo-button/gyzmo-button.module";
import { IriumInputModule } from "../../../gyzmo-commons/components/irium-input/irium-input.module";
import { IriumTitleModule } from "../../../gyzmo-commons/components/irium-title/irium-title.module";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { NavParamsProvider } from "../../../gyzmo-commons/providers/navParams.provider";
import { DeliveryCreatePopover } from "./delivery-create.popover";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: "",
                component: DeliveryCreatePopover,
            },
        ]),
        TranslateModule,
        PipesModule,
        GyzmoButtonModule,
        IriumTitleModule,
        IriumInputModule,
    ],
    declarations: [DeliveryCreatePopover],
    providers: [
        NavParamsProvider,
    ],
})
export class DeliveryCreatePopoverModule {
}
