import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { IonInput } from "@ionic/angular";
import { isNullOrEmpty } from "../../helpers/null.helper";

@Component({
    selector: "irium-input",
    templateUrl: "irium-input.html",
    styleUrls: ["irium-input.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IriumInputComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => IriumInputComponent),
        },
    ],
})
export class IriumInputComponent implements OnInit, ControlValueAccessor, Validator {
    @ViewChild("inputField", { static: false }) inputField: IonInput;

    @Input() autocomplete: "on" | "off" | "name" | "honorific-prefix" | "given-name" | "additional-name" | "family-name" | "honorific-suffix" | "nickname" | "email" | "username" | "new-password" | "current-password" | "one-time-code" | "organization-title" | "organization" | "street-address" | "address-line1" | "address-line2" | "address-line3" | "address-level4" | "address-level3" | "address-level2" | "address-level1" | "country" | "country-name" | "postal-code" | "cc-name" | "cc-given-name" | "cc-additional-name" | "cc-family-name" | "cc-number" | "cc-exp" | "cc-exp-month" | "cc-exp-year" | "cc-csc" | "cc-type" | "transaction-currency" | "transaction-amount" | "language" | "bday" | "bday-day" | "bday-month" | "bday-year" | "sex" | "tel" | "tel-country-code" | "tel-national" | "tel-area-code" | "tel-local" | "tel-extension" | "impp" | "url" | "photo" = "off";
    @Input() type: "date" | "datetime-local" | "email" | "month" | "number" | "password" | "search" | "tel" | "text" | "time" | "url" | "week" = "text";
    @Input() label: string;
    @Input() placeholder: string = null;
    @Input() labelPlacement: "fixed" | "floating" | "stacked" = "stacked";
    @Input() inputmode: "decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url" = null;
    @Input() required: boolean = false;
    @Input() enterkeyhint: "done" | "enter" | "go" | "next" | "previous" | "search" | "send" = null;
    @Input() spellcheck: boolean = false;
    @Input() readonly: boolean = false;
    @Input() disabled: boolean = false;
    @Input() min: number = null;
    @Input() max: number = null;
    @Input() minlength: number = null;
    @Input() maxlength: number = null;
    @Input() additionalIcon: string;
    @Input() mandatory: boolean;

    @Output() clearSearch: EventEmitter<void> = new EventEmitter();

    value: any;
    passwordFieldType = "password";
    touched = false;

    ngOnInit(): void {
        if (isNullOrEmpty(this.inputmode)) {
            switch (this.type) {
                case "number":
                    this.inputmode = "numeric";
                    break;
                case "tel":
                    this.inputmode = "tel";
                    break;
                case "email":
                    this.inputmode = "email";
                    break;
            }
        }
    }

    public togglePasswordFieldType() {
        if (this.passwordFieldType == "password") {
            this.passwordFieldType = "text";
        } else {
            this.passwordFieldType = "password";
        }
    }

    public clearField() {
        if (!this.disabled) {
            this.value = "";
            this.onChange(this.value);
            if (this.clearSearch) {
                this.clearSearch.emit();
            }
        }
        this.markAsTouched();
    }

    public onInputChange($event) {
        if (!this.disabled) {
            if ($event.detail.currentTarget) {
                this.value = $event.detail.currentTarget.value;
            } else {
                this.value = $event.detail.value;
            }
            this.onChange(this.value);
        }
        this.markAsTouched();
    }

    public setFocus() {
        void this.inputField.setFocus();
        this.markAsTouched();
    }

    public unFocus() {
        if (this.inputField) {
            void this.inputField.getInputElement().then(
                inputElement => inputElement.blur(),
            );
        }
    }

    onChange = (value) => {
    };

    onTouched = () => {
    };

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any) {
        this.value = value;
    }

    markAsTouched() {
        this.onTouched();
        this.touched = true;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }
}
