// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 15px 0 10px 0;
}

.wrapper .line {
  border-bottom: var(--ion-color-secondary) 2px solid;
}
.wrapper h1 {
  margin: 0 0 3px 0;
}
.wrapper ion-icon {
  font-size: x-large;
}
.wrapper ion-label {
  font-size: small;
}
.wrapper.smaller h1 {
  font-size: larger;
}
.wrapper.smaller ion-icon {
  font-size: large;
}
.wrapper.smaller ion-label {
  font-size: small;
}
.wrapper.smaller .line {
  border-bottom: var(--with-bottom-border-color) thin solid;
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/components/irium-title/irium-title.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,sBAAA;AACJ;;AAII;EACI,mDAAA;AADR;AAII;EACI,iBAAA;AAFR;AAKI;EACI,kBAAA;AAHR;AAMI;EACI,gBAAA;AAJR;AAQQ;EACI,iBAAA;AANZ;AASQ;EACI,gBAAA;AAPZ;AAUQ;EACI,gBAAA;AARZ;AAWQ;EACI,yDAAA;AATZ","sourcesContent":[":host {\r\n    display: block;\r\n    padding: 15px 0 10px 0;\r\n}\r\n\r\n.wrapper {\r\n\r\n    .line {\r\n        border-bottom: var(--ion-color-secondary) 2px solid;\r\n    }\r\n\r\n    h1 {\r\n        margin: 0 0 3px 0;\r\n    }\r\n\r\n    ion-icon {\r\n        font-size: x-large;\r\n    }\r\n\r\n    ion-label {\r\n        font-size: small;\r\n    }\r\n\r\n    &.smaller {\r\n        h1 {\r\n            font-size: larger;\r\n        }\r\n\r\n        ion-icon {\r\n            font-size: large;\r\n        }\r\n\r\n        ion-label {\r\n            font-size: small;\r\n        }\r\n\r\n        .line {\r\n            border-bottom: var(--with-bottom-border-color) thin solid;\r\n        }\r\n    }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
