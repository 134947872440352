import { DeviceHelper } from "../helpers/device.helper";
import { SqlHelper } from "../helpers/sql.helper";
import { DatabaseLocation } from "../interfaces/databaseLocation";
import { SQLiteResult, SqlProvider } from "../interfaces/sqlProvider";
import { LoggerService } from "../services/logs/logger.service";

const win: any = window;

export class WebSqlBrowserSqlProvider implements SqlProvider {
    public dbName: string;
    private _db: any;
    private initialized: boolean = false;
    private bulkMode = false;
    private bulkQueries: string[] = [];

    public constructor(protected logger: LoggerService,
                       protected deviceHelper: DeviceHelper) {
    }

    public close(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.logger.debug(this.constructor.name, "Closing database " + this.dbName);

            if (!this.isInitialized()) {
                this.logger.debug(this.constructor.name, "No database to close");
                resolve(true);
            } else {
                this.initialized = false;
                this.dbName = "";
                resolve(true);
            }
        });
    }

    public async commitBulk() {
        this.logger.warn(this.constructor.name, "Committing all bulked queries !");

        this.bulkMode = false;
        await this.commitBulkQueries(this.bulkQueries);
        this.bulkQueries = [];
    }

    public disableBulkWriting() {
        this.logger.warn(this.constructor.name, "Disabling bulk mode ! Dropping all cached queries !");
        this.bulkQueries = [];
        this.bulkMode = false;
    }

    public dropDatabase(): Promise<void> {
        this.logger.debug(this.constructor.name, "dropDatabase");

        return new Promise<void>(resolve => {
            return this.query(`SELECT name
                               FROM sqlite_master
                               WHERE type = 'table'`)
                .then(async data => {
                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            // TODO - clean parameters
                            if (data.rows[i].name !== "__WebKitDatabaseInfoTable__") {
                                await this.query(`DROP TABLE ${ data.rows[i].name }`);
                            }
                        }
                    }

                    resolve();
                });
        });
    }

    public enableBulkWriting() {
        this.logger.warn(this.constructor.name, "Enabling bulk mode !");
        this.bulkMode = true;
    }

    public getDbName(): string {
        return this.dbName;
    }

    public async getExistingTables(): Promise<string[]> {
        this.logger.info(this.constructor.name, "getExistingTables");

        let data = await this.query("SELECT name FROM sqlite_master WHERE type = 'table'");
        let result = [];

        if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
                if (data.rows[i].name !== "__WebKitDatabaseInfoTable__") {
                    result.push(data.rows[i].name);
                }
            }
        }

        return result;
    }

    public initialize(dbName: string,
                      location: DatabaseLocation): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.logger.debug(this.constructor.name, "Opening database " + dbName + " on location " + location);

            try {
                this._db = win.openDatabase(dbName, "1.0", "database", 5 * 1024 * 1024);
            } catch (e) {
                this.logger.warn(this.constructor.name, "Database estimated size is too high, trying to reduce it. Running code under PhantomJS ?");
                this._db = win.openDatabase(dbName, "1.0", "database", 512);
            }
            this.initialized = true;
            this.dbName = dbName;
            resolve(true);
        });
    }

    public isInitialized() {
        return this.initialized;
    }

    public query(queryText: string, bulkable: boolean = true): Promise<SQLiteResult> {
        return new Promise((resolve, reject) => {
            if (bulkable && this.bulkMode && (queryText.toUpperCase().startsWith("INSERT") || queryText.toUpperCase().startsWith("DELETE"))) {
                this.logger.debug(this.constructor.name, queryText + " as been bulked.");
                this.bulkQueries.push(queryText);
                resolve(new SQLiteResult(true, null));
            } else {
                let t0 = performance.now();
                try {
                    this._db.transaction(
                        (tx: any) => {
                            tx.executeSql(queryText, [],
                                (tx: any, res: any) => {
                                    let result = this.toSqliteResult(res);

                                    let t1 = performance.now();
                                    this.logger.debug(this.constructor.name,
                                        "Database=" + this.dbName,
                                        "Query=\"" + queryText + "\"",
                                        "Result=" + SqlHelper.stringifySqlResult(result),
                                        "[" + Math.floor(t1 - t0) + "ms]");

                                    if (Math.floor(t1 - t0) > 1500) {
                                        this.logger.warn(this.constructor.name,
                                            "Long queryText, need optimisation ? [" + Math.floor(t1 - t0) + "ms]",
                                            "Database=" + this.dbName,
                                            "Query=\"" + queryText + "\"");
                                    }

                                    resolve(result);
                                },
                                (tx: any, err: any) => {
                                    this.logger.error(this.constructor.name,
                                        "Database=" + this.dbName,
                                        "Query=\"" + queryText + "\"",
                                        "Result=" + SqlHelper.stringifySqlError(err));
                                    reject(new SQLiteResult(false, null, SqlHelper.stringifySqlError(err)));
                                });
                        },
                        (err: any) => reject({ err: err }));
                } catch (err) {
                    this.logger.error(this.constructor.name,
                        "Database=" + this.dbName,
                        "Query=\"" + queryText + "\"",
                        "Result=" + SqlHelper.stringifySqlError(err));

                    reject(new SQLiteResult(false, null, SqlHelper.stringifySqlError(err)));
                }
            }
        });
    }

    private commitBulkQueries(queries: string[]): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this._db.transaction(
                    (tx: any) => {
                        for (const queryText of queries) {
                            tx.executeSql(queryText, [],
                                (tx: any, res: any) => {
                                },
                                (tx: any, err: any) => {
                                });
                        }
                    },
                    (err: any) => reject({ err: err }));

                resolve();
            } catch (err) {
                this.logger.error(this.constructor.name,
                    "Database=" + this.dbName,
                    "Result=" + SqlHelper.stringifySqlError(err));

                reject(new SQLiteResult(false, null, SqlHelper.stringifySqlError(err)));
            }
        });
    }

    private toSqliteResult(res: any): SQLiteResult {
        let result = new SQLiteResult(true, []);

        for (let i = 0; i < res.rows.length; i++) {
            result.rows.push(res.rows.item(i));
        }

        if (res.rowsAffected > 0) {
            try {
                result.insertId = res.insertId;
            } catch { /* empty */
            }
        }

        return result;
    }
}
