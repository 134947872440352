// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --overflow: hidden;
}

.close-button {
  position: fixed;
  top: -10px;
  right: -10px;
  margin: 0;
  z-index: 10;
}

.delete-icon ion-icon {
  padding: 0 4px;
}

h2 {
  color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/popovers/delivery-create/delivery-create.popover.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,UAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;AACJ;;AAGI;EACI,cAAA;AAAR;;AAIA;EACI,+BAAA;AADJ","sourcesContent":["ion-content {\r\n    --overflow: hidden;\r\n}\r\n\r\n.close-button {\r\n    position: fixed;\r\n    top: -10px;\r\n    right: -10px;\r\n    margin: 0;\r\n    z-index: 10;\r\n}\r\n\r\n.delete-icon {\r\n    ion-icon {\r\n        padding: 0 4px;\r\n    }\r\n}\r\n\r\nh2 {\r\n    color: var(--ion-color-primary);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
