import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";
import { DATE_FORMAT, DATE_NODEJS_FORMAT, HOUR_FORMAT } from "../../interfaces/constants";
import { DateHelper } from "../date.helper";

@Pipe({ name: "backEndDateTime" })
export class BackEndDateTimePipe implements PipeTransform {

    transform(date: string | Date | DateTime) {
        if (!date) {
            return "";
        } else if (date instanceof DateTime) {
            return date.toFormat(DATE_FORMAT + " " + HOUR_FORMAT);
        } else if (date instanceof Date) {
            return DateTime.fromJSDate(date).toFormat(DATE_FORMAT + " " + HOUR_FORMAT);
        } else if (typeof date === "string") {
            return DateHelper.tryFromFormat(date, DATE_NODEJS_FORMAT).toFormat(DATE_FORMAT + " " + HOUR_FORMAT);
        } else {
            return "Unknown date format";
        }
    }
}
