import { ContactDetail, ContactDetailTypes } from "../models/contactDetail.model";
import { BaseDto } from "./base/base.dto";

export class ContactDetailDto extends BaseDto<ContactDetail> {
    public id: string = "";
    public thirdPartyId: string = "";
    public type: { id: string } = { id: ContactDetailTypes.PHONE };
    public value: string = "";
    public wording: string = "";

    public static fromBody(body: any, thirdPartyId: string): ContactDetailDto {
        let contactDetailDto = new ContactDetailDto();

        contactDetailDto.id = "" + body.id;
        contactDetailDto.value = body.value;
        contactDetailDto.wording = body.wording;
        contactDetailDto.thirdPartyId = thirdPartyId;

        if (body.type) {
            contactDetailDto.type = { id: body.type.id };
        }

        return contactDetailDto;
    }

    static fromModel(contactDetail: ContactDetail, thirdPartyId: string): ContactDetailDto {
        let contactDetailDto = new ContactDetailDto();

        contactDetailDto.id = contactDetail.id;
        contactDetailDto.value = contactDetail.value;
        contactDetailDto.wording = contactDetail.wording;
        contactDetailDto.type = contactDetail.type;
        contactDetailDto.thirdPartyId = thirdPartyId;

        return contactDetailDto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    toModel(): ContactDetail {
        let contactDetail = new ContactDetail();

        contactDetail.id = this.id;
        contactDetail.value = this.value;
        contactDetail.wording = this.wording;
        contactDetail.type = this.type;

        return contactDetail;
    }
}
