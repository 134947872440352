import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Attachment } from "./attachment.model";

export class InvoiceLine extends ModelBase {
    static TABLENAME = "InvoiceLines";

    id: string = "";
    invoiceId: string = "";
    wording: string = "";
    model: any = {};
    service: any = {};
    quantity: number = 0;
    quantity2: number = 0;
    preparedQuantity: number = 0;
    attachments: Attachment[] = [];

    constructor() {
        super(InvoiceLine.TABLENAME);
    }
}
