import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { DateProvider } from "../../gyzmo-commons/interfaces/dateProvider";
import { CacheService } from "../../gyzmo-commons/services/cache.service";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { AttachmentWsDao } from "../dao/ws/attachment.ws.dao";
import { AttachmentDto } from "../dto/attachment.dto";
import { AttachmentKinds } from "../interfaces/attachmentKinds";
import { Attachment } from "../models/attachment.model";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class AttachmentService {
    constructor(private attachmentWsDao: AttachmentWsDao,
                private attachmentDbDao: AttachmentDbDao,
                private logger: LoggerService,
                private dateProvider: DateProvider,
                private cacheService: CacheService,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public save(attachment: Attachment): Promise<Attachment> {
        let promise = [];

        promise.push(this.attachmentWsDao.save(this.serversConnectionsProvider.getServerConnection(), AttachmentDto.fromModel(attachment))
            .then((updatedAttachment: AttachmentDto) => {
                return this.attachmentDbDao.save(updatedAttachment.toModel());
            }));

        return Promise.all(promise)
            .then(results => {
                return results[0];
            });
    }

    public delete(attachment: AttachmentDto): Promise<void> {
        let promise = [];

        promise.push(this.attachmentWsDao.delete(this.serversConnectionsProvider.getServerConnection(), attachment.id, attachment.object, attachment.key)
            .then(() => {
                return this.attachmentDbDao.delete(attachment.id);
            }));

        return Promise.all(promise)
            .then(results => {
                return;
            });
    }

    public getByCompanyId(companyId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getPrimaryServerConnection(), companyId, attachmentKind);
    }

    public async getCGL(companyId: string): Promise<string> {
        let key = "GCL_" + companyId;
        if (await this.cacheService.isCached(key)) {
            let cache = await this.cacheService.getCached(key);
            return cache.value;
        } else {
            let attachments = await this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getPrimaryServerConnection(), companyId, AttachmentKinds.CGL);

            if (attachments.length > 0) {
                try {
                    let content = "";

                    if (!isNullOrEmpty(attachments[0].file)) {
                        content = attachments[0].file;
                    } else if (attachments[0].attachedDocuments.length > 0) {
                        content = attachments[0].attachedDocuments[0].file;
                    }

                    await this.cacheService.cache(key, content, this.dateProvider.now().plus({ years: 10 }));

                    return content;
                } catch (e) {
                    this.logger.error(this.constructor.name, e);
                    return "";
                }
            } else {
                return "";
            }
        }
    }

    public async getUserManual(): Promise<string> {
        let key = "USER_MANUAL";
        if (await this.cacheService.isCached(key)) {
            let cache = await this.cacheService.getCached(key);
            return cache.value;
        } else {
            let attachments = await this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getPrimaryServerConnection(), "IRIUM", AttachmentKinds.MODE_EMPLOI);

            if (attachments.length > 0) {
                try {
                    let content = "";

                    if (!isNullOrEmpty(attachments[0].file)) {
                        content = attachments[0].file;
                    } else if (attachments[0].attachedDocuments.length > 0) {
                        content = attachments[0].attachedDocuments[0].file;
                    }

                    await this.cacheService.cache(key, content, this.dateProvider.now().plus({ years: 10 }));

                    return content;
                } catch (e) {
                    this.logger.error(this.constructor.name, e);
                    return "";
                }
            } else {
                return "";
            }
        }
    }
}
