import { IonicConfig } from "@ionic/core";

export const API_VERSION: number = 1;
export const MODULE: string = "10008";

export const DEFAULT_MAX_TANK_CAPACITY: number = 8;

export const KM_MAX_PER_DAY: number = 2000; // Km max par jour

export const TESTS_ROOT_URL = "http://localhost:4204";

export const APP_OPTIONS: IonicConfig =
    {
        // mode: 'ios', // Pour forcer en mode ios
        scrollPadding: false,
        scrollAssist: false,
    };
