import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { OrderLinkedMovement } from "../models/orderLinkedMovement.model";
import { AttachmentDto } from "./attachment.dto";
import { BaseDto } from "./base/base.dto";
import { EquipmentDto } from "./equipment.dto";
import { ModelDto } from "./model.dto";
import { ServiceDto } from "./service.dto";

export class OrderLinkedMovementDto extends BaseDto<OrderLinkedMovement> {
    id: string = "";
    ltcId: string = "";
    movementId: string = "";
    fatherMovementId: string = "";
    quantity: number = 0;
    preparedQuantity: number = 0;
    equipmentNature: string = "";
    wording: string = "";
    // Fks
    model: ModelDto = null;
    service: ServiceDto = null;
    equipment: EquipmentDto = null;
    attachments: AttachmentDto[] = [];

    constructor() {
        super();

        this.model = new ModelDto();
        this.service = new ServiceDto();
        this.equipment = new EquipmentDto();
    }

    public static fromBody(body: any, movementId: string): OrderLinkedMovementDto {
        let linkedMovementDto = new OrderLinkedMovementDto();

        linkedMovementDto.ltcId = body.ltcId;
        linkedMovementDto.movementId = movementId;
        linkedMovementDto.fatherMovementId = body.fatherMovementId;
        linkedMovementDto.id = body.id;
        linkedMovementDto.wording = body.wording;
        linkedMovementDto.quantity = body.quantity;

        if (body.preparedQuantity) {
            linkedMovementDto.preparedQuantity = body.preparedQuantity;
        }

        if (body.equipment) {
            linkedMovementDto.equipment = EquipmentDto.fromModel(body.equipment);
        }

        if (body.model) {
            linkedMovementDto.model = ModelDto.fromBody(body.model);
        }

        if (body.service) {
            linkedMovementDto.service = ModelDto.fromBody(body.service);
        }

        if (body.attachments) {
            body.attachments.forEach(attachment => {
                linkedMovementDto.attachments.push(AttachmentDto.fromBody(attachment, "linkedMovement", linkedMovementDto.id));
            });
        }

        return linkedMovementDto;
    }

    static fromModel(linkedMovement: OrderLinkedMovement): OrderLinkedMovementDto {
        let linkedMovementDto = new OrderLinkedMovementDto();

        linkedMovementDto.id = linkedMovement.id;
        linkedMovementDto.ltcId = linkedMovement.ltcId;
        linkedMovementDto.movementId = linkedMovement.movementId;
        linkedMovementDto.fatherMovementId = linkedMovement.fatherMovementId;
        linkedMovementDto.wording = linkedMovement.wording;
        linkedMovementDto.quantity = linkedMovement.quantity;
        linkedMovementDto.preparedQuantity = linkedMovement.preparedQuantity;
        linkedMovementDto.equipmentNature = linkedMovement.equipmentNature;

        if (linkedMovement.service) {
            linkedMovementDto.service = ServiceDto.fromModel(linkedMovement.service);
        }
        if (linkedMovement.model) {
            linkedMovementDto.model = ModelDto.fromModel(linkedMovement.model);
        }
        if (linkedMovement.equipment) {
            linkedMovementDto.equipment = EquipmentDto.fromModel(linkedMovement.equipment);
        }

        if (linkedMovement.attachments) {
            linkedMovement.attachments.forEach(attachment => {
                linkedMovementDto.attachments.push(AttachmentDto.fromModel(attachment));
            });
        }

        return linkedMovementDto;
    }

    static fromOrderZoom(value: any) {
        let linkedMovementDto = new OrderLinkedMovementDto();

        linkedMovementDto.ltcId = value.field0;
        linkedMovementDto.movementId = value.field2;
        linkedMovementDto.fatherMovementId = value.field3;
        linkedMovementDto.id = value.field6;

        linkedMovementDto.quantity = value.field4;
        linkedMovementDto.preparedQuantity = value.field5;

        linkedMovementDto.model.id = value.field7;
        linkedMovementDto.model.wording = value.field8;

        linkedMovementDto.service.id = value.field9;

        linkedMovementDto.equipmentNature = value.field12;
        linkedMovementDto.equipment.id = value.field13;

        // Model "Hors catalogue"
        linkedMovementDto.wording = value.field14;

        return linkedMovementDto;
    }

    public static sorter(a: OrderLinkedMovementDto, b: OrderLinkedMovementDto) {
        if (a.preparedQuantity == a.quantity) {
            return 1;
        }
        if (b.preparedQuantity == b.quantity) {
            return -1;
        }

        return a.model.wording.localeCompare(b.model.wording);
    }

    public toBody() {
        let clone: any = this.clone();

        clone.model = this.model.toBody();
        clone.service = this.service.toBody();
        clone.equipment = this.equipment.toBody();

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            clone.attachments.push(attachment.toBody());
        });

        clone["father"] = { id: this.fatherMovementId };
        clone["quantityCompleted"] = this.preparedQuantity;
        clone["isCompleted"] = this.preparedQuantity == this.quantity;

        //   clonedLinkedMovement['movementLink'] =  {id: linkedMovementDto.id};
        clone["movementLink"] = { id: "PRE" };

        delete clone.preparedQuantity;
        delete clone.wording;
        delete clone.fatherMovementId;
        delete clone.service;
        delete clone.model;

        if (isNullOrEmpty(this.equipment.id)) {
            delete clone.equipment;
        }

        return clone;
    }

    toModel() {
        let linkedMovement = new OrderLinkedMovement();

        linkedMovement.id = this.id;
        linkedMovement.ltcId = this.ltcId;
        linkedMovement.movementId = this.movementId;
        linkedMovement.fatherMovementId = this.fatherMovementId;
        linkedMovement.wording = this.wording;
        linkedMovement.quantity = this.quantity;
        linkedMovement.preparedQuantity = this.preparedQuantity;
        linkedMovement.equipmentNature = this.equipmentNature;

        if (this.service) {
            linkedMovement.service = this.service.toModel();
        }
        if (this.model) {
            linkedMovement.model = this.model.toModel();
        }
        if (this.equipment) {
            linkedMovement.equipment = this.equipment.toModel();
        }

        if (this.attachments) {
            this.attachments.forEach(attachment => {
                linkedMovement.attachments.push(attachment.toModel());
            });
        }
        return linkedMovement;
    }

    public isValid() {
        return this.preparedQuantity == this.quantity;
    }

    public equals(linkedMovementDto: OrderLinkedMovementDto) {
        return this.id == linkedMovementDto.id
               && this.quantity == linkedMovementDto.quantity
               && this.preparedQuantity == linkedMovementDto.preparedQuantity
               && this.model.id == linkedMovementDto.model.id;
    }
}
