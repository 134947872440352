import { CheckStatus } from "../models/checkStatus";
import { BaseDto } from "./base/base.dto";

export class CheckStatusDto extends BaseDto<CheckStatus> {
    public id: string = "";
    public wording: string = "";
    public actionType: string = "";
    public equipmentNature: string = "";
    public order: string = "";

    static fromBody(body: any): CheckStatusDto {
        let returnCheckStatusDto = new CheckStatusDto();

        returnCheckStatusDto.id = body.field0;
        returnCheckStatusDto.wording = body.field1;
        returnCheckStatusDto.actionType = body.field4;
        returnCheckStatusDto.equipmentNature = body.field6;
        returnCheckStatusDto.order = body.field12;

        return returnCheckStatusDto;
    }

    static fromModel(returnCheckStatus: CheckStatus) {
        let returnCheckStatusDto = new CheckStatusDto();

        returnCheckStatusDto.id = returnCheckStatus.id;
        returnCheckStatusDto.wording = returnCheckStatus.wording;
        returnCheckStatusDto.actionType = returnCheckStatus.actionType;
        returnCheckStatusDto.equipmentNature = returnCheckStatus.equipmentNature;
        returnCheckStatusDto.order = returnCheckStatus.order;

        return returnCheckStatusDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): CheckStatus {
        let returnCheckStatus = new CheckStatus();

        returnCheckStatus.id = this.id;
        returnCheckStatus.wording = this.wording;
        returnCheckStatus.actionType = this.actionType;
        returnCheckStatus.equipmentNature = this.equipmentNature;
        returnCheckStatus.order = this.order;

        return returnCheckStatus;
    }
}
