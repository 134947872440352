import { DateTime } from "luxon";
import { DateProvider } from "../interfaces/dateProvider";

export class MockDateProvider extends DateProvider {
    constructor(private fixedDate: DateTime) {
        super();
    }

    public now(): DateTime {
        return this.fixedDate;
    }

    public nowAsDate(): Date {
        return this.now().toJSDate();
    }
}
