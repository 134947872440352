import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { IriumTitleComponent } from "./irium-title.component";

@NgModule({
    declarations: [
        IriumTitleComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        PipesModule,
        FormsModule,
    ],
    exports: [
        IriumTitleComponent,
    ],
})
export class IriumTitleModule {
}
