import { Injectable } from "@angular/core";
import { LoggerService } from "../../gyzmo-commons/services/logs/logger.service";
import { CheckStatusDbDao } from "../dao/db/checkStatus.db.dao";
import { CheckStatusWsDao } from "../dao/ws/checkStatus.ws.dao";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class ReturnCheckStatusService {
    constructor(private logger: LoggerService,
                private returnCheckStatusWsDao: CheckStatusWsDao,
                private returnCheckStatusDbDao: CheckStatusDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public synchronize(): Promise<void> {
        return this.returnCheckStatusWsDao.getList(this.serversConnectionsProvider.getPrimaryServerConnection())
            .then(returnCheckStatusDtos => {
                let promises = [];

                returnCheckStatusDtos.forEach(returnCheckStatusDto => {
                    promises.push(this.returnCheckStatusDbDao.save(returnCheckStatusDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }

    public async checkData(): Promise<boolean> {
        let correctness = true;
        let list = await this.returnCheckStatusDbDao.list();

        if (list == null || list.length <= 0) {
            correctness = false;
            this.logger.error(this.constructor.name, "No return check status !");
        }

        return correctness;
    }
}
