import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { DeliveryDto } from "../../dto/delivery.dto";
import { DeliveryLinkedMovementDto } from "../../dto/deliveryLinkedMovement.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { AddressWsDao } from "./address.ws";
import { ConstructionSiteWsDao } from "./constructionSite.ws.dao";
import { EquipmentWsDao } from "./equipment.ws.dao";
import { ModelWsDao } from "./model.ws.dao";
import { MovementWsDao } from "./movement.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { ZoomWsDao } from "./zoom.ws.dao";

@Injectable({
    providedIn: "root",
})
export class DeliveryWsDao extends WsDao<DeliveryDto> {
    constructor(private constructionSiteWsDao: ConstructionSiteWsDao,
                private modelWsDao: ModelWsDao,
                private addressWsDao: AddressWsDao,
                private thirdPartyWsDao: ThirdPartyWsDao,
                private equipmentWsDao: EquipmentWsDao,
                private movementWsDao: MovementWsDao,
                private zoomWsDao: ZoomWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<DeliveryDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, deliveryDto: DeliveryDto): Promise<DeliveryDto> {
        return new Promise<DeliveryDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", deliveryDto.id);

            serverConnection.put(this.constructor.name, MovementWsDao.WS + "/:id", tokens, deliveryDto.movement.toBody())
                .then(response => {
                    resolve(deliveryDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getLinkedMovements(serverConnection: ServerConnection, movementId: string, hydrate: boolean): Promise<DeliveryLinkedMovementDto[]> {
        return new Promise<DeliveryLinkedMovementDto[]>((resolve, reject) => {
            let params = "_limit=NOLIMIT&pF570KY=" + movementId;

            this.zoomWsDao.execute(serverConnection, "RECHERCHE_LIGNES_TOU", params)
                .then(response => {
                    let promises = [];
                    let result: DeliveryLinkedMovementDto[] = [];

                    response.forEach((value: any) => {
                        let linkedMovementDto = DeliveryLinkedMovementDto.fromDeliveryZoom(value);

                        if (hydrate) {
                            if (linkedMovementDto.prescriber.id) {
                                promises.push(this.thirdPartyWsDao.getById(serverConnection, linkedMovementDto.prescriber.id)
                                    .then(prescriber => {
                                        linkedMovementDto.prescriber = prescriber;
                                    }));
                            }
                            if (linkedMovementDto.customer.id) {
                                promises.push(this.thirdPartyWsDao.getById(serverConnection, linkedMovementDto.customer.id)
                                    .then(customer => {
                                        linkedMovementDto.customer = customer;
                                    }));
                            }
                            if (linkedMovementDto.constructionSite.id) {
                                promises.push(this.constructionSiteWsDao.getById(serverConnection, linkedMovementDto.constructionSite.id)
                                    .then(constructionSite => {
                                        linkedMovementDto.constructionSite = constructionSite;
                                    })
                                    .catch(reason => {
                                        linkedMovementDto.constructionSite = null;
                                    }));
                            }
                            if (linkedMovementDto.model.id) {
                                promises.push(this.modelWsDao.getById(serverConnection, linkedMovementDto.model.id)
                                    .then(model => {
                                        linkedMovementDto.model = model;
                                    }));
                            }
                            if (linkedMovementDto.equipment.id) {
                                promises.push(this.equipmentWsDao.getById(serverConnection, linkedMovementDto.equipment.id)
                                    .then(equipment => {
                                        linkedMovementDto.equipment = equipment;
                                    }));
                            }
                            if (linkedMovementDto.deliveryAddress.id) {
                                promises.push(this.addressWsDao.getById(serverConnection, linkedMovementDto.deliveryAddress.id)
                                    .then(address => {
                                        linkedMovementDto.deliveryAddress = address;
                                    }));
                            }
                        }

                        result.push(linkedMovementDto);
                    });

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getList(serverConnection: ServerConnection, startDate: DateTime, endDate: DateTime, hydrate = false): Promise<DeliveryDto[]> {
        return new Promise<DeliveryDto[]>((resolve, reject) => {
            let params = "_limit=NOLIMIT";
            if (startDate) {
                params += "&pStartDate=" + startDate.toFormat(DATE_NODEJS_FORMAT);
            } else {
                params += "&pStartDate=";
            }

            if (endDate) {
                params += "&pEndDate=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            } else {
                params += "&pEndDate=";
            }

            this.zoomWsDao.execute(serverConnection, "RECHERCHE_TOURNEES", params)
                .then(response => {
                    let promises = [];
                    let result: DeliveryDto[] = [];
                    response.forEach((value: any) => {
                        let deliveryDto = DeliveryDto.fromDeliveryZoom(value);

                        if (hydrate) {
                            promises.push(this.equipmentWsDao.getById(serverConnection, deliveryDto.equipment.id)
                                .then(equipment => {
                                    deliveryDto.equipment = equipment;
                                }));

                            promises.push(this.movementWsDao.getById(serverConnection, deliveryDto.movement.id)
                                .then(movement => {
                                    deliveryDto.movement = movement;
                                }));

                            promises.push(this.getLinkedMovements(serverConnection, deliveryDto.movement.id, hydrate)
                                .then(linkedMovements => {
                                    deliveryDto.linkedMovements = linkedMovements;
                                }));
                        }

                        result.push(deliveryDto);
                    });

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(result);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
