import { isNullOrEmpty } from "../../helpers/null.helper";

export const SAVE_TO_DB: string = "saveToDB"; // utilisé pour distinguer les objects enfants retournés par une API

export abstract class ModelBase {

    public tablename: string = "";

    protected constructor(tablename: string) {
        this.tablename = tablename;
    }

    public map(data: any) {
        if (data) {
            let keys = Object.keys(this);
            for (let i in keys) {
                let property = keys[i];
                if (typeof this[property] !== "function" && data[property]) {
                    if (this[property] instanceof Array) {
                        this[property] = (data[property] instanceof Array) ? data[property] : JSON.parse(data[property]);
                    } else {
                        switch (typeof this[property]) {
                            case "object":
                                this.mapObjectOrId(data, property);
                                break;
                            case "boolean":
                                this[property] = (data[property] == 1);
                                break;
                            case "number":
                                this[property] = (typeof data[property] === "string") ? parseFloat(data[property].replace(",", ".")) : data[property];
                                break;
                            default:
                                this[property] = data[property];
                                break;
                        }
                    }
                }
            }
        }

        return this;
    }

    public clone(): any {
        const cloneObj = new (<any>this.constructor)();
        let keys = Object.keys(this);
        for (let i in keys) {
            let property = keys[i];
            if (typeof this[property] === "object" && this[property] && typeof this[property] !== "function" && !(this[property] instanceof Array) && (typeof this[property].clone === "function")) {
                cloneObj[property] = this[property].clone();
            } else {
                cloneObj[property] = this[property];
            }
        }
        return cloneObj;
    }

    private isValidJsonString(value: string): boolean {
        try {
            let myObject: any = JSON.parse(value);
            return (typeof myObject === "object");
        } catch (e) {
            return false;
        }
    }

    private mapObjectOrId(data: any, property: string) {
        if (typeof data[property] === "object" && this.testObjectIsEmpty(data[property])) {
            this[property] = data[property];
            this[property][SAVE_TO_DB] = true;
        } else if (this.isValidJsonString(data[property])) {
            this[property] = JSON.parse(data[property]);
        } else {
            this[property].id = data[property];
        }
    }

    // Teste si un object a seulement un internal_id
    private testObjectIsEmpty(obj: any): boolean {
        delete obj.tablename;
        // eslint-disable-next-line no-prototype-builtins
        return !(Object.keys(obj).length === 1 && obj.hasOwnProperty("id") && !isNullOrEmpty(obj.id));

    }
}
