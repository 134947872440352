// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
  overflow: hidden;
  align-items: center;
}

.ng-invalid {
  color: var(--ion-color-danger);
}

div[slot=end] {
  display: grid;
  grid-template-columns: auto auto;
  align-items: end;
  margin-inline-start: 0;
  height: 100%;
  padding: 5px 0;
}

.with-bottom-border {
  margin: 7px 20px 4px 40px;
  border-bottom: var(--with-bottom-border-color) thin solid;
}`, "",{"version":3,"sources":["webpack://./src/gyzmo-commons/components/irium-input/irium-input.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,gCAAA;EACA,gBAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AACJ;;AAEA;EACI,yBAAA;EACA,yDAAA;AACJ","sourcesContent":[":host {\r\n    display: grid;\r\n    overflow: hidden;\r\n    align-items: center;\r\n}\r\n\r\n.ng-invalid {\r\n    color: var(--ion-color-danger);\r\n}\r\n\r\ndiv[slot=\"end\"] {\r\n    display: grid;\r\n    grid-template-columns: auto auto;\r\n    align-items: end;\r\n    margin-inline-start: 0;\r\n    height: 100%;\r\n    padding: 5px 0;\r\n}\r\n\r\n.with-bottom-border {\r\n    margin: 7px 20px 4px 40px;\r\n    border-bottom: var(--with-bottom-border-color) thin solid;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
