import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Server } from "../../models/server.model";

@Injectable({
    providedIn: "root",
})
export class ServerDbDao extends DbDaoBase<Server> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public list(): Promise<Server[]> {
        let selectQuery = "SELECT * FROM " + Server.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return [];
                    }

                    let servers: Server[] = [];
                    for (let i = 0; i < data.rows.length; i++) {
                        servers.push(this.rowToModel(data.rows[i]));
                    }

                    return servers;
                })
            .catch(reason => {
                this.logSqlError(reason);
                return [];
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Server.TABLENAME + "_id"
                    + " ON " + Server.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Server.TABLENAME
                    + " ("
                    + "id NUMERIC PRIMARY KEY,"
                    + "name TEXT, "
                    + "url TEXT, "
                    + "apiKey TEXT, "
                    + "defaultToken TEXT, "
                    + "token TEXT, "
                    + "context TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Server.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Server.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean): Promise<Server> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Server.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return new Server();
                    }

                    return this.rowToModel(data.rows[0]);
                })
            .catch(reason => {
                this.logSqlError(reason);
                return new Server();
            });
    }

    public getTableName(): string {
        return Server.TABLENAME;
    }

    protected rowToModel(row: any): Server {
        let server = new Server();

        server.id = row.id;
        server.name = row.name;
        server.url = row.url;
        server.apiKey = row.apiKey;
        server.defaultToken = row.defaultToken;
        server.token = row.token;
        server.context = row.context;

        return server;
    }

    public save(server: Server): Promise<Server> {
        let query = "INSERT OR REPLACE INTO " + Server.TABLENAME + " ("
                    + "id, name, url, apiKey, "
                    + "defaultToken, token, context"
                    + ") VALUES ("
                    + this.getValue(server.id)
                    + this.getValue(server.name)
                    + this.getValue(server.url)
                    + this.getValue(server.apiKey)
                    + this.getValue(server.defaultToken)
                    + this.getValue(server.token)
                    + this.getValue(server.context, true)
                    + ");";

        return this.sqlProvider.query(query)
            .then(response => {
                return server;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
