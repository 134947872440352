import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class HourType extends ModelBase {
    static TABLENAME = "HourTypes";

    public id: string = "";
    public wording: string = "";
    public hourType: string;

    constructor() {
        super(HourType.TABLENAME);
    }
}
