import { Injectable } from "@angular/core";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Time } from "../../models/time.model";

@Injectable({
    providedIn: "root",
})
export class TimesDbDao extends DbDaoBase<Time> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async list(movementId: string, customerId: string, constructionSiteId: string, index: number): Promise<Time[]> {
        let selectQuery = "SELECT * FROM " + Time.TABLENAME + " WHERE fatherMovementId = '" + movementId + "'";
        selectQuery += " AND customerId='" + customerId + "'";
        selectQuery += " AND constructionSiteId='" + constructionSiteId + "'";
        selectQuery += " AND _index=" + index;
        selectQuery += ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let times: Time[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    times.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];

                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return times;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getOpenTimeForMovement(movementId: string, customerId: string, constructionSiteId: string, index: number) {
        let selectQuery = "SELECT * FROM " + Time.TABLENAME + " WHERE fatherMovementId = '" + movementId + "'";
        selectQuery += " AND customerId='" + customerId + "'";
        selectQuery += " AND constructionSiteId='" + constructionSiteId + "'";
        selectQuery += " AND _index=" + index;
        selectQuery += " AND endTime = ''";
        selectQuery += ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Time.TABLENAME + "_id"
                    + " ON " + Time.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Time.TABLENAME + "_fatherMovementId"
                + " ON " + Time.TABLENAME + "(fatherMovementId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Time.TABLENAME + "_customerId"
                + " ON " + Time.TABLENAME + "(customerId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Time.TABLENAME + "_constructionSiteId"
                + " ON " + Time.TABLENAME + "(constructionSiteId);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Time.TABLENAME + "__index"
                + " ON " + Time.TABLENAME + "(_index);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Time.TABLENAME + "_endTime"
                + " ON " + Time.TABLENAME + "(endTime);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Time.TABLENAME
                    + " ("
                    + "id INTEGER PRIMARY KEY AUTOINCREMENT,"
                    + "fatherMovementId TEXT,"
                    + "customerId TEXT,"
                    + "constructionSiteId TEXT,"
                    + "_index INTEGER,"
                    + "type TEXT,"
                    + "startTime TEXT,"
                    + "endTime TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Time.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Time.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Time> {
        let selectQuery = "SELECT * FROM " + Time.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                return this.rowToModel(data.rows[0]);
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Time.TABLENAME;
    }

    protected rowToModel(row: any): Time {
        let time = new Time();

        time.id = row.id;
        time.fatherMovementId = row.fatherMovementId;
        time.customerId = row.customerId;
        time.constructionSiteId = row.constructionSiteId;
        time.index = row._index;
        time.type = row.type;
        time.startTime = row.startTime;
        time.endTime = row.endTime;

        return time;
    }

    public save(time: Time): Promise<Time> {
        let query;

        if (time.id) {
            query = "INSERT OR REPLACE INTO " + Time.TABLENAME
                    + " (id, fatherMovementId, customerId, constructionSiteId, _index, type, startTime, endTime)"
                    + " VALUES ("
                    + this.getValue(time.id)
                    + this.getValue(time.fatherMovementId)
                    + this.getValue(time.customerId)
                    + this.getValue(time.constructionSiteId)
                    + this.getValue(time.index)
                    + this.getValue(time.type)
                    + this.getValue(time.startTime)
                    + this.getValue(time.endTime, true)
                    + ");";
        } else {
            query = "INSERT OR REPLACE INTO " + Time.TABLENAME
                    + " (fatherMovementId, customerId, constructionSiteId, _index, type, startTime, endTime)"
                    + " VALUES ("
                    + this.getValue(time.fatherMovementId)
                    + this.getValue(time.customerId)
                    + this.getValue(time.constructionSiteId)
                    + this.getValue(time.index)
                    + this.getValue(time.type)
                    + this.getValue(time.startTime)
                    + this.getValue(time.endTime, true)
                    + ");";
        }

        return this.sqlProvider.query(query)
            .then(response => {
                return time;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }
}
