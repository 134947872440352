import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DateTime } from "luxon";
import { LoggerService } from "../../services/logs/logger.service";

@Component({
    selector: "gyzmo-button",
    templateUrl: "gyzmo-button.component.html",
    styleUrls: ["gyzmo-button.component.scss"],
})
export class GyzmoButtonComponent {
    @Input() text: string;
    @Input() icon: string;
    @Input() color: string;
    @Input() disabled: boolean;

    @Output() click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    clicking = false;
    debounceTime = DateTime.now();
    private lastClickButtonHash = "";

    constructor(private logger: LoggerService) {
    }

    public onBtnClick($event: MouseEvent) {
        if (this.clicking) {
            return;
        }

        this.clicking = true;

        $event.preventDefault();
        $event.stopImmediatePropagation();
        $event.stopPropagation();

        let debounceDiffTime = DateTime.now().diff(this.debounceTime, "milliseconds");
        if (debounceDiffTime.milliseconds > 1000 || this.lastClickButtonHash != this.getButtonHash()) {
            this.debounceTime = DateTime.now();
            this.lastClickButtonHash = this.getButtonHash();

            if (!this.disabled) {
                if (this.click != null) {
                    this.click.emit($event);
                }
            }
        } else {
            // eslint-disable-next-line no-console
            this.logger.warn(this.constructor.name, "Click has been debounced click time was : " + debounceDiffTime);
        }

        this.clicking = false;
    }

    private getButtonHash() {
        return this.text + this.icon;
    }
}
