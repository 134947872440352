import { Injectable } from "@angular/core";
import { CompanyDto } from "../../dto/company.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class CompanyWsDao extends WsDao<CompanyDto> {
    static WS = "companies";

    public getById(serverConnection: ServerConnection, id: string): Promise<CompanyDto> {
        return new Promise<CompanyDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, CompanyWsDao.WS + "/:id", tokens)
                .then(response => {
                    let company = CompanyDto.fromBody(response.body);
                    resolve(company);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, company: CompanyDto): Promise<CompanyDto> {
        throw new Error("Not implemented");
    }
}
