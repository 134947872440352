import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { LtcStatuses } from "../interfaces/ltcStatuses";
import { Invoice } from "./invoice.model";
import { Location } from "./location.model";
import { Movement } from "./movement.model";
import { OrderLinkedMovement } from "./orderLinkedMovement.model";
import { ThirdParty } from "./thirdParty.model";

export class Order extends ModelBase {
    static TABLENAME = "Orders";

    id: string = "";
    wording: string = "";
    startDate: string = "";
    orderDate: string = "";
    plannedReturnDate: string = "";
    returnDate: string = "";
    wantedDays: number = 0;
    duration: number = 0;
    cumulativeMileage: number = 0;
    includedMileage: number = 0;
    contractNumber: string = "";
    bookingCode: string = "";
    status: LtcStatuses = LtcStatuses.CANCELLED;
    isClosed: number = 0;
    // Fks
    customer: ThirdParty;
    driver: ThirdParty;
    merchant: ThirdParty;
    movement: Movement;
    linkedMovements: OrderLinkedMovement[] = [];
    invoice: Invoice;
    startLocation: Location;
    plannedReturnLocation: Location;
    returnLocation: Location;

    constructor() {
        super(Order.TABLENAME);

        this.customer = new ThirdParty();
        this.driver = new ThirdParty();
        this.merchant = new ThirdParty();

        this.movement = new Movement();

        this.invoice = new Invoice();

        this.startLocation = new Location();
        this.plannedReturnLocation = new Location();
        this.returnLocation = new Location();
    }
}
