import { Injectable } from "@angular/core";
import { UserDto } from "src/core/dto/user.dto";
import { ConstructionSiteDto } from "../../dto/constructionSite.dto";
import { InvoiceDto } from "../../dto/invoice.dto";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { ConstructionSiteWsDao } from "./constructionSite.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { UserWsDao } from "./user.ws.dao";

@Injectable({
    providedIn: "root",
})
export class InvoiceWsDao extends WsDao<InvoiceDto> {
    static WS = "invoices";

    constructor(
        private constructionSiteWsDao: ConstructionSiteWsDao,
        private thirdPartyWsDao: ThirdPartyWsDao,
        private userWsDao: UserWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<InvoiceDto> {
        return new Promise<InvoiceDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, InvoiceWsDao.WS + "/:id", tokens)
                .then(response => {
                    let invoiceDto = InvoiceDto.fromBody(response.body);
                    let promises = [];

                    if (invoiceDto.prescriber && invoiceDto.prescriber.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, invoiceDto.prescriber.id)
                            .then((value: ThirdPartyDto) => {
                                invoiceDto.prescriber = value;
                            }));
                    }

                    if (invoiceDto.thirdParty && invoiceDto.thirdParty.id) {
                        promises.push(this.thirdPartyWsDao.getById(serverConnection, invoiceDto.thirdParty.id)
                            .then((value: ThirdPartyDto) => {
                                invoiceDto.thirdParty = value;
                            }));
                    }

                    if (invoiceDto.constructionSite && invoiceDto.constructionSite.id) {
                        promises.push(this.constructionSiteWsDao.getById(serverConnection, invoiceDto.constructionSite.id)
                            .then((value: ConstructionSiteDto) => {
                                invoiceDto.constructionSite = value;
                            })
                            .catch(reason => {
                                invoiceDto.constructionSite = null;
                            }));
                    }

                    if (invoiceDto.orderPicker && invoiceDto.orderPicker.id) {
                        promises.push(this.userWsDao.getById(serverConnection, invoiceDto.orderPicker.id)
                            .then((value: UserDto) => {
                                invoiceDto.orderPicker = value.thirdParty;
                            }));
                    }

                    Promise.all(promises)
                        .then(ignored => {
                            resolve(invoiceDto);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, invoiceDto: InvoiceDto): Promise<InvoiceDto> {
        return new Promise<InvoiceDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, InvoiceWsDao.WS, tokens, invoiceDto.toBody())
                .then(response => {
                    resolve(invoiceDto);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
