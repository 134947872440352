import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class DriverInfo extends ModelBase {
    static TABLENAME = "DriversInfo";
    public id: string = "";
    public birthDate: string = "";
    public driverLicenseNumber: string = "";
    public driverLicenseIssuingDate: string = "";
    public driverLicenseIssuingPlace: string = "";

    constructor() {
        super(DriverInfo.TABLENAME);
    }
}
