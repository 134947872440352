import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class SurveyKind extends ModelBase {
    static TABLENAME = "SurveyKinds";

    public id: string = "";
    public wording: string = "";
    public filter1: string = "";
    public value: boolean = false;
    public order: string = "";

    constructor() {
        super(SurveyKind.TABLENAME);
    }
}
