import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Address } from "./address.model";
import { ThirdParty } from "./thirdParty.model";

export class ConstructionSite extends ModelBase {
    static TABLENAME = "ConstructionSites";

    public id: string = "";
    public siteName: string = "";

    public mainAddress: Address = null;
    public worksManager: ThirdParty = null;
    public constructionSiteManager: ThirdParty = null;

    constructor() {
        super(ConstructionSite.TABLENAME);

        this.mainAddress = new Address();
        this.worksManager = new ThirdParty();
        this.constructionSiteManager = new ThirdParty();
    }
}
