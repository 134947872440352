import { ConstructionSite } from "../models/constructionSite.model";
import { AddressDto } from "./address.dto";
import { BaseDto } from "./base/base.dto";
import { ThirdPartyDto } from "./thirdParty.dto";

export class ConstructionSiteDto extends BaseDto<ConstructionSite> {
    public id: string = "";
    public siteName: string = "";

    public mainAddress: AddressDto = null;
    public worksManager: ThirdPartyDto = null;
    public constructionSiteManager: ThirdPartyDto = null;

    constructor() {
        super();

        this.mainAddress = new AddressDto();
        this.worksManager = new ThirdPartyDto();
        this.constructionSiteManager = new ThirdPartyDto();
    }

    public static fromBody(body: any): ConstructionSiteDto {
        let constructionSiteDto = new ConstructionSiteDto();

        constructionSiteDto.id = body.id;
        constructionSiteDto.siteName = body.lastName;

        if (body.mainAddress) {
            constructionSiteDto.mainAddress = AddressDto.fromBody(body.mainAddress);
        }

        if (body.worksManager) {
            constructionSiteDto.worksManager = ThirdPartyDto.fromBody(body.worksManager);
        }
        if (body.constructionSiteManager) {
            constructionSiteDto.constructionSiteManager = ThirdPartyDto.fromBody(body.constructionSiteManager);
        }

        return constructionSiteDto;
    }

    static fromModel(constructionSite: ConstructionSite): ConstructionSiteDto {
        let constructionSiteDto = new ConstructionSiteDto();

        constructionSiteDto.id = constructionSite.id;
        constructionSiteDto.siteName = constructionSite.siteName;

        if (constructionSite.mainAddress) {
            constructionSiteDto.mainAddress = AddressDto.fromModel(constructionSite.mainAddress);
        }

        if (constructionSite.worksManager) {
            constructionSiteDto.worksManager = ThirdPartyDto.fromModel(constructionSite.worksManager);
        }
        if (constructionSite.constructionSiteManager) {
            constructionSiteDto.constructionSiteManager = ThirdPartyDto.fromModel(constructionSite.constructionSiteManager);
        }

        return constructionSiteDto;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.mainAddress = this.mainAddress.toBody();
        clone.worksManager = this.worksManager.toBody();
        clone.constructionSiteManager = this.constructionSiteManager.toBody();

        return clone;
    }

    public toModel(): ConstructionSite {
        let constructionSite = new ConstructionSite();

        constructionSite.id = this.id;
        constructionSite.siteName = this.siteName;

        if (this.mainAddress) {
            constructionSite.mainAddress = this.mainAddress.toModel();
        }

        if (this.worksManager) {
            constructionSite.worksManager = this.worksManager.toModel();
        }
        if (this.constructionSiteManager) {
            constructionSite.constructionSiteManager = this.constructionSiteManager.toModel();
        }

        return constructionSite;
    }
}
