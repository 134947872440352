/**
 * Represents all headers uses by LpBackEnd
 * Must be sorted in alphabetical order
 * Must be sorted by type (standard, VEGA, GYZMO, etc...)
 * Specifics headers starts with X-YYYY
 */

export const AC_ALLOWHEADERS: string = "Access-Control-Allow-Headers";
export const AC_ALLOWMETHOD: string = "Access-Control-Allow-Method";
export const AC_ALLOWMETHODS: string = "Access-Control-Allow-Methods";
export const AC_ALLOWORIGIN: string = "Access-Control-Allow-Origin";
export const AC_EXPOSEHEADERS: string = "Access-Control-Expose-Headers";

export const ACCEPTLANGUAGE: string = "accept-language";
export const CONTENTJSON: string = "application/json";
export const CONTENTTYPE: string = "content-type";
export const NEXT: string = "next";
export const PREVIOUS: string = "previous";
export const FIRST: string = "first";
export const LAST: string = "last";
export const ORIGIN: string = "Origin";
export const AUTHORIZATION: string = "Authorization";
export const ACCEPT: string = "Accept";

export const XREQUESTWITH: string = "X-Requested-With";
export const XVEGAID: string = "X-VEGA-Id";
export const XVEGAAPP: string = "X-VEGA-App";
export const XVEGASERIAL: string = "X-VEGA-Serial";
export const XVEGATOKEN: string = "X-VEGA-Token";
export const XVEGATOTALCOUNT: string = "X-Total-Count";
export const XVEGAVARIANT: string = "X-VEGA-Variant";
export const XVEGAVERSION: string = "X-VEGA-Version";
export const XVEGAMINVERSION: string = "X-VEGA-Min-Version";
export const XVEGAAPIKEY: string = "X-VEGA-Api-Key";
export const XVEGACONTEXT: string = "X-VEGA-Context";
export const XVEGAAUTH: string = "X-VEGA-Auth";

export const XGYZMOAPIKEY: string = "X-GYZMO-Api-Key";

export const XVEGAAPPVERSION = "X-VEGA-App-Version";
