import { Sector } from "../models/sector.model";
import { BaseDto } from "./base/base.dto";

export class SectorDto extends BaseDto<Sector> {
    public id: string = "";
    public wording: string = "";

    public static fromBody(body: any): SectorDto {
        let locationDto = new SectorDto();

        locationDto.id = "" + body.id;
        locationDto.wording = body.wording;

        return locationDto;
    }

    public static fromModel(location: Sector): SectorDto {
        let locationDto = new SectorDto();

        locationDto.id = location.id;
        locationDto.wording = location.wording;

        return locationDto;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }

    public toModel(): Sector {
        let location = new Sector();

        location.id = this.id;
        location.wording = this.wording;

        return location;
    }
}
