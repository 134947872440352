import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../gyzmo-commons/helpers/notImplementedError";
import { SurveyKindDto } from "../../dto/surveyKind.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class SurveyKindWsDao extends WsDao<SurveyKindDto> {
    public getById(serverConnection: ServerConnection, id: string): Promise<SurveyKindDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: SurveyKindDto): Promise<SurveyKindDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection): Promise<SurveyKindDto[]> {
        return new Promise<SurveyKindDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("ft", "FT49MED");

            serverConnection.get(this.constructor.name, "technical/ft/:ft/execute?_limit=NOLIMIT", tokens)
                .then(response => {
                    let results = [];
                    let ftElements: Array<any> = (response.body as Array<any>);

                    ftElements.forEach(ftElement => {
                        results.push(SurveyKindDto.fromBody(ftElement));
                    });

                    resolve(results);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
