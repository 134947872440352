import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Model } from "./model.model";
import { PersonalModel } from "./personalModel.model";

export class Equipment extends ModelBase {
    static TABLENAME = "Equipments";

    public id: string = "";
    public title: string = "";
    public options: string = "";
    public description: string = "";
    public color: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public picture: string = "";
    public trackerVersion: number = 0;
    public registrationNumber: string = "";

    public model: Model = null;

    public mileage: number = 0;
    public mileageDate: string = "";

    public personalModel: PersonalModel = null;

    constructor() {
        super(Equipment.TABLENAME);

        this.model = new Model();
        this.personalModel = new PersonalModel();
    }
}

