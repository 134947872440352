import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { PipesModule } from "../../helpers/pipes/pipes.module";
import { ProgressDialogPopover } from "./progress-dialog.popover";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: "",
                component: ProgressDialogPopover,
            },
        ]),
        PipesModule,
    ],
    declarations: [ProgressDialogPopover],
    providers: [],
})
export class ProgressDialogPopoverModule {
}
