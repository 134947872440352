import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { DeliveryStates } from "../interfaces/deliveryStates";
import { LtcStatuses } from "../interfaces/ltcStatuses";
import { DeliveryLinkedMovement } from "./deliveryLinkedMovement.model";
import { Equipment } from "./equipment.model";
import { Location } from "./location.model";
import { Movement } from "./movement.model";
import { ThirdParty } from "./thirdParty.model";

export class Delivery extends ModelBase {
    static TABLENAME = "Deliveries";

    id: string = "";
    startDate: string = "";
    plannedReturnDate: string = "";
    returnDate: string = "";
    status: LtcStatuses = LtcStatuses.DELIVERY;
    state: DeliveryStates = DeliveryStates.NOT_STARTED;
    isClosed: number = 0;

    // Fks
    driver: ThirdParty;
    equipment: Equipment;
    movement: Movement;
    linkedMovements: DeliveryLinkedMovement[] = [];
    startLocation: Location;
    plannedReturnLocation: Location;
    returnLocation: Location;

    constructor() {
        super(Delivery.TABLENAME);

        this.driver = new ThirdParty();
        this.movement = new Movement();
        this.startLocation = new Location();
        this.plannedReturnLocation = new Location();
        this.returnLocation = new Location();
        this.equipment = new Equipment();
    }
}
