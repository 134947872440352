import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { DATE_NODEJS_FORMAT } from "../../../gyzmo-commons/interfaces/constants";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../gyzmo-commons/services/logs/logger.service";
import { Delivery } from "../../models/delivery.model";
import { DeliveryLinkedMovementDbDao } from "./deliveryLinkedMovement.db.dao";
import { EquipmentDbDao } from "./equipment.db.dao";
import { LocationDbDao } from "./location.db.dao";
import { MovementDbDao } from "./movement.db.dao";
import { ThirdPartyDbDao } from "./thirdParty.db.dao";

@Injectable({
    providedIn: "root",
})
export class DeliveryDbDao extends DbDaoBase<Delivery> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private movementDbDao: MovementDbDao,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private locationDbDao: LocationDbDao,
                private linkedMovementDbDao: DeliveryLinkedMovementDbDao,
                private equipmentDbDao: EquipmentDbDao) {
        super(logger);
    }

    public getList(startDate: DateTime, endDate: DateTime,
                   hydrateMovement: boolean = false,
                   hydrateObjects: boolean = false): Promise<Delivery[]> {
        let selectQuery = "SELECT * FROM " + Delivery.TABLENAME;

        if (startDate && endDate) {
            selectQuery += " WHERE startDate >= '" + startDate.toFormat(DATE_NODEJS_FORMAT) + "' AND startDate <= '" + endDate.toFormat(DATE_NODEJS_FORMAT) + "'";
        }
        selectQuery += ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let deliveries: Delivery[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    deliveries.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                deliveries.forEach(delivery => {
                    if (hydrateMovement) {
                        hydratationPromises.push(this.movementDbDao.get(delivery.movement.id, hydrateObjects)
                            .then(value => {
                                delivery.movement = value;
                            }));

                        hydratationPromises.push(this.linkedMovementDbDao.getByMovementId(delivery.id, hydrateObjects)
                            .then(value => {
                                delivery.linkedMovements = value;
                            }));
                    }

                    if (hydrateObjects) {
                        hydratationPromises.push(this.thirdPartyDbDao.get(delivery.driver.id, hydrateObjects)
                            .then(value => {
                                delivery.driver = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(delivery.startLocation.id, hydrateObjects)
                            .then(value => {
                                delivery.startLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(delivery.plannedReturnLocation.id, hydrateObjects)
                            .then(value => {
                                delivery.plannedReturnLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(delivery.returnLocation.id, hydrateObjects)
                            .then(value => {
                                delivery.returnLocation = value;
                            }));

                        hydratationPromises.push(this.equipmentDbDao.get(delivery.equipment.id, hydrateObjects)
                            .then(value => {
                                delivery.equipment = value;
                            }));
                    }
                });

                return Promise.all(hydratationPromises)
                    .then(ignored => {
                        return deliveries;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public saveState(delivery: Delivery): Promise<Delivery> {
        let query = "UPDATE " + Delivery.TABLENAME + " SET state = "
                    + this.getValue(delivery.state, true)
                    + " WHERE id = '" + delivery.id + "';";

        return this.sqlProvider.query(query)
            .then(response => {
                return delivery;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Delivery.TABLENAME + "_id"
                    + " ON " + Delivery.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Delivery.TABLENAME + "_startDate"
                + " ON " + Delivery.TABLENAME + "(startDate);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Delivery.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "startDate TEXT,"
                    + "plannedReturnDate TEXT,"
                    + "returnDate TEXT,"
                    + "status TEXT,"
                    + "state TEXT,"
                    + "isClosed NUMERIC,"
                    // Fks
                    + "driver TEXT,"
                    + "movement TEXT,"
                    + "startLocation TEXT,"
                    + "plannedReturnLocation TEXT,"
                    + "returnLocation TEXT,"
                    + "equipment TEXT,"
                    + "server NUMERIC"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Delivery.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Delivery.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Delivery> {
        let selectQuery = "SELECT * FROM " + Delivery.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let delivery: Delivery = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    if (hydrate) {
                        hydratationPromises.push(this.movementDbDao.get(delivery.movement.id, hydrate)
                            .then(value => {
                                delivery.movement = value;
                            }));
                        hydratationPromises.push(this.thirdPartyDbDao.get(delivery.driver.id, hydrate)
                            .then(value => {
                                delivery.driver = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(delivery.startLocation.id, hydrate)
                            .then(value => {
                                delivery.startLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(delivery.plannedReturnLocation.id, hydrate)
                            .then(value => {
                                delivery.plannedReturnLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(delivery.returnLocation.id, hydrate)
                            .then(value => {
                                delivery.returnLocation = value;
                            }));

                        hydratationPromises.push(this.linkedMovementDbDao.getByMovementId(delivery.id, hydrate)
                            .then(value => {
                                delivery.linkedMovements = value;
                            }));

                        hydratationPromises.push(this.equipmentDbDao.get(delivery.equipment.id, hydrate)
                            .then(value => {
                                delivery.equipment = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(ignored => {
                            return delivery;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Delivery.TABLENAME;
    }

    protected rowToModel(row: any): Delivery {
        let delivery = new Delivery();

        delivery.id = row.id;
        delivery.startDate = row.startDate;
        delivery.plannedReturnDate = row.plannedReturnDate;
        delivery.returnDate = row.returnDate;
        delivery.status = row.status;
        delivery.state = row.state;
        delivery.isClosed = row.isClosed;

        // Fks
        delivery.driver.id = row.driver;
        delivery.movement.id = row.movement;
        delivery.equipment.id = row.equipment;

        delivery.startLocation.id = row.startLocation;
        delivery.plannedReturnLocation.id = row.plannedReturnLocation;
        delivery.returnLocation.id = row.returnLocation;

        return delivery;
    }

    public save(delivery: Delivery): Promise<Delivery> {
        let promises = [];
        promises.push(this.movementDbDao.save(delivery.movement));
        promises.push(this.thirdPartyDbDao.save(delivery.driver));
        promises.push(this.locationDbDao.save(delivery.startLocation));
        promises.push(this.locationDbDao.save(delivery.plannedReturnLocation));
        promises.push(this.locationDbDao.save(delivery.returnLocation));
        promises.push(this.equipmentDbDao.save(delivery.equipment));

        delivery.linkedMovements.forEach(linkedMovement => {
            promises.push(this.linkedMovementDbDao.save(linkedMovement));
        });

        return Promise.all(promises)
            .then(values => {
                let query = "INSERT OR REPLACE INTO " + Delivery.TABLENAME + " ("
                            + "id, startDate, plannedReturnDate, returnDate, status, state, isClosed, "
                            // Fks
                            + "driver, movement, startLocation, plannedReturnLocation, returnLocation, equipment "
                            + ") VALUES ("
                            + this.getValue(delivery.id)
                            + this.getValue(delivery.startDate)
                            + this.getValue(delivery.plannedReturnDate)
                            + this.getValue(delivery.returnDate)
                            + this.getValue(delivery.status)
                            + this.getValue(delivery.state)
                            + this.getValue(delivery.isClosed)
                            // Fks
                            + this.getFkValue(delivery.driver)
                            + this.getFkValue(delivery.movement)
                            + this.getFkValue(delivery.startLocation)
                            + this.getFkValue(delivery.plannedReturnLocation)
                            + this.getFkValue(delivery.returnLocation)
                            + this.getFkValue(delivery.equipment, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return delivery;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
